import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proInstinctAPI from 'proInstinctAPI';
import { utilityVariables as utilVar } from 'utility/messages';

export const fetchInvitationData = createAsyncThunk(
  'invite/fetchInvitationData',
  async (uuid, { rejectWithValue }) => {
    const inviteData = await proInstinctAPI.fetchInvitationData(
      uuid,
      rejectWithValue
    );

    return inviteData;
  }
);

export const completeOnboarding = createAsyncThunk(
  'invite/completeOnboarding',
  async (userPayload, { rejectWithValue }) => {
    return await proInstinctAPI.completeOnboarding(
      userPayload,
      rejectWithValue
    );
  }
);

const initialState = {
  uuid: '',
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  inviteFormFetching: true,
  inviteFormSubmitted: false,
  displayInviteFailed: false,
  confirmInviteFetching: true,
  confirmInviteFailed: false,
  confirmInvitePassed: false,
  inviteMessage: ''
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    resetInviteFilled() {
      return initialState;
    },
    resetInviteSubmitted(state) {
      state.inviteFormSubmitted = false;
    },
    showFailedInvite(state) {
      state.displayInviteFailed = true;
    },
    // this will be needed, because the athlete can change the info if they want
    fillInviteForm(state, action) {
      const { firstName, lastName, email, mobile } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.mobile = mobile;
      state.inviteFormSubmitted = true;
    }
  },
  extraReducers: {
    [fetchInvitationData.fulfilled]: (state, action) => {
      const { firstName, lastName, email, mobile, uuid } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.mobile = mobile;
      state.uuid = uuid;
      state.inviteFormFetching = false;
    },
    [fetchInvitationData.pending]: state => {
      state.inviteFormFetching = true;
    },
    [fetchInvitationData.rejected]: (state, action) => {
      state.inviteFormFetching = false;
      state.displayInviteFailed = true;
      state.inviteMessage = action.payload;
    },
    [completeOnboarding.fulfilled]: state => {
      state.confirmInviteFetching = false;
      state.confirmInvitePassed = true;
      state.inviteMessage = utilVar.success.onboardSuccess;
    },
    [completeOnboarding.pending]: state => {
      state.confirmInviteFetching = true;
    },
    [completeOnboarding.rejected]: state => {
      state.confirmInviteFetching = false;
      state.confirmInviteFailed = true;
      state.inviteMessage = utilVar.errors.onboardFailed;
    }
  }
});

export const {
  resetInviteFilled,
  showFailedInvite,
  fillInviteForm,
  resetInviteSubmitted
} = inviteSlice.actions;
export const getFormSubmitted = state => state.invite.inviteFormSubmitted;
export const getInviteFailed = state => state.invite.displayInviteFailed;
export const getInviteMessage = state => state.invite.inviteMessage;
export const getInviteFormFetching = state => state.invite.inviteFormFetching;
export const getConfirmInviteFetching = state =>
  state.invite.confirmInviteFetching;
export const getConfirmInviteFailed = state => state.invite.confirmInviteFailed;
export const getConfirmInvitePassed = state => state.invite.confirmInvitePassed;

export const getUUID = state => state.invite.uuid;
export const getInviteData = state => {
  return {
    firstName: state.invite.firstName,
    lastName: state.invite.lastName,
    athleteEmail: state.invite.email,
    mobile: state.invite.mobile
  };
};

export default inviteSlice.reducer;
