import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  dateField: {
    classNames: utilFunc.joinClassNames([
      'modal-field',
      'rounded',
      'sf-text-bold'
    ]),
    yearStart: 1900
  }
};
