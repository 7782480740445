import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilMessages } from 'utility/messages';
import * as Yup from 'yup';

const notRecognized = `Sorry, we don't recognize that email address`;
const requiredError = 'Field is required';

export const utilityVariables = {
  loginFlow: {
    classNames: utilFunc.joinClassNames([
      'd-flex',
      'flex-column',
      'justify-content-center',
      'align-items-center'
    ]),
    forgotPassword: {
      header: 'Forgot Password?',
      // prettier-ignore
      // eslint-disable-next-line max-len
      body: 'No worries! Fill in your username and we’ll send you the link to reset your password.',
      cancel: 'Want to go back?',
      cancelLink: 'Cancel',
      fields: [
        {
          label: 'Username',
          type: 'username',
          fieldName: 'username',
          placeholder: ''
        }
      ],
      // This is for email validation
      // validation: Yup.object().shape({
      //   userEmail: Yup.string().required(notRecognized)
      // }),
      initialValues: {
        username: ''
      }
    },
    thankYou: {
      header: 'Thank You!',
      // eslint-disable-next-line max-len
      body: `An email has been sent with a link. Please check your inbox to reset the password.`
    },
    resetPassword: {
      header: 'Reset Password',
      cancel: 'Want to go back?',
      cancelLink: 'Cancel',
      fields: [
        {
          label: 'New password',
          type: 'password',
          fieldName: 'newPass'
        },
        {
          label: 'Confirm password',
          type: 'password',
          fieldName: 'confirmPass'
        }
      ],
      validation: Yup.object().shape({
        newPass: Yup.string().required(requiredError),
        confirmPass: Yup.string()
          .equals([Yup.ref('newPass')], utilMessages.errors.passwordsNotMatch)
          .required(requiredError)
      }),
      initialValues: {
        newPass: '',
        confirmPass: ''
      }
    },
    resetPasswordConfirmation: {
      header: 'Password Reset Successful!',
      body: 'Your password has been reset successfully'
    },
    signUp: {
      cancel: 'Finish the form later?',
      cancelLink: 'Continue Later',
      fields: [
        {
          label: 'Athlete First Name',
          type: 'text',
          fieldName: 'firstName'
        },
        {
          label: 'Athlete Last Name',
          type: 'text',
          fieldName: 'lastName'
        },
        {
          label: 'Athlete Date of Birth',
          type: 'date',
          fieldName: 'dob',
          placeholder: 'YYYY-MM-DD'
        },
        {
          label: 'Athlete Username',
          type: 'username',
          fieldName: 'username'
        },
        {
          label: 'Password',
          type: 'password',
          fieldName: 'clubPass'
        },
        {
          label: 'Confirm Password',
          type: 'password',
          fieldName: 'clubPassConfirmation'
        },
        {
          label: 'Athlete Email',
          type: 'email',
          fieldName: 'clubEmail',
          placeholder: 'manchester@united.com'
        }
      ],
      validation: Yup.object().shape({
        firstName: Yup.string().required(requiredError),
        lastName: Yup.string().required(requiredError),
        clubEmail: Yup.string().required(requiredError),
        clubPass: Yup.string().min(8, 'Password must be at least 8 characters').max(64, 'Password must not exceed 64 characters').required(requiredError),
        username: Yup.string().required(requiredError),
        dob: Yup.string().required(requiredError),
        clubPassConfirmation: Yup.string()
        .equals([Yup.ref('clubPass')], utilMessages.errors.passwordsNotMatch)
        .required(requiredError)
      }),
      initialValues: {
        clubName: '',
        clubEmail: '',
        clubPass: '',
        clubPassConfirmation: ''
      },
      navigateTo: '/thankYou',
      cancelTo: '/'
    },
    signUpNoConsent: {
      cancel: 'Already have an account?',
      cancelLink: 'Login',
      fields: [
        {
          label: 'Athlete First Name',
          type: 'text',
          fieldName: 'firstName'
        },
        {
          label: 'Athlete Last Name',
          type: 'text',
          fieldName: 'lastName'
        },
        {
          label: 'Athlete Date of Birth',
          type: 'date',
          fieldName: 'dob',
          placeholder: 'YYYY-MM-DD'
        },
        {
          label: 'Username',
          type: 'username',
          fieldName: 'username'
        },
        {
          label: 'Password',
          type: 'password',
          fieldName: 'clubPass'
        },
        {
          label: 'Confirm Password',
          type: 'password',
          fieldName: 'clubPassConfirmation'
        },
        {
          label: 'Athlete Email',
          type: 'email',
          fieldName: 'clubEmail',
          placeholder: 'manchester@united.com'
        }
      ],
      validation: Yup.object().shape({
        firstName: Yup.string().required(requiredError),
        lastName: Yup.string().required(requiredError),
        clubEmail: Yup.string().required(requiredError),
        clubPass: Yup.string().required(requiredError),
        username: Yup.string().required(requiredError)
          .matches(/^[a-zA-Z0-9_.]{3,16}$/, 'No spaces or special characters and between 3-16 characters!'),
        dob: Yup.string().required(requiredError),
        clubPassConfirmation: Yup.string()
        .equals([Yup.ref('clubPass')], utilMessages.errors.passwordsNotMatch)
        .required(requiredError)
      }),
      initialValues: {
        firstName: '',
        lastName: '',
        clubName: '',
        clubEmail: '',
        clubPass: '',
        clubPassConfirmation: ''
      },
      navigateTo: '/nextSteps',
      cancelTo: '/'
    },
    logoTitle: {
      logoAlt: 'soccer logo'
    }
  },
  emailConfirmation: {
    redirectTo: '/'
  },
  resetConfirmation: {
    redirectTo: '/'
  },
  resetPasswordForm: {
    navigateTo: '/resetPasswordConfirmation',
    cancelTo: '/'
  },
  verifyEmail: {
    navigateTo: '/forgotPasswordConfirmation',
    cancelTo: '/'
  }
};
