import emailIcon from 'assets/email.png';
import passIcon from 'assets/password.png';

export const utilityVariables = {
  loginFields: {
    email: {
      icon: {
        imgSrc: emailIcon,
        alt: 'email icon',
        width: 17,
        height: 17
      },
      input: {
        type: 'text',
        id: 'loginEmail',
        name: 'loginEmail',
        placeholder: 'USERNAME',
        autoComplete: 'username'
      }
    },
    password: {
      icon: {
        imgSrc: passIcon,
        alt: 'password icon',
        width: 12,
        height: 17
      },
      input: {
        type: 'password',
        id: 'loginPassword',
        name: 'loginPassword',
        placeholder: 'PASSWORD',
        autoComplete: 'current-password'
      }
    }
  }
};
