import React from 'react';
import LoginField from '../LoginField';
import { utilityVariables as utilLogin } from 'utility/login';
import { utilityVariables as utilLoginFields } from 'utility/login/loginFields';

const LoginFieldContainer = () => {
  return (
    <div className={utilLogin.login.classNames.loginFieldFormContainer}>
      <LoginField
        iconProps={utilLoginFields.loginFields.email.icon}
        inputProps={utilLoginFields.loginFields.email.input}
      />
      <hr className='w-75 m-0 float-right' />
      <LoginField
        iconProps={utilLoginFields.loginFields.password.icon}
        inputProps={utilLoginFields.loginFields.password.input}
      />
    </div>
  );
};

export default LoginFieldContainer;
