import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import proInstinctAPI from 'proInstinctAPI';
import jwtDecode from 'jwt-decode';

export const authenticate = createAsyncThunk(
  'authentication/authenticateUser',
  async ({ email, password }) => {
    const { authorization, refresh } = await proInstinctAPI.authenticate(
      email,
      password
    );
    return { authorization, refresh };
  }
);

export const refreshToken = createAsyncThunk(
  'authentication/refreshToken',
  async ({ email, refresh }) => {
    const { permission, refreshToken, jwt } = await proInstinctAPI.refresh(
      email,
      refresh
    );
    return { permission, refreshToken, jwt };
  }
);

const initialState = {
  isAuthenticating: false,
  isRefreshing: false,
  isSignIn: false,
  didAuthFail: false,
  didAuthPass: false,
  didRefreshFail: false,
  didRefreshPass: false,
  didLogoutPass: false,
  permission: null,
  jwtToken: null,
  tokenExpired: true,
  refreshToken: null
};

export const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    signOut(state) {
      state.didLogoutPass = true;
      localStorage.clear();
    },
    resetState() {
      return initialState;
    },
    resetAuthResult(state) {
      state.didAuthFail = false;
      state.didAuthPass = false;
    },
    resetRefreshResult(state) {
      state.didRefreshFail = false;
      state.didRefreshPass = false;
    },
    assignUserState(state, action) {
      const { token, permission } = action.payload;
      state.isSignIn = true;
      state.jwtToken = token;
      state.permission = permission;
      state.tokenExpired = false;
    }
  },
  extraReducers: {
    [authenticate.fulfilled]: (state, action) => {
      const { authorization, refresh } = action.payload;
      const { sub: email, role: permission, userId, clubId } = jwtDecode(
        authorization
      );
      state.isAuthenticating = false;
      state.jwtToken = authorization; //bearer token
      state.refreshToken = refresh;
      state.permission = permission;
      state.isSignIn = true;
      state.didAuthFail = false;
      state.didAuthPass = true;
      state.tokenExpired = false;
      localStorage.setItem('userId', userId);
      localStorage.setItem('clubId', clubId);
      localStorage.setItem('jwt', authorization);
      localStorage.setItem('email', email);
      localStorage.setItem('permission', permission);
      localStorage.setItem('refresh', refresh);
    },
    [authenticate.pending]: state => {
      state.isSignIn = false;
      state.isAuthenticating = true;
      state.didAuthFail = false;
      state.didAuthPass = false;
    },
    [authenticate.rejected]: state => {
      state.isSignIn = false;
      state.isAuthenticating = false;
      state.didAuthFail = true;
      state.didAuthPass = false;
    },
    [refreshToken.fulfilled]: (state, action) => {
      const { refreshToken, permission, jwt } = action.payload;
      state.jwtToken = jwt;
      state.permission = permission;
      state.refreshToken = refreshToken;
      state.isSignIn = true;
      state.isRefreshing = false;
      state.didRefreshPass = true;
      state.tokenExpired = false;
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('permission', permission);
      localStorage.setItem('refresh', refreshToken);
    },
    [refreshToken.pending]: state => {
      state.isRefreshing = true;
    },
    [refreshToken.rejected]: state => {
      state.isSignIn = false;
      state.isRefreshing = false;
      state.didRefreshFail = true;
      state.jwtToken = null;
      state.tokenExpired = true;
      state.permission = null;
      state.refreshToken = null;
      localStorage.clear();
    }
  }
});

export const {
  signOut,
  resetState,
  resetAuthResult,
  resetRefreshResult,
  assignUserState
} = slice.actions;

export const getIsSignIn = state => state.authentication.isSignIn;
export const getIsAuthenticating = state =>
  state.authentication.isAuthenticating;
export const getIsRefreshing = state => state.authentication.isRefreshing;
export const getIsTokenExpired = state => state.authentication.tokenExpired;
export const getDidAuthFail = state => state.authentication.didAuthFail;
export const getDidAuthPass = state => state.authentication.didAuthPass;
export const getDidRefreshFail = state => state.authentication.didRefreshFail;
export const getDidRefreshPass = state => state.authentication.didRefreshPass;
export const getDidLogoutPass = state => state.authentication.didLogoutPass;
export const getPermission = state => state.authentication.permission;

export default slice.reducer;
