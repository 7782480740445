import React from 'react';
import { utilityVariables as utilVar } from 'utility/login';
import pt from 'prop-types';

const LoginFieldIcon = ({ imgSrc, alt, width, height }) => {
  return (
    <div className={utilVar.login.classNames.loginIconContainer}>
      <div className={utilVar.login.classNames.loginIcon}>
        <img
          src={imgSrc}
          alt={alt}
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};

LoginFieldIcon.propTypes = {
  imgSrc: pt.string.isRequired,
  alt: pt.string,
  width: pt.number.isRequired,
  height: pt.number.isRequired
};

LoginFieldIcon.defaultProps = {
  alt: 'loginFieldIcon'
};

export default LoginFieldIcon;
