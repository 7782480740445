import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { getMonthSelection as selectMonth } from 'features/DateRange';

const selectStartDate = state => state.dateChange.startDate;
const selectEndDate = state => state.dateChange.endDate;

const selectMomentDates = createSelector(
  [selectStartDate, selectEndDate],
  (startDate, endDate) => {
    return {
      startDate: moment.unix(startDate),
      endDate: moment.unix(endDate)
    };
  }
);

const selectMonthAsDate = createSelector([selectMonth], selectMonth =>
  moment(selectMonth).toDate()
);

export { selectMomentDates, selectStartDate, selectEndDate, selectMonthAsDate };
