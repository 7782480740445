import React from 'react';
import pt from 'prop-types';
import { utilityFunctions as utilFunc } from 'utility';

const LoginFlowSubmission = ({ spin, setSpin, setErrors, submissionText }) => {
  return (
    <button
      className='loginFormSubmit mt-3 sf-display-bold'
      type='submit'
      onClick={() => {
        utilFunc.resetErrors(setErrors);
      }}
    >
      <bt-2>{submissionText}</bt-2>
    </button>
  );
};

LoginFlowSubmission.propTypes = {
  spin: pt.bool.isRequired,
  setSpin: pt.func.isRequired,
  setErrors: pt.func.isRequired,
  submissionText: pt.string.isRequired
};

export default LoginFlowSubmission;
