import React from 'react';
import { isAndroid, isIOS, isBrowser } from 'react-device-detect';
import AppStore from 'assets/appStore.png';
import PlayStore from 'assets/playStore.png';
import Checkmark_shield from 'assets/Checkmark_shield.png';
import { utilityVariables as utilOnboard } from 'utility/postOnboarding';
import { useHistory } from 'react-router';
import { Button, Row } from 'react-bootstrap';

const PostOnboarding = () => {
  const history = useHistory();

  function redirectHome(e) {
    e.preventDefault();
    history.push('/');
  }

  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <div className='loginFlowBG' />
      <div className='d-flex flex-column align-items-center mx-3'>
        <img
          src={Checkmark_shield}
          width={75}
          alt={'checkmarkShield'}
        />
        <div style={{
          height: '0.75rem'
        }} />
        {isIOS ? (
          <>
            <b-1>{utilOnboard.postOnboarding.iOSTextTop}</b-1>
            <b-1>{utilOnboard.postOnboarding.iOSTextBottom}</b-1>
            <a href={process.env.REACT_APP_IOS_APP}>
              <img
                src={AppStore}
                width={utilOnboard.postOnboarding.logoWidth}
                alt={utilOnboard.postOnboarding.iosLogoAlt}
              />
            </a>
          </>
        ) : null}
        {isAndroid ? (
          <>
            <b-1>{utilOnboard.postOnboarding.androidTextTop}</b-1>
            <b-1>{utilOnboard.postOnboarding.androidTextBottom}</b-1>
            <a href={process.env.REACT_APP_ANDROID_APP}>
              <img
                src={PlayStore}
                width={utilOnboard.postOnboarding.logoWidth}
                alt={utilOnboard.postOnboarding.androidLogoAlt}
              />
            </a>
          </>
        ) : null}
        {isBrowser ? (
          <>
            <h1>Welcome to ProInstinct!</h1>
            <h3> </h3>
            <h3> </h3>
            <h3> </h3>
            <h3> </h3>
            <h3> </h3>
            <h3> </h3>
            <h3>{utilOnboard.postOnboarding.browserTextTop}</h3>
            <h3>{utilOnboard.postOnboarding.browserTextBottom}</h3>
            <span style={{
              marginTop: '1rem'
            }}>
              <Row>
                <a href={process.env.REACT_APP_ANDROID_APP} style={{
                  padding: '1rem',
                  marginRight: '2rem'
                }}>
                  <img
                    src={PlayStore}
                    width={utilOnboard.postOnboarding.logoWidth}
                    alt={utilOnboard.postOnboarding.androidLogoAlt}
                  />
                </a>
                <a href={process.env.REACT_APP_IOS_APP} style={{
                  padding: '1rem',
                  marginLeft: '2rem'
                }}>
                  <img
                    src={AppStore}
                    width={utilOnboard.postOnboarding.logoWidth}
                    alt={utilOnboard.postOnboarding.iosLogoAlt}
                  />
                </a>
              </Row>
            </span>
          </>
        ) : null}
        {/* Don't redirect users back home post onboarding */}
        {/* <Button onClick={redirectHome} className='button-purple' style={{
          marginTop: '3em'
        }}>Return to Homepage</Button> */}
      </div>
    </div>
  );
};

export default PostOnboarding;
