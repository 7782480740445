import React from 'react';
import PasswordField from 'components/Fields/PasswordField';
import pt from 'prop-types';

const PasswordContainer = ({
  label,
  fieldName,
  errors,
  autoComplete,
  border
}) => {
  return (
    <div className='d-flex flex-column mb-3 sf-display-regular'>
      <dh-2 class='text-dark font-weight-bold mb-1'>{label}</dh-2>
      <PasswordField
        fieldName={fieldName}
        autoComplete={autoComplete}
        border={border}
      />
      {errors[fieldName] ? (
        <b-1 class='fieldError ml-1 mt-1'>{errors[fieldName]}</b-1>
      ) : null}
    </div>
  );
};

PasswordContainer.propTypes = {
  label: pt.string.isRequired,
  fieldName: pt.string.isRequired,
  errors: pt.object,
  autoComplete: pt.string.isRequired,
  border: pt.bool
};

PasswordContainer.defaultProps = {
  errors: {},
  border: false
};

export default PasswordContainer;
