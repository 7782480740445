import React from 'react';
import closeHamburger from 'assets/closeHamburger.png';
import pt from 'prop-types';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilIcons } from 'utility/icons';
import './CloseSidebar.scss';

const CloseSidebar = ({
  hamburgerClick,
  setResponsiveClick,
  isHamburgerClicked,
  isResponsiveClicked,
  width
}) => {
  return (
    <img
      className='closeSidebar'
      onClick={() =>
        utilFunc.hamburgerClickHandler(
          hamburgerClick,
          isHamburgerClicked,
          setResponsiveClick,
          isResponsiveClicked,
          width
        )
      }
      src={closeHamburger}
      alt='close hamburger'
      width={utilIcons.icons.closeHamburger.width}
      height={utilIcons.icons.closeHamburger.height}
    />
  );
};

CloseSidebar.propTypes = {
  hamburgerClick: pt.func.isRequired,
  setResponsiveClick: pt.func.isRequired,
  isHamburgerClicked: pt.bool.isRequired,
  isResponsiveClicked: pt.bool.isRequired,
  width: pt.number
};

CloseSidebar.defaultProps = {
  width: window.innerWidth
};

export default CloseSidebar;
