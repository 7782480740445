import React from 'react';
import pt from 'prop-types';
import './ModalFieldRow.scss';

const ModalFieldRow = ({ children }) => {
  return (
    <div className='d-flex modalFieldRow mb-4 flex-sm-row flex-column'>
      {children}
    </div>
  );
};

ModalFieldRow.propTypes = {
  children: pt.node.isRequired
};

export default ModalFieldRow;
