import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

const maxModules = 5;
const selectMonthlyStats = state => state.dashboard.monthlyStats;
const selectModules = state => state.dashboard.topModules;
const selectAthletes = state => state.dashboard.activeAthletes;

const selectConvertedMonths = createSelector(
  [selectMonthlyStats],
  monthlyStats =>
    monthlyStats.map(entry => ({
      ...entry,
      month: moment(entry.month, 'M').format('MMMM')
    }))
);

const selectTopModules = createSelector([selectModules], modules =>
  modules.slice(0, maxModules)
);

const selectAllActiveAthletes = createSelector([selectAthletes], athletes =>
  athletes.map(athlete => ({
    ...athlete,
    place: `${athlete.place < 10 ? `0${athlete.place}` : `${athlete.place}`}`,
    lastDate: athlete.lastDate
      ? moment(moment.utc(athlete.lastDate)).local().format('DD MMM YYYY')
      : ''
  }))
);

export { selectConvertedMonths, selectTopModules, selectAllActiveAthletes };
