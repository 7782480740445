import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ComingSoonTooltip from 'components/ComingSoonTooltip';
import pt from 'prop-types';
import './DashboardHeaderLink.scss';

const DashboardHeaderLink = ({ linkTo, linkName }) => {
  return (
    <OverlayTrigger
      placement='auto'
      delay={{ show: 100, hide: 100 }}
      overlay={ComingSoonTooltip}
    >
      <h4 className='dashboard-header-link sf-display-regular'>
        <a href={linkTo} className='main-nav text-dark d-inline-block'>
          {linkName}
        </a>
      </h4>
    </OverlayTrigger>
  );
};

DashboardHeaderLink.propTypes = {
  linkTo: pt.string.isRequired,
  linkName: pt.string.isRequired
};

export default DashboardHeaderLink;
