import React, { useState } from 'react';
import { Field } from 'formik';
import { utilityVariables as utilVar } from 'utility/fields/passwordField';
import eyeIcon from 'assets/eye.png';
import pt from 'prop-types';
import './PasswordField.scss';

const PasswordField = ({ fieldName, autoComplete, border }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={`d-flex passContainer ${border ? 'border' : null}`}>
      <Field
        className='passField border-0'
        type={visible ? 'text' : 'password'}
        id={fieldName}
        name={fieldName}
        autoComplete={autoComplete}
      />
      <button
        className='passwordDisplayIcon border-0 px-3'
        onClick={() => setVisible(!visible)}
        type='button'
      >
        <img
          src={eyeIcon}
          alt={utilVar.passwordField.iconAlt}
          width={utilVar.passwordField.iconWidth}
        />
      </button>
    </div>
  );
};

PasswordField.propTypes = {
  fieldName: pt.string.isRequired,
  autoComplete: pt.string,
  border: pt.bool
};

PasswordField.defaultProps = {
  autoComplete: 'current-password',
  border: false
};

export default PasswordField;
