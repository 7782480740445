import { setTokenNotAuthentic } from 'features/API';
import {
  assignUserState,
  getDidLogoutPass,
  getDidRefreshFail,
  getIsRefreshing,
  getIsTokenExpired,
  getPermission,
  refreshToken,
  signOut
} from 'features/Authentication';
import { getDidLogoutCancel, resetLogout } from 'features/Modal';
import { setCurrentPage } from 'features/Navigation';
import { setTeamData } from 'features/TeamIds';
import {
  fetchClubAdmins,
  fetchClubInfo,
  getClubInfo,
  getUpdatingSettingsPass,
  resetSettingsResult
} from 'features/Settings';
import pt from 'prop-types';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { utilityFunctions as utilFunc } from 'utility';

// going to add fetch club info here
// so that the club name can be updated in the sidebar

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenExpired = useSelector(getIsTokenExpired);
  const isRefreshing = useSelector(getIsRefreshing);
  const userPermission = useSelector(getPermission);
  const didRefreshFail = useSelector(getDidRefreshFail);
  const logoutCancel = useSelector(getDidLogoutCancel);
  const logoutPassed = useSelector(getDidLogoutPass);
  const clubInfo = useSelector(getClubInfo);
  const settingsUpdated = useSelector(getUpdatingSettingsPass);

  useEffect(() => {
    if (didRefreshFail) {
      history.push('/');
    }
  }, [didRefreshFail, history]);

  useEffect(() => {
    if (logoutCancel && history.location.pathname !== '/logout') {
      dispatch(resetLogout());
    }

    if (history.location.pathname !== '/logout') {
      dispatch(setCurrentPage(history.location.pathname));
    }
  }, [dispatch, history.location.pathname, logoutCancel]);

  useEffect(() => {
    // we have to fetch club info here because the club name
    // is in the sidebar as well
    if (!tokenExpired && (!clubInfo || settingsUpdated)) {
      dispatch(resetSettingsResult());
      dispatch(fetchClubInfo(true));
      dispatch(
        fetchClubAdmins({
          toFetch: true,
          permission: localStorage.getItem('permission')
        })
      );
    }
  }, [clubInfo, dispatch, settingsUpdated, tokenExpired]);

  useEffect(() => {
    if (!localStorage.getItem('jwt') && !logoutPassed) {
      dispatch(setTokenNotAuthentic());
      dispatch(signOut());
    }
  }, [dispatch, logoutPassed]);

  // taking this out of a useEffect so it runs before the child
  // component is rendered!
  if (!isRefreshing) {
    if (utilFunc.decodedJWT()?.exp < utilFunc.currentTimestamp()) {
      dispatch(
        refreshToken({
          email: localStorage.getItem('email'),
          refresh: localStorage.getItem('refresh')
        })
      );
    } else {
      if (!userPermission && !didRefreshFail) {
        dispatch(
          setTeamData(localStorage.getItem('teamData'))
        );
        dispatch(
          assignUserState({
            token: localStorage.getItem('jwt'),
            permission: localStorage.getItem('permission')
          })
        );
      }
    }
  }

  return tokenExpired || didRefreshFail ? (
    <div className='d-flex justify-content-center align-items-center'>
      <Spinner animation='border' variant='primary' />
    </div>
  ) : (
    children
  );
};

PrivateRoute.propTypes = {
  children: pt.node.isRequired
};

export default PrivateRoute;
