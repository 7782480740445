import {
  utilityFunctions as utilFunc,
  utilityVariables as utilVar
} from 'utility';
import { utilityVariables as utilMessages } from 'utility/messages';
import store from '../../store';
import * as Yup from 'yup';
import { updateClubInfo } from 'features/Settings';
const requiredError = 'Field is required';

export const utilityVariables = {
  settings: {
    title: 'Settings',
    adminFields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      //prettier-ignore
      adminEmail: 'Admin\'s Email',
      mobile: 'Phone Number',
      dob: 'Birthday'
    },
    notifications: {
      label: 'Email Notifications',
      submit: value => {
        store.dispatch(updateClubInfo({ notifications: value }));
      }
    },
    footer: {
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions'
    },
    clubName: {
      label: 'Club Name',
      type: 'text',
      fieldName: 'clubName',
      validation: Yup.object().shape({
        clubName: Yup.string().required(requiredError)
      }),
      submit: values => {
        store.dispatch(updateClubInfo({ clubName: values.clubName }));
      }
    },
    clubEmail: {
      label: 'Club Email',
      type: 'email',
      fieldName: 'clubEmail',
      validation: initialEmail =>
        Yup.object().shape({
          clubEmail: Yup.string()
            .test(
              utilVar.emailDuplicated,
              utilVar.emailDuplicatedError,
              value => utilFunc.isEmailNotDuplicate(value, initialEmail)
            )
            .required(requiredError)
        }),
      submit: values => {
        store.dispatch(updateClubInfo({ clubEmail: values.clubEmail }));
      }
    },
    clubPassword: {
      label: 'Club Password'
    },
    fieldEdit: {
      editBtnIcon: {
        alt: 'editSetting',
        height: 26,
        width: 26
      },
      classNames: {
        editBtnContainer: utilFunc.joinClassNames([
          'settingsEditEntryButton',
          'actionTableAdd-sf-lg',
          'd-flex',
          'align-items-center',
          'justify-content-center'
        ]),
        formContainer: utilFunc.joinClassNames([
          'd-flex',
          'flex-md-row',
          'flex-column',
          'justify-content-between',
          'w-100',
          'formContainer'
        ]),
        btnContainer: utilFunc.joinClassNames([
          'd-flex',
          'justify-content-between',
          'mx-md-0',
          'mx-4',
          'mt-md-0',
          'mt-2'
        ]),
        cancel: utilFunc.joinClassNames([
          'settingsCancel',
          'border-0',
          'rounded',
          'sf-display-bold',
          'text-white',
          'bg-secondary'
        ]),
        confirm: utilFunc.joinClassNames([
          'settingsConfirm',
          'mr-2',
          'border-0',
          'rounded',
          'sf-display-bold',
          'text-white',
          'bg-success'
        ])
      }
    },
    changePassword: {
      cancel: utilFunc.joinClassNames([
        'changeCancel',
        'border-0',
        'rounded',
        'w-100',
        'mb-2',
        'sf-display-bold',
        'text-white',
        'bg-secondary'
      ]),
      confirm: utilFunc.joinClassNames([
        'changeConfirm',
        'mb-2',
        'border-0',
        'rounded',
        'w-100',
        'sf-display-bold',
        'text-white',
        'bg-success'
      ]),
      oldPassword: {
        label: 'Old Password',
        fieldName: 'oldPassword'
      },
      newPassword: {
        label: 'New Password',
        fieldName: 'newPassword'
      },
      repeatPassword: {
        label: 'Confirm Password',
        fieldName: 'repeatPassword'
      },
      initialValues: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: ''
      },
      schema: email =>
        Yup.object().shape({
          // eslint-disable-next-line max-len
          oldPassword: Yup.string()
            .test(
              utilMessages.errors.oldPasswordTitle,
              utilMessages.errors.oldPasswordIncorrect,
              value => utilFunc.isOldPassCorrect(email, value)
            )
            .required(requiredError),
          newPassword: Yup.string().required(requiredError),
          repeatPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords not matching')
            .required(requiredError)
        }),
      submit: values => {
        store.dispatch(
          updateClubInfo({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          })
        );
      }
    },
    deleteAccount: {
      title: 'Delete Account',
      body: 'This action cannot be undone',
      buttonText: 'DELETE ACCOUNT',
      buttonClassNames: utilFunc.joinClassNames([
        'accountDelete',
        'border-0',
        'bg-danger',
        'd-flex',
        'align-items-center',
        'justify-content-center'
      ])
    },
    editAccount: {
      title: 'EDIT ACCOUNT'
    }
  }
};
