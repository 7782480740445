import React from 'react';
import { useHistory } from 'react-router-dom';
import pt from 'prop-types';

const LoginFlowRedirect = ({ linkRedirect, buttonText }) => {
  const history = useHistory();

  return (
    <button
      className='loginFormSubmit mt-3 sf-display-bold'
      onClick={() => history.push(linkRedirect)}
    >
      <bt-2>{buttonText}</bt-2>
    </button>
  );
};

LoginFlowRedirect.propTypes = {
  linkRedirect: pt.string.isRequired,
  buttonText: pt.string.isRequired
};

export default LoginFlowRedirect;
