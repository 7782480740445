import React from 'react';
import LoginFieldIcon from '../LoginFieldIcon';
import LoginFieldInput from '../LoginFieldInput';
import { utilityVariables as utilVar } from 'utility/login';
import pt from 'prop-types';

const LoginField = ({ iconProps, inputProps }) => {
  return (
    <div className={utilVar.login.classNames.loginFieldContainer}>
      <LoginFieldIcon
        imgSrc={iconProps.imgSrc}
        alt={iconProps.alt}
        width={iconProps.width}
        height={iconProps.height}
      />
      <LoginFieldInput
        type={inputProps.type}
        id={inputProps.id}
        name={inputProps.name}
        placeholder={inputProps.placeholder}
        autoComplete={inputProps.placeholder}
      />
    </div>
  );
};

LoginField.propTypes = {
  iconProps: pt.object.isRequired,
  inputProps: pt.object.isRequired
};

export default LoginField;
