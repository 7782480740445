import React from 'react';
import pt from 'prop-types';
import BarChart from 'components/BarChart';
import { utilityVariables as utilVar } from 'utility/dashboard/trainingHours';

const TrainingHours = ({ monthlyStats }) => {
  return (
    <div className='mt-3 bg-white'>
      <BarChart
        header={utilVar.trainingHours.header}
        subheader={utilVar.trainingHours.subHeader}
        data={monthlyStats}
      />
    </div>
  );
};

TrainingHours.propTypes = {
  monthlyStats: pt.array.isRequired
};

export default TrainingHours;
