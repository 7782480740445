import { createSlice } from '@reduxjs/toolkit';

const promptSlice = createSlice({
  name: 'prompt',
  initialState: {
    message: null,
    promptID: null,
    variant: null
  },
  reducers: {
    showPrompt: {
      reducer(state, action) {
        const { message, variant } = action.payload;
        state.message = message;
        state.variant = variant;
      },
      prepare(message, variant) {
        return {
          payload: {
            message, variant
          }
        };
      }
    },
    setPromptID: {
      reducer(state, action) {
        const { promptID } = action.payload;
        clearTimeout(state.promptID);
        state.promptID = promptID;
      },
      prepare(promptID) {
        return {
          payload: {
            promptID
          }
        };
      }
    },
    hidePrompt(state) {
      state.promptID = null;
    }
  }
});

export const { showPrompt, hidePrompt, setPromptID } = promptSlice.actions;

export const getMessagePrompt = state => state.prompt.message;
export const getMessageVariant = state => state.prompt.variant;
export const getPromptID = state => state.prompt.promptID;

export default promptSlice.reducer;
