import React, { useEffect, useState } from 'react';
import { utilityVariables as utilVar } from 'utility/login/loginFlow';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import LoginFlowForm from 'components/LoginFlow/LoginFlowForm';
import { useHistory } from 'react-router';

const SignUp = () => {
  const [spin, setSpin] = useState(false);
  const [createUserJSON, setCreateUserJSON] = useState({
    'parentEmail': '', // webhook
    'firstName': '', // webhook
    'lastName': '', // webhook
    'teamId': 1, // webhook
    'inviteCode': '',
    'dateOfBirth': '', // input
    'username': '', // input
    'email': '', // input
    'password': '' // input
  });

  const history = useHistory();
  const code = localStorage.getItem('onboardCode');

  useEffect(() => {
    if (!code) {
      alert('Please input a valid sign up code first.');
      localStorage.clear();
      history.push('/accessCode');
    }

    setCreateUserJSON({
      ...createUserJSON,
      parentEmail: localStorage.getItem('onboardParentEmail'),
      firstName: localStorage.getItem('onboardFirstName'), 
      lastName: localStorage.getItem('onboardLastName'),
      teamId: parseInt(localStorage.getItem('onboardTeamIds')),
      inviteCode: localStorage.getItem('onboardCode')
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  }, [createUserJSON]);

  return (
    <>
      <div className='loginFlowBG' />
        <div className={utilVar.loginFlow.classNames}>
          <div className='signUpFlowContainer'>
            <LogoTitle />
            <LoginFlowForm
              initialValues={{ 
                ...utilVar.loginFlow.signUp.initialValues, 
                parentEmail: localStorage.getItem('onboardParentEmail'),
                firstName: localStorage.getItem('onboardFirstName'), 
                lastName: localStorage.getItem('onboardLastName'),
                teamId: localStorage.getItem('onboardTeamIds')
              }}
              schema={utilVar.loginFlow.signUp.validation}
              spin={spin}
              setSpin={setSpin}
              loginFlowFields={utilVar.loginFlow.signUp.fields}
              submissionLabel={BTN_LOCALIZATIONS['en'].SIGNUP}
              navigateTo={utilVar.loginFlow.signUp.navigateTo}
              createUserJSON={createUserJSON}
            />
        </div>
      </div>
    </>
  );
};

export default SignUp;
