import React from 'react';
import LoginFlowInstructions from 'components/LoginFlow/LoginFlowInstructions';
import LoginFlowRedirect from 'components/LoginFlow/LoginFlowRedirect';
import { utilityVariables as utilLoginFlow } from 'utility/login/loginFlow';
import { BTN_LOCALIZATIONS } from 'utility/buttons';

const ResetConfirmation = () => {
  return (
    <>
      <div className='loginFlowBG' />
      <div className={utilLoginFlow.loginFlow.classNames}>
        <div className='loginFlowContainer'>
          <LoginFlowInstructions
            title={utilLoginFlow.loginFlow.resetPasswordConfirmation.header}
            body={utilLoginFlow.loginFlow.resetPasswordConfirmation.body}
          />
          <LoginFlowRedirect
            linkRedirect={utilLoginFlow.resetConfirmation.redirectTo}
            buttonText={BTN_LOCALIZATIONS['en'].LOGIN}
          />
        </div>
      </div>
    </>
  );
};

export default ResetConfirmation;
