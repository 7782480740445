import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: null
};

export const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    }
  }
});

export const { setCurrentPage } = slice.actions;

export const getCurrentPage = state => state.navigation.currentPage;
export default slice.reducer;
