import { showPrompt, hidePrompt, setPromptID } from 'features/Prompt';
import { promptConstants } from './PromptConstants';

const triggerPrompt = (message, variant, dispatch) => {
  dispatch(showPrompt(message, variant));
  const promptID = setTimeout(() => {
    dispatch(hidePrompt());
  }, promptConstants.duration);
  dispatch(setPromptID(promptID));
};

export { triggerPrompt };
