class BTN_TEXT {
  constructor(text) {
    this.CANCEL = text.CANCEL;
    this.ADD = text.ADD;
    this.EDIT = text.EDIT;
    this.HIDE = text.HIDE;
    this.SHOW = text.SHOW;
    this.SAVE = text.SAVE;
    this.INVITE = text.INVITE;
    this.DELETE = text.DELETE;
    this.NO = text.NO;
    this.YES = text.YES;
    this.LOGIN = text.LOGIN;
    this.CONTINUE = text.CONTINUE;
    this.RETURN = text.RETURN;
    this.SIGNUP = text.SIGNUP;
    this.RESETPASSWORD = text.RESETPASSWORD;
    this.REGISTER = text.REGISTER;
  }
}

export const BTN_LOCALIZATIONS = {
  en: new BTN_TEXT({
    CANCEL: 'CANCEL',
    ADD: 'ADD',
    EDIT: 'EDIT',
    HIDE: 'Hide All',
    SHOW: 'Show All',
    SAVE: 'SAVE',
    INVITE: 'INVITE',
    DELETE: 'DELETE',
    NO: 'NO',
    YES: 'YES',
    LOGIN: 'LOGIN',
    CONTINUE: 'CONTINUE',
    RETURN: 'GO BACK',
    SIGNUP: 'SIGN UP',
    RESETPASSWORD: 'RESET PASSWORD',
    REGISTER: 'REGISTER'
  })
};
