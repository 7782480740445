import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { authenticate, getIsAuthenticating } from 'features/Authentication';

import LoginSpinner from '../LoginSpinner';
import LoginSubmission from '../LoginSubmission';
import LoginFieldContainer from '../LoginFieldContainer';
import { utilityVariables as utilVar } from 'utility/login';

const LoginForm = () => {
  const dispatch = useDispatch();
  const isAuthenticating = useSelector(getIsAuthenticating);

  return (
    <Formik
      initialValues={utilVar.login.initialValues}
      onSubmit={values => {
        dispatch(
          authenticate({
            email: values.loginEmail,
            password: values.loginPassword
          })
        );
      }}
    >
      <Form className={utilVar.login.classNames.loginForm}>
        <LoginFieldContainer />
        <LoginSpinner show={isAuthenticating} />
        <LoginSubmission />
      </Form>
    </Formik>
  );
};

export default LoginForm;
