import React from 'react';
import AthletesTableRow from '../AthletesTableRow';
import AthletesTableRowContainer from '../AthletesTableRowContainer';
import pt from 'prop-types';
import { utilityVariables as utilVar } from 'utility/dashboard/athletesTable';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import './AthletesTable.scss';

const AthletesTable = ({
  activeAthletes,
  expandAthletes,
  setExpandAthletes
}) => {
  return (
    <div className='px-4 mt-3 tableWrapper'>
      <div className='d-flex mt-2'>
        <div className='d-flex flex-column mr-auto mt-3'>
          <b-1 class='sf-display-bold m-0'>{utilVar.athletesTable.title}</b-1>
          <b-2 class='sf-display-regular'>{utilVar.athletesTable.subtitle}</b-2>
        </div>
        <b-2
          class='sf-display-regular align-self-center athletesTableLink'
          onClick={setExpandAthletes}
        >
          {expandAthletes
            ? BTN_LOCALIZATIONS['en'].HIDE
            : BTN_LOCALIZATIONS['en'].SHOW}
        </b-2>
      </div>
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead className='text-center'>
            <tr className='sf-display-medium'>
              {utilVar.athletesTable.headers.map((header, idx) => (
                <th
                  key={`${header}${idx}`}
                  className={`${header.className} athletesTableHeader`}
                >
                  <b-1>{header.text}</b-1>
                </th>
              ))}
            </tr>
          </thead>

          {/* Logic to applying transitions. 
          When athletes is not expanded and it's
          the 3rd row or more then hide it Otherwise show the row
          AthletesTableRowContainer has CSSTransition which will perform the
          transition */}

          <tbody className='text-center'>
            {activeAthletes.map((entry, index) =>
              !expandAthletes && index >= utilVar.athletesTable.maxAthletes ? (
                <AthletesTableRowContainer key={index}>
                  <AthletesTableRow
                    place={entry.place}
                    athlete={entry.name}
                    status={entry.status}
                    lastDate={entry.lastDate}
                    hours={entry.hours}
                  />
                </AthletesTableRowContainer>
              ) : (
                <AthletesTableRowContainer key={index} in>
                  <AthletesTableRow
                    place={entry.place}
                    athlete={entry.name}
                    status={entry.status}
                    lastDate={entry.lastDate}
                    hours={entry.hours}
                  />
                </AthletesTableRowContainer>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AthletesTable.propTypes = {
  activeAthletes: pt.array,
  expandAthletes: pt.bool,
  setExpandAthletes: pt.func.isRequired
};

AthletesTable.defaultProps = {
  activeAthletes: [],
  expandAthletes: false
};

export default AthletesTable;
