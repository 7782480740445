import React from 'react';
import { utilityVariables as utilVar } from 'utility/dashboard/dashboardHeader';
import DashboardHeaderLink from './DashboardHeaderLink';

const DashboardHeader = () => {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-column flex-sm-row'>
        <h2 className='sf-display-bold dashboard-title mr-auto'>
          {utilVar.dashboardHeader.title}
        </h2>
        <div className='d-flex align-items-center'>
          <DashboardHeaderLink linkTo='/Analytics' linkName='Analytics' />
          <DashboardHeaderLink linkTo='/Watchlist' linkName='Watchlist' />
        </div>
      </div>
      <hr className='mt-2' />
    </div>
  );
};

export default DashboardHeader;
