import React from 'react';
import { utilityVariables as utilSettings } from 'utility/settings';
import pt from 'prop-types';
import './DeleteButton.scss';

const DeleteButton = ({ onClick }) => {
  return (
    <button
      className={utilSettings.settings.deleteAccount.buttonClassNames}
      onClick={onClick}
    >
      <b-2 class='sf-display-bold text-white'>DELETE ACCOUNT</b-2>
    </button>
  );
};

DeleteButton.propTypes = {
  onClick: pt.func.isRequired
};

export default DeleteButton;
