import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proInstinctAPI from 'proInstinctAPI';

export const fetchClubAdmins = createAsyncThunk(
  'settings/fetchClubAdmins',
  async ({ toFetch, permission }) => {
    const { club } = await proInstinctAPI.fetchClub(toFetch, permission);
    return club;
  }
);

export const fetchClubInfo = createAsyncThunk(
  'settings/fetchClubInfo',
  async (userPayload, { rejectWithValue }) => {
    const { clubInfo } = await proInstinctAPI.fetchClubInfo(
      userPayload,
      rejectWithValue
    );
    return clubInfo;
  }
);

export const updateClubInfo = createAsyncThunk(
  'settings/updateClubInfo',
  async (userPayload, { rejectWithValue }) =>
    await proInstinctAPI.updateClubInfo(userPayload, rejectWithValue)
);

const initialState = {
  admins: null,
  clubInfo: null,
  fetchingClubInfo: true,
  didFetchInfoFail: false,
  fetchingAdmins: true,
  didFetchFail: false,
  updatingSettings: false,
  didUpdatePass: false,
  didUpdateFail: false,
  settingsMessage: ''
};

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettingsResult(state) {
      state.didFetchInfoFail = false;
      state.didFetchFail = false;
      state.didUpdatePass = false;
      state.didUpdateFail = false;
    }
  },
  extraReducers: {
    [fetchClubAdmins.fulfilled]: (state, action) => {
      state.admins = action.payload;
      state.fetchingAdmins = false;
    },
    [fetchClubAdmins.pending]: state => {
      state.fetchingAdmins = true;
    },
    [fetchClubAdmins.rejected]: state => {
      state.admins = null;
      state.fetchingAdmins = false;
      state.didFetchFail = true;
    },
    [updateClubInfo.fulfilled]: (state, action) => {
      state.settingsMessage = action.payload;
      state.updatingSettings = false;
      state.didUpdatePass = true;
    },
    [updateClubInfo.pending]: state => {
      state.updatingSettings = true;
    },
    [updateClubInfo.rejected]: (state, action) => {
      state.settingsMessage = action.payload;
      state.updatingSettings = false;
      state.didUpdateFail = true;
    },
    [fetchClubInfo.fulfilled]: (state, action) => {
      state.clubInfo = action.payload;
      state.fetchingClubInfo = false;
    },
    [fetchClubInfo.pending]: state => {
      state.fetchingClubInfo = true;
    },
    [fetchClubInfo.rejected]: state => {
      state.fetchingClubInfo = false;
      state.didFetchInfoFail = true;
    }
  }
});

export const { resetSettingsResult } = slice.actions;

export const getClubAdmins = state => state.settings.admins;
export const getFetchingAdmins = state => state.settings.fetchingAdmins;
export const getDidFetchFail = state => state.settings.didFetchFail;

export const getClubInfo = state => state.settings.clubInfo;
export const getFetchingClubInfo = state => state.settings.fetchingClubInfo;
export const getFetchingClubInfoFailed = state =>
  state.settings.didFetchInfoFail;

export const getUpdatingSettings = state => state.settings.updatingSettings;
export const getUpdatingSettingsPass = state => state.settings.didUpdatePass;
export const getUpdatingSettingsFail = state => state.settings.didUpdateFail;
export const getSettingsMessage = state => state.settings.settingsMessage;

export default slice.reducer;
