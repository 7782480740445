import React from 'react';
import pt from 'prop-types';

const FieldContainer = ({ children, background }) => {
  return <div className={`${background} p-3 rounded mb-4`}>{children}</div>;
};

FieldContainer.propTypes = {
  children: pt.node.isRequired,
  background: pt.string
};

FieldContainer.defaultProps = {
  background: 'bg-white'
};

export default FieldContainer;
