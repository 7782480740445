import React from 'react';
import Modal from 'react-bootstrap/Modal';
import MediumModalHeader from '../MediumModalHeader';
import MediumModalBody from '../MediumModalBody';
import MediumModalFooter from '../MediumModalFooter';
import { useDispatch, useSelector } from 'react-redux';
import infoModalIcon from 'assets/info@2x.png';
import {
  toggleLogoutSuccess,
  toggleLogoutCancel,
  getShowLogout
} from 'features/Modal';
import { utilityVariables as utilVar } from 'utility/modal';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import '../Modal.scss';

const LogoutConfirm = () => {
  const dispatch = useDispatch();
  const showLogout = useSelector(getShowLogout);
  const cancelLogout = () => dispatch(toggleLogoutCancel());
  const submitLogout = () => dispatch(toggleLogoutSuccess());

  return (
    <Modal
      show={showLogout}
      onHide={() => dispatch(toggleLogoutCancel())}
      size='md'
    >
      <MediumModalHeader
        icon={infoModalIcon}
        alt={utilVar.modal.logout.modalHeaderAlt}
        title={utilVar.modal.logout.title}
      />
      <MediumModalBody content={utilVar.modal.logout.message} />
      <MediumModalFooter
        onCancel={cancelLogout}
        onSubmit={submitLogout}
        cancelText={BTN_LOCALIZATIONS['en'].NO}
        confirmText={BTN_LOCALIZATIONS['en'].YES}
      />
    </Modal>
  );
};

export default LogoutConfirm;
