import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  dateSelectionButton: {
    classNames: {
      dateSelection: utilFunc.joinClassNames([
        'offsetDate',
        'd-flex',
        'align-items-center'
      ])
    },
    chevron: {
      width: 6,
      height: 10
    }
  }
};
