import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { getClubAdmins as selectClubAdmins } from '.';

const selectConvertedClubAdmins = createSelector(
  [selectClubAdmins],
  clubAdmins => {
    if (!clubAdmins) return [];

    return clubAdmins.map(user => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      adminEmail: user.email,
      mobile: user.phoneNumber || '',
      dob: moment(user.dateOfBirth).utc().format('YYYY-MM-DD')
    }));
  });

export { selectConvertedClubAdmins };
