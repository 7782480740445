import React, { useState } from 'react';
import Select from 'react-select';
import pt from 'prop-types';
import './SelectField.scss';

const SelectField = ({
  name,
  className,
  options,
  placeholder,
  setValue,
  userTeam
}) => {
  const [selected, setSelected] = useState(userTeam.type);

  return (
    <Select
      value={options.filter(option => option.label === selected)}
      classNamePrefix={className ? 'select-field-login-flow' : 'select-field'}
      options={options}
      placeholder={placeholder}
      onChange={e => {
        setSelected(e.label);
        setValue(name, e.value);
      }}
    />
  );
};

SelectField.propTypes = {
  name: pt.string,
  className: pt.string,
  options: pt.array,
  placeholder: pt.string,
  setValue: pt.func.isRequired,
  userTeam: pt.string
};

SelectField.defaultProps = {
  name: '',
  className: '',
  options: [],
  placeholder: 'placeholder',
  userTeam: ''
};

export default SelectField;
