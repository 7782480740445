import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import {
  getMessagePrompt,
  getMessageVariant,
  getPromptID
} from 'features/Prompt';

const LoginAlert = () => {
  const message = useSelector(getMessagePrompt);
  const variant = useSelector(getMessageVariant);
  const promptID = useSelector(getPromptID);

  return (
    <div className='loginAlert w-25'>
      <Alert show={promptID !== null} className='text-center' variant={variant}>
        {message}
      </Alert>
    </div>
  );
};

export default LoginAlert;
