import React from 'react';
import ToggleSwitch from 'components/ToggleSwitch';
import pt from 'prop-types';
import { utilityVariables as utilVar } from 'utility/settings';

const EmailNotifications = ({ setState, state, width }) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='field d-flex flex-column sf-display-regular'>
        <dh-2 class='text-dark font-weight-bold mb-1 sf-display-regular'>
          {utilVar.settings.notifications.label}
        </dh-2>
      </div>
      <ToggleSwitch
        setState={setState}
        state={state}
        width={width}
      />
    </div>
  );
};

EmailNotifications.propTypes = {
  setState: pt.func.isRequired,
  state: pt.bool.isRequired,
  width: pt.number
};

EmailNotifications.defaultProps = {
  width: window.innerWidth
};

export default EmailNotifications;
