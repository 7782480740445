import React, { cloneElement } from 'react';
import * as Yup from 'yup';
import { updateClubUser } from 'features/Athletes';
import {
  utilityFunctions as utilFunc,
  utilityVariables as utilVar
} from 'utility';
import ModalField from 'components/Modals/ModalField';
import ModalFieldRow from 'components/Modals/ModalFieldRow';
import InputField from 'components/Fields/InputField';
import DateField from 'components/Fields/DateField';
import PhoneField from 'components/Fields/PhoneField';
import SelectField from 'components/Fields/SelectField';
import { utilityVariables as utilInput } from 'utility/fields/inputField';
import { utilityVariables as utilPhone } from 'utility/fields/phoneField';

const phoneNumberInvalid = `Please enter a valid phone number`;
const phoneNumberCheck = /^$|[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}/;
const requiredError = 'Field is required';

export const utilityFunctions = {
  configuringModalFields: (userFields, errors) => {
    let currentFields = [];
    return userFields.reduce((acc, curr, index) => {
      const props = {
        ...utilityVariables.modal.fields[curr.name],
        key: `${curr.name}_${index}`,
        error: errors[curr.name]
      };

      // prettier-ignore
      currentFields.push(
        <ModalField {...props}>
          {curr.field
            ? cloneElement(curr.field, {
              name: curr.name,
              placeholder: utilityVariables.modal.fields[curr.name].placeholder
            })
            : null}
        </ModalField>
      );

      if (index % 2 === 1) {
        acc.push(
          <ModalFieldRow key={Math.floor(index / 2)}>
            {currentFields}
          </ModalFieldRow>
        );
        currentFields = [];
      }

      return acc;
    }, []);
  }
};

export const utilityVariables = {
  validateUser: initialEmail =>
    Yup.object().shape({
      firstName: Yup.string().required(requiredError),
      dob: Yup.date().required(requiredError),
      parentEmail: Yup.string().required(requiredError),
      team: Yup.lazy(value => {
        if (typeof value === 'number') {
          return Yup.number().required(requiredError);
        } else {
          return Yup.object().required(requiredError);
        }
      })
    }),
  modal: {
    addUser: {
      title: 'ADD NEW ATHLETE',
      modalHeaderAlt: 'addModalIcon'
    },
    editUser: {
      title: 'EDIT ATHLETE',
      modalHeaderAlt: 'editModalIcon',
      athlete: {
        fields: (
          setFieldValue,
          values,
          setParentDisabled,
          parentDisabled,
          editUserFilled,
          teams
        ) => [
          {
            name: 'firstName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'lastName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'athleteEmail',
            field: (
              <InputField
                type='email'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'mobile',
            field: (
              <PhoneField
                setValue={setFieldValue}
                className={utilPhone.phoneField.classNames.phone}
                mobile={values.mobile}
              />
            )
          },
          {
            name: 'dob',
            field: (
              <DateField
                setFieldValue={setFieldValue}
                setParentDisabled={setParentDisabled}
                dob={values.dob}
              />
            )
          },
          {
            name: 'parentEmail',
            field: (
              <InputField
                type='email'
                disabled={parentDisabled}
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'team',
            field: (
              <SelectField
                setValue={setFieldValue}
                userTeam={editUserFilled.team}
                options={teams}
              />
            )
          },
          {}
        ],
        initial: {
          firstName: '',
          lastName: '',
          athleteEmail: '',
          mobile: '',
          dob: '',
          parentEmail: '',
          team: ''
        },
        onSubmit: (values, dispatch, parentDisabled) => {
          const userPayload = {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.athleteEmail,
            parentEmail: parentDisabled ? null : values.parentEmail,
            mobile: values.mobile,
            dob: values.dob,
            // if team hasn't changed then it will be the object property id
            // otherwise it is values.team
            team: values.team.id || values.team
          };
          dispatch(updateClubUser(userPayload));
        }
      },
      admin: {
        fields: (setFieldValue, values, setParentDisabled) => [
          {
            name: 'firstName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'lastName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'adminEmail',
            field: (
              <InputField
                type='email'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'mobile',
            field: (
              <PhoneField
                setValue={setFieldValue}
                className={utilPhone.phoneField.classNames.phone}
                mobile={values.mobile}
              />
            )
          },
          {
            name: 'dob',
            field: (
              <DateField
                setFieldValue={setFieldValue}
                setParentDisabled={setParentDisabled}
                dob={values.dob}
              />
            )
          },
          {}
        ],
        initial: {
          firstName: '',
          lastName: '',
          adminEmail: '',
          mobile: '',
          dob: ''
        },
        schema: initialEmail =>
          Yup.object().shape({
            firstName: Yup.string().required(requiredError),
            lastName: Yup.string().required(requiredError),
            adminEmail: Yup.string()
              .test(
                utilVar.emailDuplicated,
                utilVar.emailDuplicatedError,
                value => utilFunc.isEmailNotDuplicate(value, initialEmail)
              )
              .required(requiredError),
            mobile: Yup.string().matches(phoneNumberCheck, phoneNumberInvalid),
            dob: Yup.date().required(requiredError)
          }),
        onSubmit: (values, dispatch) => {
          const userPayload = {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.adminEmail,
            mobile: values.mobile,
            dob: values.dob
          };
          dispatch(updateClubUser(userPayload));
        }
      }
    },
    deleteUser: {
      title: 'ARE YOU SURE?',
      adminTitle: 'DELETE YOUR ACCOUNT?',
      message: 'You are about to delete this row, please confirm your action.',
      //prettier-ignore
      // eslint-disable-next-line max-len
      adminMessage: 'You are about to delete your account. This action cannot be undone.',
      modalHeaderAlt: 'deleteModalIcon'
    },
    logout: {
      title: 'LOG OUT',
      message: 'Are you sure you want to log out?',
      modalHeaderAlt: 'logoutModalIcon'
    },
    largeModalFooter: {
      classNames: utilFunc.joinClassNames([
        'footer-actions',
        'mr-0',
        'mr-sm-4',
        'd-flex',
        'flex-column',
        'flex-sm-row'
      ])
    },
    mediumModalFooter: {
      classNames: {
        container: utilFunc.joinClassNames([
          'footer-actions',
          'mr-0',
          'mr-sm-4',
          'd-flex',
          'flex-column',
          'flex-sm-row'
        ]),
        cancel: utilFunc.joinClassNames([
          'modalCancel',
          'mediumCancel-btn',
          'border-0',
          'rounded',
          'mr-3',
          'sf-display-bold',
          'text-white'
        ]),
        confirm: utilFunc.joinClassNames([
          'modalConfirm',
          'mediumConfirm-btn',
          'border-0',
          'rounded',
          'sf-display-bold',
          'text-white'
        ])
      }
    },
    modalIconDimensions: {
      iconHeight: 33,
      iconWidth: 37
    },
    fields: {
      firstName: {
        required: true,
        displayName: 'First Name',
        placeholder: 'Jocelyn'
      },
      lastName: {
        displayName: 'Last Name',
        placeholder: 'Schweltzer'
      },
      athleteEmail: {
        required: true,
        displayName: `Athlete's Email`,
        placeholder: 'jocelyn.schweltzer@gmail.com'
      },
      adminEmail: {
        required: true,
        displayName: `Admin's Email`,
        placeholder: 'jocelyn.schweltzer@gmail.com'
      },
      mobile: {
        required: true,
        displayName: 'Mobile',
        placeholder: '604 555-8888'
      },
      dob: {
        required: true,
        displayName: 'Date of Birth',
        placeholder: 'YYYY-MM-DD'
      },
      parentEmail: {
        required: true,
        displayName: `Email (Parent Email if under 16)`,
        placeholder: 'email@example.com'
      },
      team: {
        required: true,
        displayName: 'Team',
        placeholder: 'Choose team'
      }
    }
  }
};
