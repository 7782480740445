import React from 'react';
import XLSX from 'xlsx';

export const utilityFunctions = {
    checkDateString: dt => {
        let [day, month, year] = dt.split('/');
        return (parseInt(month) > 12) 
        || (parseInt(day) > 31) 
        || (parseInt(year) > 2020);
    },
    importExcel: (
        e,
        setIsLoadingExcel,
        setColDefs,
        importValErrors,
        setImportValErrors,
        setDeta,
        setErrorMessage,
        setShowAlert
    ) => {
        setIsLoadingExcel(true);
        const betterFileData = [];
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = e => {
            const bstr = e.target.result;
            const workBook = XLSX.read(bstr, { type: 'binary' });

            const name = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[name];

            const fileData = XLSX.utils.sheet_to_json(workSheet, {
                header: 1
            });

            const headers = fileData[0];

            const heads = headers.map(head => ({ title: head, field: head }));

            // move to utilColumns or something
            const pick = (key, values) => object => values.includes(object[key]);
            setColDefs(heads.filter(pick('title', [
                'Team',
                'Division',
                'First Name',
                'Last Name',
                'Birthdate',
                'Invite Email'])));

            fileData.splice(0, 1);
            for (let i = 0; i < fileData.length; i++) {
                const row = fileData[i];
                const newDict = {};

                for (let j = 0; j < headers.length; j++) {
                    newDict[headers[j]] = row[j];
                }

                // light validation on name, email, and birthdate
                // moveFunc to utils
                if (
                    newDict['Invite Email'] === null
                    || newDict['Invite Email'] === undefined
                    || newDict['Invite Email'] === ''
                ) {
                    setImportValErrors(importValErrors.push(
                        // eslint-disable-next-line max-len
                        `Email entry for row ${i + 2} is messed: ${newDict['Email']}`
                    ));
                } else if (
                    newDict['First Name'] === null
                    || newDict['First Name'] === undefined
                    || newDict['First Name'] === ''
                ) {
                    setImportValErrors(importValErrors.push(
                        // eslint-disable-next-line max-len
                        `First Name entry for row ${i + 2} is messed: ${newDict['First Name']}`
                    ));
                } else if (
                    newDict['Last Name'] === null
                    || newDict['Last Name'] === undefined
                    || newDict['Last Name'] === ''
                ) {
                    setImportValErrors(importValErrors.push(
                        // eslint-disable-next-line max-len
                        `Last Name entry for row ${i + 2} is messed: ${newDict['Last Name']}`
                    ));
                } else if (
                    newDict['Birthdate'] === null
                    || newDict['Birthdate'] === undefined
                    || newDict['Birthdate'] === ''
                    || utilityFunctions.checkDateString(newDict['Birthdate'])
                ) {
                    setImportValErrors(importValErrors.push(
                        // eslint-disable-next-line max-len
                        `Birthdate entry for row ${i + 2} is messed: ${newDict['Birthdate']}`
                    ));
                } else {
                    betterFileData.push(newDict);
                }
            }
            setDeta(betterFileData);
            if (importValErrors.length > 0) {
                setErrorMessage(
                    importValErrors.map((err, i) => <p key={i}>{err}</p>)
                );

                setImportValErrors([]);
                setShowAlert(true);
            }
        };


        try {
            reader.readAsBinaryString(file);
        } catch (e) {
            console.error(e);
        }
    }
};
