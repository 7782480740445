import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  athletesMonthSection: {
    classNames: {
      monthlyStats: utilFunc.joinClassNames([
        'd-flex',
        'flex-column',
        'flex-sm-row',
        'athleteSections',
        'justify-content-between'
      ]),
      section: utilFunc.joinClassNames([
        'd-flex',
        'flex-column',
        'align-items-sm-center',
        'pl-4',
        'pl-sm-0',
        'sf-text-regular'
      ])
    }
  }
};
