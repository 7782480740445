import { utilityVariables as utilAPI } from 'utility/api';
import { utilityVariables as utilStatus } from 'utility/status';
import { setTokenNotAuthentic } from 'features/API';
import store from '../store';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';

const resetErrorDuration = 2000;
const ageRestriction = 16;
let timeOutId;

export const utilityFunctions = {
  resetErrors: setErrors => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => setErrors({}), resetErrorDuration);
  },
  validateAge: value => moment().diff(value, 'years') >= ageRestriction,
  // percentage difference calculation here: https://tinyurl.com/ydxlwvug
  athletePercentageDiff: (current, prev) => {
    if (current === prev) return 0;

    const difference = current - prev;
    const average = (current + prev) / 2;
    const quotient = difference / average;
    const percentageDiff = quotient * 100;
    // adding decimal places if necessary
    return Math.round(percentageDiff * 100) / 100;
  },
  displayFullClubName: name => name.length <= 14,
  checkLoading: ([...args]) => args.some(fetch => fetch),
  formatXAxis: item => moment(item, 'MMMM').format('MMM'),
  joinClassNames: classNames => classNames.join(' '),
  percentageDifferenceText: (prev, curr, diff) =>
    `Percentage Difference between ${prev} and ${curr}: ${diff}%`,
  setHorizontalVisibility: window => window.innerWidth < 576,
  linkName: itemName => itemName.toLowerCase().replace(' ', ''),
  sideBarName: itemName => itemName.replaceAll(' ', '').toLowerCase(),
  pastDates: () => moment().format('YYYY-MM-DD'),
  // eslint-disable-next-line max-len
  withinWeek: date =>
    moment(date).diff(moment(), 'days') > -7 &&
    moment(date).diff(moment(), 'days') <= 0,
  convertTimestampToDate: timeStamp =>
    moment.unix(timeStamp).format('YYYY-MM-DD'),
  handleDateChange: (e, name, setFieldValue, setParentDisabled=undefined) => {
    setFieldValue(name, e.format('YYYY-MM-DD'));
    //parent email is dependent on date of birth
    //e.target.value is the current value set in the field to validate the age
    setParentDisabled ?
    utilityFunctions.validateAge(e)
      ? setParentDisabled(true)
      : setParentDisabled(false)
    : console.log('no setParentDisabled');
  },
  currentTimestamp: () => Date.now() / 1000, // timestamp in seconds
  decodedJWT: () => {
    try {
      return jwtDecode(localStorage.getItem('jwt'));
    } catch {
      return null;
    }
  },
  checkTokenAuthenticity: status => {
    if (
      status === utilStatus.status.FORBIDDEN &&
      store.getState().authentication.isSignIn
    ) {
      store.dispatch(setTokenNotAuthentic());
    }
  },
  hamburgerClickHandler: (
    hamburgerClick,
    isHamburgerClicked,
    setResponsiveClick,
    isResponsiveClicked,
    width
  ) => {
    if (width > 979) {
      hamburgerClick(!isHamburgerClicked);
    } else {
      setResponsiveClick(!isResponsiveClicked);
    }
  },
  formatJSDate: (date, formatter) => moment(date).format(formatter),
  formatCurrentDate: formatter => moment().format(formatter),
  isEmailNotDuplicate: async (value, initialEmail) => {
    // if email has not been changed
    if (initialEmail === value) {
      return true;
    }

    const response = await axios.get(`${utilAPI.api.userEmail}${value}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.status === utilStatus.status.NO_CONTENT;
  },
  isOldPassCorrect: async (email, password) => {
    try {
      const response = await axios.post(
        `${utilAPI.api.verifyPassword}`,
        {
          email,
          password
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );
      return response.status === utilStatus.status.OK;
    } catch (err) {
      console.error(err);
    }
  },

  handleApiRequestWithLoader: (
    event, eventType, apiReq, setApiErr = function() {}, 
    setSpn, thenFunction = function() {}, 
    setErrorMessage = function () {}, navTo
  ) => {
    const input = event[eventType];
    setSpn(true);
    apiReq(input)
    .then(_ => {
      setSpn(false);
      thenFunction(navTo);
    })
    .catch(
      error => {
        clearTimeout(timeOutId);
        setApiErr(true);
        setSpn(false);
        timeOutId = setTimeout(() => {
          setApiErr(false);
          setErrorMessage('');
        }, resetErrorDuration);

        if (error.response) {
          // Request made and API responded
          const status = error.response.status;
          if (status === 404) {
            // Request made but API rejected request
            setErrorMessage('Not a valid username. Please try again');
          } else if (status === 403) {
            // Request made but is forbidden
            setErrorMessage('Request forbidden');
          } else if (status === 401) {
            // Request made but lacking valid authentication
            setErrorMessage('Need valid login credentials.');
          } else if (status === 500) {
            // Request made but uh-oh?
            setErrorMessage('Oops! Something went wrong.');
          }
        }
      }
    ); 
    
  },
    
  teamToID: (team, teamData) => {
    for (let i = 0; i < teamData.length; i++) {
      if (teamData[i].type === team) {
        return teamData[i].id.toString();
      }
    }
    return '-1';
  },
    
  // date format dd/mm/yyyy to iso
  adjustDateToIso: value => {
    let [day, month, year] = value.split('/');
    if (parseInt(month) < 10 && month[0] !== '0') {
      month = '0' + month;
    }
    if (parseInt(day) < 10 && day[0] !== '0') {
      day = '0' + day;
    }
    let dt1 = `${year}-${month}-${day}T00:00:00.000Z`;
    return dt1;
  },

  // date format yyyy-mm-dd to iso
  adjustDateToIso2: value => {
    let [year, month, day] = value.split('-');
    if (parseInt(month) < 10 && month[0] !== '0') {
      month = '0' + month;
    }
    if (parseInt(day) < 10 && day[0] !== '0') {
      day = '0' + day;
    }
    let dt1 = `${year}-${month}-${day}T00:00:00.000Z`;
    return dt1;
  },
    
  picked: (item, teamData, firstNameAccessor, teamIdAccessor, birthdateAccessor, emailAccessor) => {
    return {
      'firstName': item[firstNameAccessor],
      'teamId': utilityFunctions.teamToID(item[teamIdAccessor], teamData),
      'birthdate': utilityFunctions.adjustDateToIso(
        item[birthdateAccessor]
      ),
      'parentEmail': item[emailAccessor]
    };
  }
};

export const utilityVariables = {
  appTitle: 'ProInstinct',
  loading: 'Loading',
  promptDelay: 200,
  emailDuplicated: 'Duplicate email',
  emailDuplicatedError: 'Email address is already in use'
};
