import React from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import pt from 'prop-types';

const MediumModalBody = ({ content }) => {
  return (
    <ModalBody className='sf-display-regular pt-0 pb-3'>
      <b-1>{content}</b-1>
    </ModalBody>
  );
};

MediumModalBody.propTypes = {
  content: pt.string
};

MediumModalBody.defaultProps = {
  content: ''
};

export default MediumModalBody;
