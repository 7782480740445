import React from 'react';
import { utilityVariables as utilVar } from 'utility/messages';

const ComingSoon = () => {
  return (
    <div
      className='alert alert-warning text-center text-secondary'
      role='alert'
    >
      {utilVar.warnings.comingSoon}
    </div>
  );
};

export default ComingSoon;
