import React, { useEffect } from 'react';
import pt from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { getShowDeleteAthlete } from 'features/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { utilityVariables as utilModal } from 'utility/modal';
import { utilityVariables as utilMessages } from 'utility/messages';
import { utilityVariables as utilPermissions } from 'utility/permissions';
import { Spinner } from 'react-bootstrap';
import { getAthleteIndex } from 'features/Modal';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import deleteModalIcon from 'assets/info@2x.png';
import MediumModalHeader from '../MediumModalHeader';
import MediumModalBody from '../MediumModalBody';
import MediumModalFooter from '../MediumModalFooter';
import { promptConstants } from 'features/Prompt/PromptConstants';
import { triggerPrompt } from 'features/Prompt/PromptUtility';
import {
  fetchClubAthletes,
  deleteClubUser,
  resetAthleteResult,
  getDeletingAthlete,
  getDidDeleteFail,
  getDidDeletePass
} from 'features/Athletes';
import { signOut } from 'features/Authentication';
import { resetLogout } from 'features/Modal';
import { useHistory } from 'react-router';

const DeleteUser = ({ toggleDeleteModal, permission, body, title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showDeleteAthlete = useSelector(getShowDeleteAthlete);
  const deleteAthleteIndex = useSelector(getAthleteIndex);
  const deletingAthlete = useSelector(getDeletingAthlete);
  const deletingAthleteFailed = useSelector(getDidDeleteFail);
  const deletingAthletePassed = useSelector(getDidDeletePass);

  //TODO: for both edit and delete modals, fix prompt messages in next PR
  // This PR is getting a bit too big already

  const handleSubmit = () => {
    dispatch(deleteClubUser(deleteAthleteIndex));
  };

  useEffect(() => {
    if (deletingAthleteFailed) {
      dispatch(resetAthleteResult());
      triggerPrompt(
        utilMessages.errors.athleteDeleteFailed,
        promptConstants.danger,
        dispatch
      );
      toggleDeleteModal();
    } else if (deletingAthletePassed) {
      // logout and destroy all evidence if not USER
      if (permission !== utilPermissions.permissionMapping.api.USER) {
        dispatch(resetLogout());
        dispatch(signOut());
        history.push('/');
      } else {
        dispatch(resetAthleteResult());
        triggerPrompt(
          utilMessages.success.athleteDeleted,
          promptConstants.success,
          dispatch
        );
        dispatch(fetchClubAthletes({ toFetch: true, permission }));
        toggleDeleteModal();
      }
    }
  }, [
    deletingAthleteFailed,
    deletingAthletePassed,
    dispatch,
    history,
    permission,
    toggleDeleteModal
  ]);

  return (
    <Modal show={showDeleteAthlete} onHide={toggleDeleteModal} size='md'>
      <MediumModalHeader
        icon={deleteModalIcon}
        alt={utilModal.modal.deleteUser.modalHeaderAlt}
        title={title}
      />
      <MediumModalBody content={body} />
      {deletingAthlete ? (
        <div className='d-flex justify-content-center mb-3'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : null}
      <MediumModalFooter
        onCancel={toggleDeleteModal}
        onSubmit={handleSubmit}
        cancelText={BTN_LOCALIZATIONS['en'].CANCEL}
        confirmText={BTN_LOCALIZATIONS['en'].DELETE}
      />
    </Modal>
  );
};

DeleteUser.propTypes = {
  toggleDeleteModal: pt.func.isRequired,
  permission: pt.string.isRequired,
  body: pt.string,
  title: pt.string
};

DeleteUser.defaultProps = {
  body: utilModal.modal.deleteUser.message,
  title: utilModal.modal.deleteUser.title
};

export default DeleteUser;
