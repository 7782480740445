import React from 'react';
import { utilityVariables as utilVar } from 'utility/login';
import { Link } from 'react-router-dom';

const LoginFooter = () => {
  return (
    <div className={utilVar.login.classNames.loginLinks}>
      <b-1 class='mb-3'>
        <Link
          to={utilVar.login.footer.forgotPasswordRedirect}
          className='loginFlowCancelLink'
        >
          {utilVar.login.footer.forgotPassword}
        </Link>
      </b-1>
      <b-1 class='text-white'>
        {utilVar.login.footer.signUpText}
        <Link
          to={utilVar.login.footer.signUpRedirect}
          className='loginFlowCancelLink'
        >
          {utilVar.login.footer.signUpLinkText}
        </Link>
      </b-1>
    </div>
  );
};

export default LoginFooter;
