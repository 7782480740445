import React from 'react';
import pt from 'prop-types';

const AthletesTableRow = ({ place, athlete, status, lastDate, hours }) => {
  return (
    <>
      <td className='text-left'>
        <b-1>{place}</b-1>
      </td>
      <td>
        <b-1>{athlete}</b-1>
      </td>
      <td>
        <b-1>{status}</b-1>
      </td>
      <td>
        <b-1>{lastDate}</b-1>
      </td>
      <td className='text-right'>
        <b-1>{hours}</b-1>
      </td>
    </>
  );
};

AthletesTableRow.propTypes = {
  place: pt.string,
  athlete: pt.string,
  status: pt.string,
  lastDate: pt.string,
  hours: pt.string
};

AthletesTableRow.defaultProps = {
  place: 'N/A',
  athlete: 'N/A',
  status: 'N/A',
  lastDate: 'N/A',
  hours: '0:00'
};

export default AthletesTableRow;
