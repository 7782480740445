import 'utility/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { detect } from 'detect-browser';
import { utilityVariables as utilVar } from 'utility/messages';
import store from './store';

// Sentry 
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// More Sentry
import { createBrowserHistory } from 'history';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorPage from 'components/404';

const history = createBrowserHistory();
const browser = detect();

Sentry.init({
  // Specifies where to send events - specific to a project 
  dsn: process.env.REACT_APP_SENTRY_DSN,
  maxBreadcrumbs: 50,
  debug: false, // should turn off during production
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost', 
        process.env.REACT_APP_URL, 
        process.env.REACT_APP_API_URL, /^\//
      ],
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Release is the commit version being deployed - syncs
  // with deploy integrations or source maps.
  // Currently using Sentry/Netlify Build Plugin to
  // automate release management, which automatically
  // sets release information.
  release: process.env.REACT_APP_SENTRY_RELEASE
  // environment: 'staging',
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        {browser.name === 'ie' ? alert(utilVar.errors.browserIE) : null}
        {/* Error boundary wrapping the root of the app 
        to redirect to a fallback error page although
        the error page is not configured to show specific 
        error messages yet. Always 404 error */}
        <Sentry.ErrorBoundary fallback={<ErrorPage/>}>
          <App />
        </Sentry.ErrorBoundary>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
