import React from 'react';
import { BTN_LOCALIZATIONS } from 'utility/buttons';

const LoginSubmission = () => {
  return (
    <button className='mt-3 loginFormSubmit sf-display-bold' type='submit'>
      <bt-2>{BTN_LOCALIZATIONS['en'].LOGIN}</bt-2>
    </button>
  );
};

export default LoginSubmission;
