import React from 'react';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import Checkmark_circle from 'assets/Checkmark_circle.png';
import { Row } from 'react-bootstrap';

const ApprovalRedirect = () => {
  return (
    <>
      <div className={'h-100 d-flex align-items-center justify-content-center'}>
        <div className='loginFlowBG'/>
        <div className='d-flex flex-column align-items-center mx-3'>
          <LogoTitle />
          <Row style={{
            marginBottom: '2rem'
          }}>
            <h2><img
              src={Checkmark_circle}
              width={30}
              height={30}
              alt={'checkmarkShield'}
            /> You have approved a player!</h2> 
          </Row>
          <h3>Please login using the Mobile App.</h3>
        </div>
      </div>
    </>
  );
};

export default ApprovalRedirect;
