import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { utilityVariables as utilMessages } from 'utility/messages';
import { utilityVariables as utilPermissions } from 'utility/permissions';
import { utilityVariables as utilLogin } from 'utility/login';
import { useHistory } from 'react-router-dom';
import { promptConstants } from 'features/Prompt/PromptConstants';
import { triggerPrompt } from 'features/Prompt/PromptUtility';

import {
  getDidAuthFail,
  getDidAuthPass,
  resetAuthResult,
  resetRefreshResult,
  resetState,
  getDidRefreshFail,
  getDidLogoutPass,
  getPermission
} from 'features/Authentication';

import {
  getTeams
} from 'features/TeamIds';

import {
  getInviteFailed,
  resetInviteFilled,
  getInviteMessage,
  getConfirmInviteFailed,
  getConfirmInvitePassed
} from 'features/Invite';

import LoginForm from './LoginForm';
import LoginFooter from './LoginFooter';
import LoginAlert from './LoginAlert';
import LogoTitle from '../LoginFlow/LogoTitle';

import { getTokenAuthentic, resetTokenAuthentic } from 'features/API';

import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const didAuthFail = useSelector(getDidAuthFail);
  const didAuthPass = useSelector(getDidAuthPass);
  const didRefreshFail = useSelector(getDidRefreshFail);
  const didLogoutPass = useSelector(getDidLogoutPass);
  const displayInviteFailed = useSelector(getInviteFailed);
  const inviteMessage = useSelector(getInviteMessage);
  const confirmInviteFailed = useSelector(getConfirmInviteFailed);
  const confirmInvitePassed = useSelector(getConfirmInvitePassed);
  const permission = useSelector(getPermission);
  const tokenAuthentic = useSelector(getTokenAuthentic);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (didAuthFail) {
      dispatch(resetAuthResult());
      triggerPrompt(
        utilMessages.errors.loginFailed,
        promptConstants.danger,
        dispatch
      );
    } else if (didAuthPass) {
      if (
        utilPermissions.permissionMapping[permission] !==
        utilPermissions.permissionMapping.USER
      ) {
        dispatch(getTeams());
        history.push('/dashboard');
      } else {
        dispatch(resetState());
        triggerPrompt(
          utilMessages.errors.loginNotAllowed,
          promptConstants.danger,
          dispatch
        );
      }
    }
  }, [didAuthFail, didAuthPass, dispatch, history, permission]);

  // this is used because I want the prompt to have a transition
  useEffect(() => {
    // checking if token was authentic
    if (didLogoutPass) {
      dispatch(resetTokenAuthentic());
      dispatch(resetState());
      if (!tokenAuthentic) {
        triggerPrompt(
          utilMessages.errors.loginAgain,
          promptConstants.danger,
          dispatch
        );
      } else {
        triggerPrompt(
          utilMessages.success.logoutSuccess,
          promptConstants.success,
          dispatch
        );
      }
    }
  }, [didLogoutPass, dispatch, tokenAuthentic]);

  useEffect(() => {
    async function postRefresh() {
      if (didRefreshFail) {
        // this is used because I want the prompt to have a transition
        await new Promise(resolve =>
          setTimeout(resolve, promptConstants.delay)
        );
        dispatch(resetRefreshResult());
        triggerPrompt(
          utilMessages.errors.refreshFailed,
          promptConstants.danger,
          dispatch
        );
      }
    }

    postRefresh();
  }, [didRefreshFail, dispatch]);

  useEffect(() => {
    if (displayInviteFailed) {
      dispatch(resetInviteFilled());
      triggerPrompt(
        inviteMessage || utilMessages.errors.inviteFailed,
        promptConstants.danger,
        dispatch
      );
    }
  }, [dispatch, displayInviteFailed, inviteMessage]);

  useEffect(() => {
    if (confirmInviteFailed) {
      dispatch(resetInviteFilled());
      triggerPrompt(inviteMessage, promptConstants.danger, dispatch);
    } else if (confirmInvitePassed) {
      dispatch(resetInviteFilled());
      triggerPrompt(inviteMessage, promptConstants.success, dispatch);
    }
  }, [confirmInviteFailed, confirmInvitePassed, dispatch, inviteMessage]);

  return (
    <>
      <div className='loginFlowBG' />
      <LoginAlert />
      <div className={utilLogin.login.classNames.formikContainer}>
        <LogoTitle />
        <LoginForm />
        <LoginFooter />
      </div>
    </>
  );
};

export default Login;
