import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideBarItem from '../SideBarItem';
import athleteIcon from 'assets/Athletes.png';
import dashboardIcon from 'assets/Dashboard.png';
import settingsIcon from 'assets/Settings.png';
import supportIcon from 'assets/Support.png';
import logOutIcon from 'assets/Log out.png';
import './SideBarNavigation.scss';

const SideBarNavigation = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  return (
    <ul className='d-flex flex-column align-items-center w-100 p-0 mb-auto'>
      <SideBarItem
        itemName='Dashboard'
        icon={dashboardIcon}
        selected={selected.includes('dashboard')}
        setSelected={setSelected}
      />
      <SideBarItem
        itemName='Athletes'
        icon={athleteIcon}
        selected={selected.includes('athletes')}
        setSelected={setSelected}
      />
      {/* <SideBarItem itemName='Exercises' 
      icon='fas fa-external-link-square-alt' 
      selected={selected.includes('exercises')} setSelected={setSelected}/> */}
      <SideBarItem
        itemName='Settings'
        icon={settingsIcon}
        selected={selected.includes('settings')}
        setSelected={setSelected}
      />
      <SideBarItem
        itemName='Support'
        icon={supportIcon}
        selected={selected.includes('support')}
        setSelected={setSelected}
      />
      <SideBarItem
        itemName='Log Out'
        icon={logOutIcon}
        selected={selected.includes('logout')}
        setSelected={setSelected}
      />
    </ul>
  );
};

export default SideBarNavigation;
