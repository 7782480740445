import * as Sentry from '@sentry/react';

// capture and trace API errors
export const apiSentryTrace = (fnName, err) => {
    Sentry.withScope(function(scope) {
        scope.clear();
        // group errors together based on their request and response
        scope.setFingerprint([fnName, String(err.statusCode)]);
        Sentry.setExtra('error', err);
        Sentry.captureException(err, {
          tags: {
            section: 'API'
          }
        });
        Sentry.setTag('function', fnName);
    });
};
