import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  toggleSwitch: {
    on: {
      classNames: utilFunc.joinClassNames([
        'align-self-center',
        'sf-display-bold',
        'text-white',
        'ml-2',
        'position-absolute'
      ])
    },
    off: {
      classNames: utilFunc.joinClassNames([
        'text-off',
        'align-self-center',
        'sf-display-bold',
        'text-white',
        'position-absolute'
      ])
    }
  }
};
