import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { getClubUsers as selectClubUsers } from '.';

const selectConvertedClubUsers = createSelector(
  [selectClubUsers],
  clubUsers => {
    if (!clubUsers?.users) return [];

    return clubUsers.users.map(user => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      athleteEmail: user.email,
      mobile: user.phoneNumber || '',
      parentEmail: user.parentEmail || '',
      dob: moment(user.dateOfBirth).utc().format('YYYY-MM-DD'),
      team: user.team
    }));
  }
);

export { selectConvertedClubUsers };
