import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import { utilityVariables as utilVar } from 'utility/messages';

const ComingSoonTooltip = props => {
  return (
    <Tooltip id='coming-soon-tooltip' {...props}>
      {utilVar.warnings.comingSoon}
    </Tooltip>
  );
};

export default ComingSoonTooltip;
