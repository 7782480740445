import React, { useState } from 'react';
import LoginFlowInstructions from 'components/LoginFlow/LoginFlowInstructions';
import LoginFlowCancel from 'components/LoginFlow/LoginFlowCancel';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import { utilityVariables as utilLoginFlow } from 'utility/login/loginFlow';
import 'components/LoginFlow/LoginFlow.scss';
import ResetPasswordFormUtil from 'components/LoginFlow/ResetPasswordFormUtil';

const VerifyEmail = () => {
  //just for now
  const [spin, setSpin] = useState(false);

  return (
    <>
      <div className='loginFlowBG' />
      <div className={utilLoginFlow.loginFlow.classNames}>
        <div className='loginFlowContainer'>
          <LoginFlowInstructions
            title={utilLoginFlow.loginFlow.forgotPassword.header}
            body={utilLoginFlow.loginFlow.forgotPassword.body}
          />
          <ResetPasswordFormUtil
            initialValues={utilLoginFlow.loginFlow.forgotPassword.initialValues}
            // schema={utilLoginFlow.loginFlow.forgotPassword.validation}
            spin={spin}
            setSpin={setSpin}
            loginFlowFields={utilLoginFlow.loginFlow.forgotPassword.fields}
            submissionLabel={BTN_LOCALIZATIONS['en'].CONTINUE}
            navigateTo={utilLoginFlow.verifyEmail.navigateTo}
          />
          <LoginFlowCancel
            text={utilLoginFlow.loginFlow.forgotPassword.cancel}
            linkRef={utilLoginFlow.verifyEmail.cancelTo}
            linkLabel={utilLoginFlow.loginFlow.forgotPassword.cancelLink}
          />
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
