export const utilityVariables = {
  errors: {
    // prettier-ignore
    // eslint-disable-next-line max-len
    browserIE: 'ProInstinct does not fully support IE, please use a modern browser like Edge, Google Chrome, Firefox, etc...',
    loginFailed: 'Login failed',
    loginAgain: 'Authorization failed, please login again',
    // prettier-ignore
    // eslint-disable-next-line max-len
    loginNotAllowed: 'You do not have the correct permissions to access this page',
    refreshFailed: 'Failed to refresh, please login again',
    fetchClubFailed: 'Failed to fetch fetch club',
    fetchMonthlyStatsFailed: 'Failed to fetch monthly stats',
    fetchTopModulesFailed: 'Failed to fetch top modules',
    athleteUpdateFailed: 'Failed to update athlete!',
    athleteDeleteFailed: 'Failed to delete athlete!',
    athleteAddFailed: 'Failed to invite athlete!',
    clubUpdateFailed: 'Failed to update club info!',
    inviteSendFailed: 'Failed to send invite!',
    inviteFailed: 'Failed to access invite page',
    inviteExpired: 'Invitation has expired',
    inviteAlreadyUsed: 'Athlete is already onboarded!',
    onboardFailed: 'Failed to onboard athlete',
    requestPasswordResetFailed: 'Failed to request a password reset. ' + 
                                'Is your email correct?',
    passwordsNotMatch: 'Passwords do not match',
    oldPasswordTitle: 'Incorrect password',
    oldPasswordIncorrect: 'Old password is not correct',
    fetchClubInfoFailed: 'Failed to fetch Club Info',
    errorFetchAthletes: 'Error retrieving athletes'
  },
  warnings: {
    comingSoon: 'This feature is coming soon!',
    contactAdmin:
      'Contact an admin to assign an account to have the CLUB_ADMIN permission',
    // prettier-ignore
    // eslint-disable-next-line max-len
    noAthletesFound: 'No athletes added to the club, click on ADD to get started!'
  },
  success: {
    loginSuccess: 'Login success',
    logoutSuccess: 'Logged out successfully',
    athleteUpdated: 'Athlete updated successfully!',
    athleteDeleted: 'Athlete deleted successfully!',
    athleteAdded: 'Athlete successfully invited!',
    adminDeleted: 'Admin account has been deleted!',
    onboardSuccess: 'Welcome aboard!',
    settingsUpdated: 'Settings updated successfully!'
  }
};
