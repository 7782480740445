import React, { useState } from 'react';
import supportImg from 'assets/support_img.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Alert } from 'react-bootstrap';
import { utilityVariables as utilVar } from 'utility/support';
import './Support.scss';

const Support = () => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      <div className='d-flex justify-content-center'>
        <Alert className='text-center w-50' show={copied} variant='success'>
          {utilVar.support.clipboardSuccess}
        </Alert>
      </div>
      <div className='d-flex justify-content-center align-items-center w-100'>
        <div className='supportPage rounded text-center px-5 pt-4'>
          <div className='mb-5'>
            <h2 className='sf-display-bold'>{utilVar.support.title}</h2>
            <h4 className='sf-text-medium mb-0'>{utilVar.support.body}</h4>
            <CopyToClipboard
              text={utilVar.support.email}
              onCopy={() => {
                setCopied(true);
                setTimeout(
                  () => setCopied(false),
                  utilVar.support.clipboardTimeout
                );
              }}
            >
              <h4 className='clipboardLink'>{utilVar.support.clipboard}</h4>
            </CopyToClipboard>
          </div>
          <img
            src={supportImg}
            alt={utilVar.support.logo.alt}
            width={utilVar.support.logo.width}
            height={utilVar.support.logo.height}
          />
        </div>
      </div>
    </>
  );
};

export default Support;
