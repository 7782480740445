import React, { forwardRef } from 'react';
import ClubHeading from './ClubHeading';
import SideBarNavigation from './SideBarNavigation';
import Credits from './Credits';
import pt from 'prop-types';
import './Sidebar.scss';

const Sidebar = forwardRef(({ sideBarVisible }, ref) => {
  return (
    <nav
      className={`sidebar d-flex flex-column pl-0 pr-0 vh-100 position-fixed ${
        sideBarVisible ? '' : 'hamburgerForeground'
      }`}
      ref={ref}
    >
      <ClubHeading />
      <SideBarNavigation />
      <Credits />
    </nav>
  );
});

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
  sideBarVisible: pt.bool
};

Sidebar.defaultProps = {
  sideBarVisible: true
};

export default Sidebar;
