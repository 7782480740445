import * as Yup from 'yup';
import { utilityVariables as utilMessages } from 'utility/messages';
const requiredError = 'Field is required';

export const utilityVariables = {
  confirmInvite: {
    title: 'Confirm Invitation',
    instructions: 'Enter a password for your account and you will be set!',
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateInvite: Yup.object().shape({
      password: Yup.string().required(requiredError),
      confirmPassword: Yup.string()
        .equals([Yup.ref('password')], utilMessages.errors.passwordsNotMatch)
        .required(requiredError)
    })
  }
};
