import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  addEntry: {
    classNames: {
      addContainer: utilFunc.joinClassNames([
        'addEntryContainer',
        'd-flex',
        'align-items-center',
        'justify-content-end',
        'bg-white'
      ]),
      addButton: utilFunc.joinClassNames([
        'addEntryButton',
        'actionTableAdd-sf-lg',
        'd-flex',
        'align-items-center',
        'justify-content-center'
      ])
    },
    addImgAlt: 'addIcon',
    dimensions: 19
  }
};
