import React from 'react';
import pt from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import './ModalField.scss';

const ModalField = ({ required, displayName, error, children }) => {
  return (
    <div className='d-flex flex-column w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        <h3 className='sf-display-bold modalFieldHeader'>{displayName}</h3>
        {required ? <span className='requiredField rounded-circle' /> : null}
      </div>
      {error ? <b-1 class='sf-text-bold text-danger'>{error}</b-1> : null}
      {children}
    </div>
  );
};

ModalField.propTypes = {
  required: pt.bool,
  displayName: pt.string,
  error: pt.string,
  children: pt.node
};

ModalField.defaultProps = {
  required: false,
  displayName: null,
  error: null,
  children: null
};

export default ModalField;
