import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  fillInviteForm,
  getFormSubmitted,
  fetchInvitationData,
  getInviteFormFetching,
  getInviteData,
  getInviteFailed,
  getUUID
} from 'features/Invite';
import LoginFlowInstructions from 'components/LoginFlow/LoginFlowInstructions';
import { utilityVariables as utilVar } from 'utility/invite';
import { utilityVariables as utilModal } from 'utility/modal';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import LoginFlowField from 'components/LoginFlow/LoginFlowField';
import LoginFlowSubmission from 'components/LoginFlow/LoginFlowSubmission';
import 'components/LoginFlow/LoginFlow.scss';

// I want it to look similar to the Reset Password/Forgot Password page
// Need formik for the form
const InviteAthlete = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [spin, setSpin] = useState(false);
  const inviteFormSubmitted = useSelector(getFormSubmitted);
  const inviteFormFetching = useSelector(getInviteFormFetching);
  const inviteData = useSelector(getInviteData);
  const displayInviteFailed = useSelector(getInviteFailed);
  const invitationUUID = useSelector(getUUID);

  useEffect(() => {
    if (inviteFormSubmitted) {
      history.push('/confirmInvite');
    }
  }, [history, inviteFormSubmitted]);

  useEffect(() => {
    if (!invitationUUID) dispatch(fetchInvitationData(uuid));
  }, [dispatch, invitationUUID, uuid]);

  useEffect(() => {
    if (displayInviteFailed) {
      history.push('/');
    }
  }, [displayInviteFailed, history]);

  return (
    <>
      <div className='loginFlowBG' />
      {inviteFormFetching ? (
        <div className='d-flex justify-content-center align-items-center h-100'>
          <Spinner animation='border' variant='primary' />
        </div>
      ) : (
        <div className='w-100 d-flex flex-column align-items-center pt-5'>
          <div className='loginFlowContainer'>
            <LoginFlowInstructions
              title={utilVar.invite.title}
              body={utilVar.invite.instructions}
            />
          </div>
          <Formik
            initialValues={{ ...utilVar.invite.initialValues, ...inviteData }}
            validationSchema={utilVar.invite.validateInvite}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              const userPayload = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.athleteEmail,
                mobile: values.mobile
              };
              dispatch(fillInviteForm(userPayload));
            }}
          >
            {({ errors, setErrors, setFieldValue, values }) => {
              const addUserFields = [
                {
                  label: 'First Name',
                  name: 'firstName',
                  field: 'input',
                  props: { type: 'text' }
                },
                {
                  label: 'Last Name',
                  name: 'lastName',
                  field: 'input',
                  props: { type: 'text' }
                },
                {
                  //prettier-ignore
                  label: 'Athlete\'s Email',
                  name: 'athleteEmail',
                  field: 'input',
                  props: { type: 'email' }
                },
                {
                  label: 'Mobile',
                  name: 'mobile',
                  field: 'phone',
                  props: { setFieldValue: setFieldValue, mobile: values.mobile }
                }
              ];

              return (
                <Form>
                  {addUserFields.map((userField, idx) => (
                    <div key={`${userField.label}${idx}`} className='mb-2'>
                      <LoginFlowField
                        label={userField.label}
                        type={userField.field}
                        fieldName={userField.name}
                        fieldProps={userField.props}
                        error={errors[userField.name]}
                        placeholder={
                          utilModal.modal.fields[userField.name].placeholder
                        }
                      />
                    </div>
                  ))}
                  <LoginFlowSubmission
                    spin={spin}
                    setSpin={setSpin}
                    setErrors={setErrors}
                    submissionText={BTN_LOCALIZATIONS.en.CONTINUE}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

InviteAthlete.propTypes = {};

export default InviteAthlete;
