import axios from 'axios';
import { apiSentryTrace } from 'features/Sentry';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilAPI } from 'utility/api';
import { utilityVariables as utilMessages } from 'utility/messages';
import { utilityVariables as utilStatus } from 'utility/status';

const proInstinctAPI = {
  async authenticate(username, password) {
    try {
      const response = await axios.post(utilAPI.api.authenticateUser, {
        username,
        password
      });
      const { authorization, refresh } = response.headers;
      return { authorization, refresh };
    } catch (err) {
      apiSentryTrace('authenticate', err);
    }
  },

  async refresh(username, refresh) {
    try {
      const response = await axios.post(utilAPI.api.refreshUser, {
        username: username,
        refreshToken: refresh
      });

      const { refreshToken, jwt, permission } = response.data;
      return { refreshToken, jwt, permission };
    } catch (err) {
      apiSentryTrace('refresh', err);
      console.error(err);
    }
  },

  async fetchClub(toFetch, permission, clubID=0) {
    const params = {
      fetch: toFetch ? true : false,
      permission: permission
    };
    const userPermission = localStorage.getItem('permission');
    if (userPermission === 'SITE_ADMIN') {
      // if (clubID === 0) {
      try {
        const clubUsersURL = utilAPI.api.allClubUsers;
        const response = await axios.get(clubUsersURL, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        });
  
        const { data: club } = response;
        return { club };
      } catch (err) {
        apiSentryTrace('fetchAllClub', err);
        utilFunc.checkTokenAuthenticity(err.response.status);
        console.error(err);
      }
      // DROPDOWN Options based on clubID for SITE_ADMIN
      // } 
      // else {
      //   try {
      //     const clubUsersURL = utilAPI.api.clubUsers(clubID);
      //     const response = await axios.get(clubUsersURL, {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem('jwt')}`
      //       },
      //       params: params
      //     });
      //     const { users: club } = response.data;
      //     return { club };
      //   } catch (err) {
      //     apiSentryTrace('fetchClub', err);
      //     utilFunc.checkTokenAuthenticity(err.response.status);
      //     console.error(err);
      //   }
      // }
    } else {
      try {
        const clubUsersURL = utilAPI.api.clubUsers(
          localStorage.getItem('clubId')
        );
        const response = await axios.get(clubUsersURL, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          },
          params: params
        });
        const { users: club } = response.data;
        return { club };
      } catch (err) {
        apiSentryTrace('fetchClub', err);
        utilFunc.checkTokenAuthenticity(err.response.status);
        console.error(err);
      }
    }
  },

  async fetchClubTeams() {
    try {
      const clubTeamsURL = utilAPI.api.clubTeams(
        localStorage.getItem('clubId')
      );
      const response = await axios.get(clubTeamsURL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      });

      const { data: teams } = response;
      return { teams };
    } catch (err) {
      apiSentryTrace('fetchClubTeams', err);
      utilFunc.checkTokenAuthenticity(err.response.status);
      console.error(err);
    }
  },

  async addClubUser(userPayload, rejectWithValue) {
    try {
      const {
        firstName,
        lastName,
        athleteEmail: email,
        parentEmail,
        mobile: phoneNumber,
        dob: dateOfBirth,
        team
      } = userPayload;

      const response = await axios.post(
        utilAPI.api.athletesEndpoint,
        {
          firstName,
          lastName,
          email,
          parentEmail,
          phoneNumber,
          dateOfBirth,
          team
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      const { data: addedAthlete } = response;
      return { addedAthlete };
    } catch (err) {
      apiSentryTrace('addClubUser', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.athleteUpdateFailed);
    }
  },

  async sendUserInvite(id, rejectWithValue) {
    try {
      await axios.post(
        utilAPI.api.invite,
        {
          userId: id
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );
    } catch (err) {
      apiSentryTrace('sendUserInvite', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.inviteSendFailed);
    }
  },

  async updateClubUser(userPayload, rejectWithValue) {
    try {
      const {
        id: athleteId,
        firstName,
        lastName,
        email,
        parentEmail,
        mobile: phoneNumber,
        dob: dateOfBirth,
        team,
        newPassword,
        oldPassword,
        onboardingComplete
      } = userPayload;

      await axios.put(
        `${utilAPI.api.athletesEndpoint}${athleteId}`,
        {
          firstName,
          lastName,
          email,
          parentEmail,
          phoneNumber,
          dateOfBirth,
          team,
          newPassword,
          oldPassword,
          onboardingComplete
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );
    } catch (err) {
      apiSentryTrace('updateClubUser', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.athleteUpdateFailed);
    }
  },

  async deleteClubUser(userId, rejectWithValue) {
    try {
      await axios.delete(`${utilAPI.api.athletesEndpoint}${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      });
    } catch (err) {
      apiSentryTrace('deleteClubUser', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.athleteDeleteFailed);
    }
  },

  async fetchClubInfo(toFetch, rejectWithValue) {
    try {
      const response = await axios.get(
        `${utilAPI.api.clubsEndpoint}${localStorage.getItem('clubId')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          },
          params: {
            fetch: toFetch ? true : false
          }
        }
      );

      const { data: clubInfo } = response;
      return { clubInfo };
    } catch (err) {
      apiSentryTrace('fetchClubInfo', err);
      utilFunc.checkTokenAuthenticity(err.response.status);
      console.error(err);
      return rejectWithValue(utilMessages.errors.fetchClubInfoFailed);
    }
  },

  async updateClubInfo(userPayload, rejectWithValue) {
    try {
      await axios.put(
        `${utilAPI.api.clubsEndpoint}${localStorage.getItem('clubId')}`,
        userPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      return utilMessages.success.settingsUpdated;
    } catch (err) {
      apiSentryTrace('updateClubInfo', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.clubUpdateFailed);
    }
  },

  async fetchMonthlyStats(start, end) {
    try {
      const monthlyStatsURL = utilAPI.api.monthlyStats(
        localStorage.getItem('clubId')
      );
      const response = await axios.post(
        monthlyStatsURL,
        {
          dateMin: utilFunc.convertTimestampToDate(start),
          dateMax: utilFunc.convertTimestampToDate(end)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      const { totalAthletes, totalHours, avgHours, months } = response.data;
      return { totalAthletes, totalHours, avgHours, months };
    } catch (err) {
      apiSentryTrace('fetchMonthlyStats', err);
      utilFunc.checkTokenAuthenticity(err.response.status);
      console.error(err);
    }
  },

  async fetchTopModules(start, end) {
    try {
      const topModulesURL = utilAPI.api.topModules(
        localStorage.getItem('clubId')
      );
      const response = await axios.post(
        topModulesURL,
        {
          dateMin: utilFunc.convertTimestampToDate(start),
          dateMax: utilFunc.convertTimestampToDate(end)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      const { modules } = response.data;
      return { modules };
    } catch (err) {
      apiSentryTrace('fetchTopModules', err);
      utilFunc.checkTokenAuthenticity(err.response.status);
      console.error(err);
    }
  },

  async fetchActiveAthletes(start, end) {
    try {
      const activeAthletesURL = utilAPI.api.activeAthletes(
        localStorage.getItem('clubId')
      );
      const response = await axios.post(
        activeAthletesURL,
        {
          dateMin: utilFunc.convertTimestampToDate(start),
          dateMax: utilFunc.convertTimestampToDate(end)
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      const { athletes } = response.data;
      return { athletes };
    } catch (err) {
      apiSentryTrace('fetchActiveAthletes', err);
      utilFunc.checkTokenAuthenticity(err.response.status);
      console.error(err);
    }
  },

  async fetchInvitationData(uuid, rejectWithValue) {
    try {
      const response = await axios.get(`${utilAPI.api.invite}${uuid}`);
      const { data: inviteData } = response;
      inviteData.uuid = uuid;
      return inviteData;
    } catch (err) {
      apiSentryTrace('fetchInvitationData', err);
      if (err.response.status === utilStatus.status.GONE) {
        return rejectWithValue(utilMessages.errors.inviteExpired);
      } else if (err.response.status === utilStatus.status.CONFLICT) {
        return rejectWithValue(utilMessages.errors.inviteAlreadyUsed);
      }

      return rejectWithValue(utilMessages.errors.inviteFailed);
    }
  },

  async completeOnboarding(userPayload, rejectWithValue) {
    try {
      await axios.put(`${utilAPI.api.invite}${userPayload.uuid}`, userPayload);
    } catch (err) {
      apiSentryTrace('completeOnboarding', err);
      console.error(err);
      return rejectWithValue(utilMessages.errors.onboardFailed);
    }
  },

  async validateCode(code) {
    try {
      const codeSingle = utilAPI.api.codeSingle(code);
      const response = await axios.get(codeSingle, {});
      return { 
        goodRes: true, 
        isUnderage: response.data.isUnderage, 
        teamId: response.data.team.id 
      };
    } catch (err) {
      apiSentryTrace('validateCode', err);
      console.error(err);
      return { goodRes: false, isUnderage: null };
    }
  },

  async getAllTeams() {
    try {
      const url = utilAPI.api.listAllTeam;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      });

      return response.data;
    } catch (err) {
      apiSentryTrace('getAllTeams', err);
      console.error(err);
    }
  },

  async verifySubmission(code) {
    try {
      const url = utilAPI.api.getWebhookSubmission(code);
      const response = await axios.get(url, {});
      return response.data;
    } catch (err) {
      apiSentryTrace('verifySubmission', err);
      console.error(err);
      return [];
    }
  },

  // async verifySubmissionOnJotformAPI(code) {
  //   try {
  //     const url = `https://api.jotform.com/user/submissions?apiKey=${process.env.REACT_APP_JOTFORM_API_KEY}&filter={"fullText": "${code}"}`;
  //     const response = await axios.get(url, {});
  //     console.log('api response: ', response);
  //     return response.data.content;
  //   } catch (err) {
  //     console.error(err);
  //     return [];
  //   }
  // },

  async onboardInvite(inviteData, handleShow=()=>{}) {
    try {
      const url = utilAPI.api.onboard;
      const response = await axios({
        method: 'POST',
        url: url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        },
        data: inviteData
      });

      handleShow();
      return response.data;
    } catch (err) {
      apiSentryTrace('onboardInvite', err);
      console.error(err);
    }
  },

  async createUser(userData) {
    const url = utilAPI.api.athletesEndpoint;
    const response = await axios({
      method: 'POST',
      url: url,
      data: userData
    });
    return response.data;
  },

  async markEmailAsOpen(code) {
    try {
      const url = utilAPI.api.markEmailAsOpen(code);
      await axios.post(url, {});
    } catch (err) {
      apiSentryTrace('markEmailAsOpen', err);
      console.error(err);
    }
  },

  async sentOnboardingEmailsList() {
    try {
      const club = `${localStorage.getItem('clubId')}`;
      const permission = `${localStorage.getItem('permission')}`;
      if (permission !== 'SITE_ADMIN') {
        const url = utilAPI.api.sentOnboardingEmails(club);
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        });
        return response.data;
      } else {
        const url = utilAPI.api.allSentOnboardingEmails;
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        });
        return response.data;
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  async forgotPassword(username) {
    const url = utilAPI.api.requestResetPassword(username);
    const response = await axios({
      method: 'POST',
      url: url
    });
    
  }
};

export default proInstinctAPI;
