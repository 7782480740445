import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import pt from 'prop-types';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';
import {
  getMessagePrompt,
  getMessageVariant,
  getPromptID
} from 'features/Prompt';
import Hamburger from '../Hamburger';
import CloseSidebar from 'components/CloseSidebar';
import './NavBar.scss';

const NavBar = ({
  isHamburgerClicked,
  hamburgerClick,
  isResponsiveClicked,
  setResponsiveClick,
  sideBarVisible,
  width,
  targetRef
}) => {
  const hamburgerProps = {
    hamburgerClick,
    setResponsiveClick,
    isHamburgerClicked,
    isResponsiveClicked,
    width
  };
  const message = useSelector(getMessagePrompt);
  const variant = useSelector(getMessageVariant);
  const promptID = useSelector(getPromptID);

  return (
    <div className='d-flex flex-row-reverse' ref={targetRef}>
      <nav
        className={`p-0 position-fixed d-flex ${
          isHamburgerClicked && width > 979 ? 'navBar' : 'navBarSideBarVisible'
        }`}
      >
        <div className='d-flex align-items-center w-100'>
          <span className='ml-4 mr-2 mr-auto d-flex align-items-center'>
            {sideBarVisible ? (
              <CloseSidebar {...hamburgerProps} />
            ) : (
              <Hamburger {...hamburgerProps} />
            )}
          </span>
          <Alert
            className='text-center w-50 position-absolute navBarAlert'
            show={promptID !== null}
            variant={variant}
          >
            {message}
          </Alert>
          {/* <ProfileIcon /> */}
        </div>
      </nav>
    </div>
  );
};

NavBar.propTypes = {
  isHamburgerClicked: pt.bool.isRequired,
  hamburgerClick: pt.func.isRequired,
  isResponsiveClicked: pt.bool.isRequired,
  setResponsiveClick: pt.func.isRequired,
  sideBarVisible: pt.bool.isRequired,
  width: pt.number,
  targetRef: pt.object.isRequired
};

NavBar.defaultProps = {
  width: window.innerWidth
};

export default withResizeDetector(NavBar);
