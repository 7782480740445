import React from 'react';
import pt from 'prop-types';

const AdminInfoField = ({ header, value }) => {
  return (
    <div className='container px-0'>
      <div className='row'>
        <div className='col'>
          <dh-2 class='sf-display-bold'>{header}:</dh-2>
        </div>
        <div className='col'>
          <b-1 class='sf-display-regular'>{value}</b-1>
        </div>
      </div>
    </div>
  );
};

AdminInfoField.propTypes = {
  header: pt.string.isRequired,
  value: pt.string.isRequired
};

export default AdminInfoField;
