export const utilityVariables = {
  athletesTable: {
    title: 'Athletes',
    subtitle: 'Most active athletes',
    headers: [
      { text: 'Place', className: 'text-left' },
      { text: 'Athlete' },
      { text: 'Status' },
      { text: 'Last Date' },
      { text: 'Minutes', className: 'text-right' }
    ],
    transitionTimeout: 100,
    maxAthletes: 3
  }
};
