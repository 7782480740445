import React from 'react';
import { Link } from 'react-router-dom';
import pt from 'prop-types';
import './LoginFlowCancel.scss';

const LoginFlowCancel = ({ text, linkRef, linkLabel }) => {
  return (
    <b-1 class='sf-text-regular text-white ml-2'>
      {text}{' '}
      <Link to={linkRef} className='loginFlowCancelLink' onClick={e => {
        localStorage.clear();
      }}>
        {linkLabel}
      </Link>
    </b-1>
  );
};

LoginFlowCancel.propTypes = {
  text: pt.string.isRequired,
  linkRef: pt.string.isRequired,
  linkLabel: pt.string.isRequired
};

export default LoginFlowCancel;
