import * as Yup from 'yup';
const requiredError = 'Field is required';
const phoneNumberInvalid = `Please enter a valid phone number`;
const phoneNumberCheck = /^$|[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}/;

export const utilityVariables = {
  invite: {
    title: 'Invite Athlete',
    // prettier-ignore
    // eslint-disable-next-line max-len
    instructions: 'Edit any information that looks incorrect and then click on continue.',
    initialValues: {
      firstName: '',
      lastName: '',
      athleteEmail: '',
      mobile: ''
    },
    validateInvite: Yup.object().shape({
      firstName: Yup.string().required(requiredError),
      lastName: Yup.string().required(requiredError),
      athleteEmail: Yup.string().required(requiredError),
      mobile: Yup.string().matches(phoneNumberCheck, phoneNumberInvalid)
    })
  },
  emailList: {
    columns: [
      { Header: 'Team', accessor: 'Team/Coach', id: 'team name' },
      { Header: 'First Name', accessor: 'First Name', id: 'firstName' },
      { Header: 'Last Name', accessor: 'Last Name', id: 'lastName' },
      {
        Header: 'Birthdate',
        accessor: 'Birthdate'
      },
      { Header: 'Parent Email', accessor: 'Email' }
      // { Header: 'Actions' }
    ]
  }
};
