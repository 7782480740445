import React from 'react';
import pt from 'prop-types';
import { detect } from 'detect-browser';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar
} from 'recharts';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/dashboard/barChart';

import './BarChart.scss';

const browser = detect();

const ResponsiveBarChart = ({ data }) => {
  return (
    <ResponsiveContainer height={utilVar.barChart.containerHeight}>
      <BarChart
        data={data}
        maxBarSize={utilVar.barChart.maxBarSize}
        margin={utilVar.barChart.margin}
      >
        <XAxis
          dataKey={utilVar.barChart.xAxisDataKey}
          axisLine={false}
          tickLine={false}
          tick={utilVar.barChart.axisTick}
          tickFormatter={utilFunc.formatXAxis}
          className='sf-display-regular'
        />
        <YAxis
          domain={utilVar.barChart.yAxisDomain}
          axisLine={false}
          tickLine={false}
          padding={utilVar.barChart.yAxisPadding}
          tick={utilVar.barChart.axisTick}
          className='sf-display-regular'
        />
        <Tooltip />
        <Bar
          dataKey={utilVar.barChart.barDataKey}
          fill={utilVar.barChart.barFill}
          radius={utilVar.barChart.barRadius}
          name={utilVar.barChart.barName}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const StaticBarChart = ({ data }) => {
  return (
    <BarChart
      data={data}
      maxBarSize={utilVar.barChart.maxBarSize}
      margin={utilVar.barChart.margin}
      height={400}
      width={600}
    >
      <XAxis
        dataKey={utilVar.barChart.xAxisDataKey}
        axisLine={false}
        tickLine={false}
        tick={utilVar.barChart.axisTick}
        tickFormatter={utilFunc.formatXAxis}
        className='sf-display-regular'
      />
      <YAxis
        domain={utilVar.barChart.yAxisDomain}
        axisLine={false}
        tickLine={false}
        padding={utilVar.barChart.yAxisPadding}
        tick={utilVar.barChart.axisTick}
        className='sf-display-regular'
      />
      <Tooltip />
      <Bar
        dataKey={utilVar.barChart.barDataKey}
        fill={utilVar.barChart.barFill}
        radius={utilVar.barChart.barRadius}
        name={utilVar.barChart.barName}
      />
    </BarChart>
  );
};

const StyledBarChart = ({ header, subheader, data }) => {
  return (
    <div className='barChartContainer py-3 px-4'>
      {header && <b-1 class='sf-display-bold'>{header}</b-1>}
      <br />
      {subheader && <b-2 class='sf-display-regular'>{subheader}</b-2>}
      {browser.name === 'ie' ? (
        <StaticBarChart data={data} />
      ) : (
        <ResponsiveBarChart data={data} />
      )}
    </div>
  );
};

ResponsiveBarChart.propTypes = {
  data: pt.array.isRequired
};

StaticBarChart.propTypes = {
  data: pt.array.isRequired
};

StyledBarChart.propTypes = {
  header: pt.string,
  subheader: pt.string,
  data: pt.array.isRequired
};

StyledBarChart.defaultProps = {
  header: null,
  subheader: null
};

export default StyledBarChart;
