import ErrorPage from 'components/404';
import pt from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import AthletesPage from '../Athletes';
import Dashboard from '../Dashboard';
import LogOut from '../LogOut';
import NavBar from '../Navbar';
import Settings from '../Settings';
import Sidebar from '../Sidebar';
import Support from '../Support';

const Navigation = ({ width, targetRef }) => {
  const nodeRef = useRef(null);
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [responsiveHamburgerClick, setResponsiveHamburgerClick] = useState(
    false
  );
  const [responsiveSideBarVisible, setResponsiveSideBarVisible] = useState(
    false
  );

  useEffect(() => {
    if (width > 979) {
      setResponsiveHamburgerClick(false);
      setResponsiveSideBarVisible(false);

      setSideBarVisible(!isHamburgerClicked);
    } else if (width <= 979) {
      setIsHamburgerClicked(false);
      setSideBarVisible(false);

      setResponsiveSideBarVisible(responsiveHamburgerClick);
    }
  }, [isHamburgerClicked, responsiveHamburgerClick, width]);

  return (
    <div ref={targetRef}>
      <CSSTransition
        in={responsiveSideBarVisible}
        timeout={200}
        classNames='fade'
        unmountOnExit
        nodeRef={nodeRef}
      >
        <>
          <div
            className='h-100 position-fixed hamburgerForeground'
            ref={nodeRef}
          >
            <Sidebar />
          </div>
          <div
            className='vw-100 h-100 position-fixed hamburgerBackground'
            onClick={() => setResponsiveHamburgerClick(false)}
          />
        </>
      </CSSTransition>

      <CSSTransition
        in={sideBarVisible}
        timeout={200}
        classNames='fade'
        unmountOnExit
        nodeRef={nodeRef}
      >
        <Sidebar
          sideBarVisible={sideBarVisible}
          ref={nodeRef}
        />
      </CSSTransition>
      <NavBar
        isHamburgerClicked={isHamburgerClicked}
        hamburgerClick={setIsHamburgerClicked}
        isResponsiveClicked={responsiveHamburgerClick}
        setResponsiveClick={setResponsiveHamburgerClick}
        sideBarVisible={sideBarVisible}
      />
      <main
        className={`min-vh-100 ${
          isHamburgerClicked && width > 979
            ? 'mainContent'
            : 'mainContentSideBarVisible'
        }`}
      >
        <div className='mx-4'>
          <Switch>
            <Route path='/athletes' 
              render={() => 
                (<AthletesPage />)
              }
            />
            {/* <Route path='/exercises'>
            <Exercises />
          </Route> */}
            <Route path='/settings'

            >
              <Settings width={width} />
            </Route>
            <Route path='/support'>
              <Support />
            </Route>
            <Route path='/dashboard'>
              <Dashboard />
            </Route>
            <Route path='/logout'>
              <LogOut />
            </Route>
            {/* fallback page if we can't match anything */}
            <Route path='/'>
              <ErrorPage />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
};

Navigation.propTypes = {
  width: pt.number,
  targetRef: pt.object.isRequired
};

Navigation.defaultProps = {
  width: window.innerWidth
};

export default withResizeDetector(Navigation);
