import React from 'react';
import EditIcon from 'assets/edit_settings@3x.png';
import { utilityVariables as utilSettings } from 'utility/settings';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import pt from 'prop-types';
import './EditSetting.scss';

const EditSetting = ({ action, className }) => {
  return (
    <button
      className={className}
      onClick={action}
    >
      <bt-1 class='sf-display-bold text-white mr-2'>
        {BTN_LOCALIZATIONS['en'].EDIT}
      </bt-1>
      <img
        src={EditIcon}
        alt={utilSettings.settings.fieldEdit.editBtnIcon.alt}
        height={utilSettings.settings.fieldEdit.editBtnIcon.height}
        width={utilSettings.settings.fieldEdit.editBtnIcon.width}
      />
    </button>
  );
};

EditSetting.propTypes = {
  action: pt.func,
  className: pt.string
};

EditSetting.defaultProps = {
  action: () => {},
  className: utilSettings.settings.fieldEdit.classNames.editBtnContainer
};

export default EditSetting;
