import React from 'react';
import { utilityVariables as utilModal } from 'utility/modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import LargeModalCancel from '../LargeModalCancel';
import LargeModalConfirm from '../LargeModalConfirm';
import pt from 'prop-types';

const LargeModalFooter = ({
  setParentDisabled,
  toggleModal,
  cancelText,
  setErrors,
  confirmText
}) => {
  return (
    <ModalFooter>
      <div className={utilModal.modal.largeModalFooter.classNames}>
        <LargeModalCancel
          setParentDisabled={setParentDisabled}
          toggleModal={toggleModal}
          cancel={cancelText}
        />
        <LargeModalConfirm setErrors={setErrors} confirm={confirmText} />
      </div>
    </ModalFooter>
  );
};

LargeModalFooter.propTypes = {
  setParentDisabled: pt.func.isRequired,
  toggleModal: pt.func.isRequired,
  cancelText: pt.string,
  setErrors: pt.func.isRequired,
  confirmText: pt.string
};

LargeModalFooter.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm'
};

export default LargeModalFooter;
