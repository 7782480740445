import React from 'react';
import pt from 'prop-types';

const JotformParentalConsent = ({ uuid }) => {

    const srcUrl = `https://form.jotform.com/${process.env.REACT_APP_JOTFORM_ID}?uuid=${uuid}`;
    return (
        <iframe 
        id={`JotFormIFrame-${process.env.REACT_APP_JOTFORM_ID}`}
        title='Parent Consent Form Draft' 
        allowtransparency='true' 
        allowFullScreen={true} 
        allow='geolocation; microphone; camera' 
        src={srcUrl}
        frameBorder='2px solid black'
        className='jotform-iframe'
        />
    );
};

JotformParentalConsent.propTypes = {
    uuid: pt.string.isRequired
};

export default JotformParentalConsent;
