import React from 'react';
import pt from 'prop-types';

const LoginFlowBody = ({ body }) => {
  return <h2 className='sf-text-medium text-white mb-5'>{body}</h2>;
};

LoginFlowBody.propTypes = {
  body: pt.string.isRequired
};

export default LoginFlowBody;
