import React from 'react';
import LoginFlowHeader from 'components/LoginFlow/LoginFlowHeader';
import LoginFlowBody from 'components/LoginFlow/LoginFlowBody';
import pt from 'prop-types';

const LoginFlowInstructions = ({ title, body }) => {
  return (
    <div className='ml-2'>
      <LoginFlowHeader title={title} />
      {body ? <LoginFlowBody body={body} /> : null}
    </div>
  );
};

LoginFlowInstructions.propTypes = {
  title: pt.string.isRequired,
  body: pt.string
};

LoginFlowInstructions.defaultProps = {
  body: ''
};

export default LoginFlowInstructions;
