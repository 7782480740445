import React from 'react';
import PhoneInput from 'react-phone-input-2';
import pt from 'prop-types';
import './PhoneField.scss';

const PhoneField = ({
  mobile,
  setValue,
  disabled,
  placeholder,
  className,
  dropdownBtnClass
}) => {
  return (
    <PhoneInput
      inputClass={`${className} ${disabled ? 'no-hover' : 'bg-white'}`}
      buttonClass={`${dropdownBtnClass}`}
      country='ca'
      value={mobile}
      onChange={e => setValue('mobile', e)}
      onlyCountries={['ca', 'us']}
      disableCountryCode
      placeholder={placeholder}
    />
  );
};

PhoneField.propTypes = {
  className: pt.string,
  dropdownBtnClass: pt.string,
  mobile: pt.string,
  setValue: pt.func.isRequired,
  disabled: pt.bool,
  placeholder: pt.string
};

PhoneField.defaultProps = {
  className: '',
  dropdownBtnClass: '',
  mobile: null,
  disabled: false,
  placeholder: 'placeholder'
};

export default PhoneField;
