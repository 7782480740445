const apiURL = process.env.REACT_APP_API_URL;

export const utilityVariables = {
  api: {
    authenticateUser: `${apiURL}/login`,
    refreshUser: `${apiURL}/users/refresh`,
    athletesEndpoint: `${apiURL}/users/`,
    clubsEndpoint: `${apiURL}/clubs/`,
    userEmail: `${apiURL}/users/email/`,
    allClubUsers: `${apiURL}/users/`,
    clubUsers: clubId => `${apiURL}/clubs/${clubId}/users`,
    clubTeams: clubId => `${apiURL}/teams/${clubId}`,
    monthlyStats: clubId => `${apiURL}/clubs/${clubId}/athletes/stats`,
    topModules: clubId => `${apiURL}/clubs/${clubId}/modules`,
    activeAthletes: clubId => `${apiURL}/clubs/${clubId}/athletes`,
    invite: `${apiURL}/invite/`,
    verifyPassword: `${apiURL}/users/verifyPassword`,
    requestResetPassword: username => `${apiURL}/users/forgotPassword/${username}`,
    resetPassword: `${apiURL}/users/resetPassword`,
    codeSingle: code => `${apiURL}/onboardcodes/code/${code}`,
    listAllTeam: `${apiURL}/teams`,
    getWebhookSubmission: code => `${apiURL}/onboardcodes/code/${code}/jotformsubmission`,
    onboard: `${apiURL}/onboard`,
    markEmailAsOpen: code => `${apiURL}/sentonboardingemails/invitecode/${code}/open`,
    sentOnboardingEmails: club => `${apiURL}/sentonboardingemails/club/${club}`,
    // allSentOnboardingEmails: `${apiURL}/onboardcodes/` // this request gets onboard code only
    allSentOnboardingEmails: `${apiURL}/sentonboardingemails/` // this request gets more details
    // such as user AND onboardCode properties
  }
};
