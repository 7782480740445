import React from 'react';
import pt from 'prop-types';
import './AthletesMonthSection.scss';
//prettier-ignore
import { 
  utilityVariables as utilVar 
} from 'utility/dashboard/athletesMonth/athletesMonthSection';

const AthletesMonthSection = ({ sectionData }) => {
  return (
    <div className={utilVar.athletesMonthSection.classNames.monthlyStats}>
      {sectionData.map((section, index) => {
        return (
          <div
            key={`${section.fieldName}-${index}`}
            className={
              index === sectionData.length - 1
                ? `${utilVar.athletesMonthSection.classNames.section} mr-3`
                : utilVar.athletesMonthSection.classNames.section
            }
          >
            <t-1 class='athleteMonthSectionTitle mb-0'>
              {section.fieldValue}
            </t-1>
            {section.fieldName.split('\n').map((name, idx) => (
              <s-1 key={`${name}${idx}`} class='athleteMonthSectionSubTitle'>
                {name}
              </s-1>
            ))}
          </div>
        );
      })}
    </div>
  );
};

AthletesMonthSection.propTypes = {
  sectionData: pt.array.isRequired
};

export default AthletesMonthSection;
