import React from 'react';
import { utilityVariables as utilVar } from 'utility/sidebar';
import clubLogo from 'assets/Logo@3x.png';

const Credits = () => {
  return (
    <div className='d-flex justify-content-between text-white'>
      <b-2 class='sf-display-bold pl-2'>{utilVar.sidebar.poweredBy}</b-2>
      <div className='d-flex flex-column align-items-center pr-2'>
        <img
          src={clubLogo}
          alt={utilVar.sidebar.creditsLogo.alt}
          width={utilVar.sidebar.creditsLogo.width}
          height={utilVar.sidebar.creditsLogo.height}
        />
        <bt-4 class='sf-text-light'>{utilVar.sidebar.version}</bt-4>
      </div>
    </div>
  );
};

export default Credits;
