import React from 'react';
import { Formik, Form } from 'formik';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/settings';
import PasswordContainer from '../PasswordContainer';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import pt from 'prop-types';
import './ChangePassword.scss';

const ChangePassword = ({ email, setSettingsEdit }) => (
  <div className='d-flex flex-column align-items-center'>
    <Formik
      initialValues={utilVar.settings.changePassword.initialValues}
      validationSchema={utilVar.settings.changePassword.schema(email)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setSettingsEdit(null);
        utilVar.settings.changePassword.submit(values);
      }}
    >
      {({ errors, setErrors }) => (
        <Form className='d-flex flex-column changePasswordForm'>
          <input hidden type='text' autoComplete='username' />
          <PasswordContainer
            label={utilVar.settings.changePassword.oldPassword.label}
            fieldName={utilVar.settings.changePassword.oldPassword.fieldName}
            errors={errors}
            border
          />
          <PasswordContainer
            label={utilVar.settings.changePassword.newPassword.label}
            fieldName={utilVar.settings.changePassword.newPassword.fieldName}
            errors={errors}
            border
          />
          <PasswordContainer
            label={utilVar.settings.changePassword.repeatPassword.label}
            fieldName={utilVar.settings.changePassword.repeatPassword.fieldName}
            errors={errors}
            border
          />
          <div className='d-flex flex-column mx-md-0 mt-md-0 mt-2'>
            <button
              className={utilVar.settings.changePassword.confirm}
              type='submit'
              onClick={() => utilFunc.resetErrors(setErrors)}
            >
              <bt-2>{BTN_LOCALIZATIONS.en.SAVE}</bt-2>
            </button>
            <button
              className={utilVar.settings.changePassword.cancel}
              type='reset'
              onClick={() => setSettingsEdit(null)}
            >
              <bt-2>{BTN_LOCALIZATIONS.en.CANCEL}</bt-2>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ChangePassword.propTypes = {
  email: pt.string.isRequired,
  setSettingsEdit: pt.func.isRequired
};

export default ChangePassword;
