import React from 'react';
import pt from 'prop-types';
import { Row } from 'react-bootstrap';
import LoginFlowField from '../LoginFlowField';

const LoginFlowFieldColumn = ({
    loginFlowFields,
    errors,
    setErrors,
    setFieldValue,
    values
}) => {
    return (
        (loginFlowFields.length > 4) ?
            [<Row key={1} className='signUp-row'>
                {loginFlowFields.slice(0, 2).map((field, i) => (
                    <LoginFlowField
                        key={`${field.label}${i}`}
                        label={field.label}
                        type={field.type}
                        fieldName={field.fieldName}
                        error={errors[field.fieldName]}
                        placeholder={field?.placeholder}
                        fieldProps={{
                            setFieldValue: setFieldValue,
                            dob: values.dob
                        }}
                    />
                ))}
            </Row>,
            <Row key={2} className='signUp-row'>
                {loginFlowFields.slice(2, 4).map((field, i) => (
                    <LoginFlowField
                        key={`${field.label}${i}`}
                        label={field.label}
                        type={field.type}
                        fieldName={field.fieldName}
                        error={errors[field.fieldName]}
                        placeholder={field?.placeholder}
                        fieldProps={{
                            setFieldValue: setFieldValue,
                            dob: values.dob
                        }}
                    />
                ))}
            </Row>,
            <Row key={3} className='signUp-row'>
                {loginFlowFields.slice(4, 6).map((field, i) => (
                    <LoginFlowField
                        key={`${field.label}${i}`}
                        label={field.label}
                        type={field.type}
                        fieldName={field.fieldName}
                        error={errors[field.fieldName]}
                        placeholder={field?.placeholder}
                        fieldProps={{
                            setFieldValue: setFieldValue,
                            dob: values.dob
                        }}
                    />
                ))}
            </Row>,
            <Row key={4} className='signUp-row'>
                {loginFlowFields.slice(6, 8).map((field, i) => (
                    <LoginFlowField
                        key={`${field.label}${i}`}
                        label={field.label}
                        type={field.type}
                        fieldName={field.fieldName}
                        error={errors[field.fieldName]}
                        placeholder={field?.placeholder}
                        fieldProps={{
                            setFieldValue: setFieldValue,
                            dob: values.dob
                        }}
                        disabled={field.fieldName === 'clubName'}
                    />
                ))}
            </Row>]
            : loginFlowFields.slice(0, 4).map((field, i) => (
                <LoginFlowField
                    key={`${field.label}${i}`}
                    label={field.label}
                    type={field.type}
                    fieldName={field.fieldName}
                    error={errors[field.fieldName]}
                    placeholder={field?.placeholder}
                    fieldProps={{
                        setFieldValue: setFieldValue,
                        dob: values.dob
                    }}
                />
            ))
    );
};

LoginFlowFieldColumn.propTypes = {
    loginFlowFields: pt.object.isRequired,
    errors: pt.object.isRequired,
    setErrors: pt.func.isRequired,
    setFieldValue: pt.func.isRequired,
    values: pt.any.isRequired
};

export default LoginFlowFieldColumn;
