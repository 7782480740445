const promptConstants = {
  delay: 200,
  duration: 3000,
  success: 'success',
  danger: 'danger'
};

export {
  promptConstants
};
