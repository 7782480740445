import React from 'react';
import pt from 'prop-types';
import { utilityVariables as utilVar } from 'utility/dashboard/topModules';
import ComingSoon from 'components/ComingSoon';
import './TopModules.scss';


const TopModules = ({ topModules }) => {
  return (
    <div className={utilVar.topModules.classNames.container}>
      <ComingSoon />
      {/* <table className='w-100'>
        <thead>
          <tr className='sf-display-bold top-exercises-title'>
            <td className='w-100'>
              <dh-1>{utilVar.topModules.modules}</dh-1>
            </td>
            <td>
              <dh-1>{utilVar.topModules.players}</dh-1>
            </td>
          </tr>
        </thead>
        <tbody>
          {topModules.map((exercise, index) => (
            <TopModulesRow
              key={`topExercise${index}`}
              index={index + 1}
              exerciseName={exercise.name}
              exerciseCount={exercise.players}
            />
          ))}
        </tbody>
      </table>
      <b-2 class={utilVar.topModules.classNames.footer}>
        {utilVar.topModules.footer}
      </b-2> */}
    </div>
  );
};

TopModules.propTypes = {
  topModules: pt.array
};

TopModules.defaultProps = {
  topModules: []
};

export default TopModules;
