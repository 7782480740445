import React from 'react';
import { utilityFunctions as utilFunc } from 'utility';
import pt from 'prop-types';

const LargeModalConfirm = ({ setErrors, confirm }) => {
  return (
    <button
      className='modalSave border-0 rounded largeConfirm-btn'
      onClick={utilFunc.resetErrors(setErrors)}
      type='submit'
    >
      <bt-1 class='sf-display-bold text-white'>{confirm}</bt-1>
    </button>
  );
};

LargeModalConfirm.propTypes = {
  setErrors: pt.func.isRequired,
  confirm: pt.string
};

LargeModalConfirm.defaultProps = {
  confirm: 'Confirm'
};

export default LargeModalConfirm;
