import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  athletesMonth: {
    classNames: {
      athletesMonth: utilFunc.joinClassNames([
        'd-flex',
        'flex-column',
        'flex-sm-row',
        'athleteMonthContainer',
        'justify-content-between',
        'align-items-center',
        'mt-3'
      ])
    }
  }
};
