import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proInstinctAPI from 'proInstinctAPI';

export const getTeams = createAsyncThunk(
  'teamIds/getTeams',
  async () => {
    const data = await proInstinctAPI.getAllTeams();
    return data;
  }
);

const initialState = {
  data: null
};

export const slice = createSlice({
  name: 'teamIds',
  initialState,
  reducers: {
    resetTeamDataState() {
      return initialState;
    },
    setTeamData(state, action) {
      const data = action.payload;
      state.data = data;
    }
  },
  extraReducers: {
    [getTeams.fulfilled]: (state, action) => {
      const data = action.payload;
      state.data = data;
    }
  }
});

export const {
  setTeamData,
  resetTeamDataState
} = slice.actions;

export const getTeamData = state => state.teamIds.data;

export default slice.reducer;
