import React from 'react';
import ModalBody from 'react-bootstrap/ModalBody';
import { utilityFunctions as utilFunc } from 'utility/modal';
import pt from 'prop-types';

const LargeModalBody = ({ fields, errors }) => {
  return (
    <ModalBody>{utilFunc.configuringModalFields(fields, errors)}</ModalBody>
  );
};

LargeModalBody.propTypes = {
  fields: pt.array,
  errors: pt.object
};

LargeModalBody.defaultProps = {
  fields: [],
  errors: {}
};

export default LargeModalBody;
