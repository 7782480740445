import React from 'react';
import pt from 'prop-types';

const MediumModalButton = ({ className, handleClick, type, buttonText }) => {
  return (
    <button className={className} onClick={handleClick} type={type}>
      <bt-1 className='mb-0'>{buttonText}</bt-1>
    </button>
  );
};

MediumModalButton.propTypes = {
  className: pt.string,
  handleClick: pt.func.isRequired,
  type: pt.string.isRequired,
  buttonText: pt.string
};

MediumModalButton.defaultProps = {
  className: '',
  buttonText: 'Confirm'
};

export default MediumModalButton;
