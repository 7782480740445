import React from 'react';
import pt from 'prop-types';
import { Spinner } from 'react-bootstrap';
import clubLogo from 'assets/logo.png';
import { useSelector } from 'react-redux';
import { getPermission } from 'features/Authentication';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilSideBar } from 'utility/sidebar';
import { utilityVariables as utilIcons } from 'utility/icons';
import { utilityVariables as utilPermissions } from 'utility/permissions';
import { getClubInfo, getFetchingClubInfo } from 'features/Settings';
import './ClubHeading.scss';

const ClubHeading = () => {
  const role = utilPermissions.permissionMapping[useSelector(getPermission)];
  const displayName = clubName =>
    utilFunc.displayFullClubName(clubName)
      ? clubName
      : `${clubName.substring(0, 12)}...`;

  const clubInfo = useSelector(getClubInfo);
  const fetchingClubInfo = useSelector(getFetchingClubInfo);
  const fetchingStatus = [fetchingClubInfo];

  return (
    <span className={utilSideBar.sidebar.classNames.clubHeadingContainer}>
      <div className='row w-100'>
        <div className={utilSideBar.sidebar.classNames.clubLogo}>
          <img
            src={clubLogo}
            alt={utilSideBar.sidebar.clubLogoAlt}
            width={utilIcons.icons.clubLogo.width}
            height={utilIcons.icons.clubLogo.height}
          />
        </div>
        <div className={utilSideBar.sidebar.classNames.clubName}>
          {utilFunc.checkLoading(fetchingStatus) ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <h3 className='sf-display-bold mb-0'>
              {displayName(clubInfo.name)}
            </h3>
          )}
          <b-3 class='sf-display-light'>{role}</b-3>
        </div>
      </div>
    </span>
  );
};

ClubHeading.propTypes = {
  role: pt.string
};

ClubHeading.defaultProps = {
  role: 'N/A'
};

export default ClubHeading;
