import React from 'react';
import { Field } from 'formik';
import pt from 'prop-types';

const LoginFieldInput = ({ type, id, name, placeholder, autoComplete }) => {
  return (
    <span className='d-flex h-50 w-75 flex-row-reverse'>
      <Field
        className='w-100 border-0 loginFieldInput'
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </span>
  );
};

LoginFieldInput.propTypes = {
  type: pt.string.isRequired,
  id: pt.string.isRequired,
  name: pt.string.isRequired,
  placeholder: pt.string.isRequired,
  autoComplete: pt.string.isRequired
};

export default LoginFieldInput;
