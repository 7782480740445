export const utilityVariables = {
  permissionMapping: {
    SITE_ADMIN: 'site admin',
    CLUB_ADMIN: 'club admin',
    USER: 'user',
    ADMIN: 'admin',
    ATHLETE: 'athlete',
    api: {
      SITE_ADMIN: 'SITE_ADMIN',
      CLUB_ADMIN: 'CLUB_ADMIN',
      USER: 'USER'
    }
  }
};
