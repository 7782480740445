import React from 'react';
import pt from 'prop-types';
import { utilityVariables as utilToggle } from 'utility/toggleSwitch';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ setState, state, width }) => {
  return (
    <div
      className={`toggle d-flex justify-content-between ${
        state ? 'offBg' : 'onBg'
      }`}
      onClick={() => setState(!state)}
    >
      {state && width > 768 ? (
        <s-1 class={utilToggle.toggleSwitch.on.classNames}>
          {'ON'}
        </s-1>
      ) : null}
      <div className={state ? 'bg-white off' : 'bg-white on'} />
      {!state && width > 768 ? (
        <s-1 class={utilToggle.toggleSwitch.off.classNames}>
          {'OFF'}
        </s-1>
      ) : null}
    </div>
  );
};

ToggleSwitch.propTypes = {
  setState: pt.func.isRequired,
  state: pt.bool.isRequired,
  width: pt.number
};

ToggleSwitch.defaultProps = {
  width: window.innerWidth
};

export default ToggleSwitch;
