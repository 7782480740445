import React from 'react';
import pt from 'prop-types';

const FieldDisplay = ({
  label,
  value,
  textColor,
  actionButton
}) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='field d-flex flex-column sf-display-regular'>
        <dh-2 class={`${textColor} font-weight-bold mb-1`}>{label}</dh-2>
        <b-1 class={`${textColor}`}>{value}</b-1>
      </div>
      {actionButton}
    </div>
  );
};

FieldDisplay.propTypes = {
  label: pt.string,
  value: pt.string,
  textColor: pt.string,
  actionButton: pt.node.isRequired
};

FieldDisplay.defaultProps = {
  label: '',
  value: '',
  textColor: 'text-dark'
};

export default FieldDisplay;
