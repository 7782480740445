export const utilityVariables = {
  404: {
    title: '404',
    body:
      //prettier-ignore
      // eslint-disable-next-line max-len
      'Looks like you\'ve taken a wrong turn sailor. Click the button below to go back',
    buttonText: 'Go Back'
  }
};
