import React, { useState, useEffect } from 'react';
import pt from 'prop-types';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getShowAddAthlete } from 'features/Modal';
import { utilityVariables as utilInput } from 'utility/fields/inputField';
import { utilityVariables as utilPhone } from 'utility/fields/phoneField';
import { utilityVariables as utilModal } from 'utility/modal';
import { utilityVariables as utilMessages } from 'utility/messages';
import { utilityVariables as utilPermissions } from 'utility/permissions';
// import { utilityFunctions as utilFunc } from 'utility';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import { promptConstants } from 'features/Prompt/PromptConstants';
import { triggerPrompt } from 'features/Prompt/PromptUtility';

import { Modal, Spinner } from 'react-bootstrap';
import addModalIcon from 'assets/addModal@3x.png';
import PhoneField from '../../Fields/PhoneField';
import SelectField from '../../Fields/SelectField';
import InputField from '../../Fields/InputField';
import LargeModalFooter from '../LargeModalFooter';
import LargeModalBody from '../LargeModalBody';
import LargeModalHeader from '../LargeModalHeader';
import DateField from 'components/Fields/DateField';
import '../Modal.scss';
import {
  fetchClubAthletes,
  addClubUser,
  sendUserInvite,
  getClubTeams,
  getAddingAthlete,
  getAddedAthlete,
  getDidAddFail,
  getDidAddPass,
  resetAthleteResult
} from 'features/Athletes';
import { getTeamData } from 'features/TeamIds';
import { utilityFunctions } from 'utility';

const AddUser = ({ toggleAthleteModal }) => {
  const dispatch = useDispatch();
  const [parentDisabled, setParentDisabled] = useState(false);
  const showAddAthlete = useSelector(getShowAddAthlete);
  const teams = useSelector(getClubTeams);
  const teamData = useSelector(getTeamData);
  const teamDataFormatted = teamData ? teamData.map(team => ({ label: team.type, value: team.id })) : {};
  const addingAthlete = useSelector(getAddingAthlete);
  const addingAthleteFailed = useSelector(getDidAddFail);
  const addingAthleteSuccess = useSelector(getDidAddPass);
  const addedAthlete = useSelector(getAddedAthlete);

  useEffect(() => {
    if (addingAthleteFailed) {
      dispatch(resetAthleteResult());
      triggerPrompt(
        utilMessages.errors.athleteAddFailed,
        promptConstants.danger,
        dispatch
      );
      toggleAthleteModal();
    } else if (addingAthleteSuccess) {
      dispatch(resetAthleteResult());
      triggerPrompt(
        utilMessages.success.athleteAdded,
        promptConstants.success,
        dispatch
      );
      dispatch(
        fetchClubAthletes({
          toFetch: true,
          // the idea here is that we can only add users from the athletes page
          // therefore the permission is guaranteed to be USER
          permission: utilPermissions.permissionMapping.api.USER
        })
      );
      toggleAthleteModal();
    }
  }, [addingAthleteSuccess, addingAthleteFailed, dispatch, toggleAthleteModal]);

  useEffect(() => {
    // only if the user is 16 or older for now.
    
    // TODO removing this check for now, as we manually verify users' age
    // if (
    //   addingAthleteSuccess &&
    //   utilFunc.validateAge(addedAthlete.dateOfBirth)
    // ) {
    //   dispatch(sendUserInvite(addedAthlete.id));
    // }

    if (addingAthleteSuccess) {
      dispatch(sendUserInvite(addedAthlete.id));
    }
  }, [addedAthlete, addingAthleteSuccess, dispatch]);

  return (
    <Formik
      // since we can only add athletes
      // I'm going to reuse initial from edit user
      initialValues={utilModal.modal.editUser.athlete.initial}
      validationSchema={utilModal.validateUser}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        const userPayload = [{
          firstName: values.firstName,
          parentEmail: parentDisabled ? null : values.parentEmail,
          birthdate: utilityFunctions.adjustDateToIso2(values.dob),
          teamId: values.team
        }];
        dispatch(sendUserInvite(userPayload, toggleAthleteModal(false)));
      }}
    >
      {({ errors, setErrors, setFieldValue, values }) => {
        const addUserFields = [
          {
            name: 'firstName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'lastName',
            field: (
              <InputField
                type='text'
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'dob',
            field: (
              <DateField
                setFieldValue={setFieldValue}
                setParentDisabled={setParentDisabled}
                dob={values.dob}
              />
            )
          },
          {
            name: 'parentEmail',
            field: (
              <InputField
                type='email'
                disabled={parentDisabled}
                className={utilInput.inputField.classNames}
              />
            )
          },
          {
            name: 'team',
            field: <SelectField setValue={setFieldValue} options={teamDataFormatted} />
          },
          {}
        ];

        return (
          <Modal show={showAddAthlete} onHide={toggleAthleteModal} size='xl'>
            <Form>
              <LargeModalHeader
                icon={addModalIcon}
                alt={utilModal.modal.addUser.modalHeaderAlt}
                title={utilModal.modal.addUser.title}
              />
              <LargeModalBody fields={addUserFields} errors={errors} />
              {addingAthlete ? (
                <div className='d-flex justify-content-center mb-3'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : null}
              <LargeModalFooter
                setParentDisabled={setParentDisabled}
                toggleModal={toggleAthleteModal}
                cancelText={BTN_LOCALIZATIONS['en'].CANCEL}
                setErrors={setErrors}
                confirmText={BTN_LOCALIZATIONS['en'].INVITE}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

AddUser.propTypes = {
  toggleAthleteModal: pt.func.isRequired
};

export default AddUser;
