import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import pt from 'prop-types';

import {
  fetchClubTeams,
  getClubUsers,
  getClubTeams,
  fetchClubAthletes
} from 'features/Athletes';
import { signOut } from 'features/Authentication';
import { utilityFunctions as utilFuncExcel } from 'utility/excelImport';
import { utilityVariables as utilPermissions } from 'utility/permissions';
import { utilityFunctions as utilFunc } from 'utility';
import { getTokenAuthentic } from 'features/API';
import proInstinctAPI from 'proInstinctAPI';
import { getTeamData, getTeams } from 'features/TeamIds';
import MatTable from 'utility/materialUI';
import AlertModal from 'components/AlertModal';

import AddUser from '../../Modals/AddUser';
import DeleteUser from '../../Modals/DeleteUser';
import EditUser from '../../Modals/EditUser';
import AddEntry from '../../AddEntry';
import {
  toggleAddAthlete,
  toggleDeleteAthlete,
  toggleEditAthlete
} from 'features/Modal';

import { selectConvertedClubUsers } from 'features/Athletes/AthletesSelectors';

import { utilityVariables as utilModal } from 'utility/modal';
// import { utilityVariables as utilPermissions } from 'utility/permissions';

const InviteAthletes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clubUsers = useSelector(getClubUsers);
  const teams = useSelector(getClubTeams);
  const teamData = useSelector(getTeamData);
  const authenticToken = useSelector(getTokenAuthentic);

  const convertedClubUsers = useSelector(selectConvertedClubUsers);

  const toggleAthleteModal = () => dispatch(toggleAddAthlete());
  const toggleDeleteModal = () => dispatch(toggleDeleteAthlete());
  const toggleEditModal = () => dispatch(toggleEditAthlete());

  const [deta, setDeta] = useState([]);
  const [colDefs, setColDefs] = useState();
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [inputKey, setInputKey] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [importValErrors, setImportValErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(<div />);

  const [data, setData] = useState([]);
  const [dataToSend, setDataToSend] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!teamData) {
      dispatch(getTeams);
    }
  }, [dispatch, teamData]);

  useEffect(() => {
    if (!clubUsers) {
      dispatch(
        fetchClubAthletes({
          toFetch: false,
          permission: 'USER'
        })
      );
    }
  }, [dispatch, clubUsers]);

  useEffect(() => {
    if (!teams.length) {
      dispatch(fetchClubTeams());
    }
  }, [teams, dispatch]);

  useEffect(() => {
    if (!authenticToken) {
      dispatch(signOut());
      history.push('/');
    }
  }, [authenticToken, dispatch, history]);

  useEffect(() => {
    if (deta && deta.length !== 0) {
      setDataToSend(deta.map(item => {
        return utilFunc.picked(
          item, 
          teamData,
          'First Name', 
          'Team', 
          'Birthdate', 
          'Invite Email'
        );
      }));
    }
    setData(deta);
    setIsLoadingExcel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deta]);

  function clear() {
    let randomString = Math.random().toString(36);

    setInputKey(randomString);
    setImportValErrors([]);
    setColDefs([]);
    setDeta([]);
  }

  return (
    <div className='text-center'>
      <>
      <div className='mb-3 mt-3' style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{
                width: '30px',
                height: '30px'
              }}>
                {
                  isLoadingExcel ?
                    <Spinner
                      as='span'
                      animation='border'
                      role='status'
                      aria-hidden='true'
                      size='sm'
                    />
                    : ''
                }
              </div>
              <input type='file' onChange={e => {
                utilFuncExcel.importExcel(
                  e,
                  setIsLoadingExcel,
                  setColDefs,
                  importValErrors,
                  setImportValErrors,
                  setDeta,
                  setErrorMessage,
                  setShowAlert
                );
              }} key={inputKey} disabled={teamData ? false : true} />
              <div style={{
                flexGrow: 1
              }} />
              <AddUser toggleAthleteModal={toggleAthleteModal} />
                {/* <DeleteUser
                  toggleDeleteModal={toggleDeleteModal}
                  permission={utilPermissions.permissionMapping.api.USER}
                />
                <EditUser
                  toggleEditModal={toggleEditModal}
                  clubUsers={convertedClubUsers}
                  initialValues={utilModal.modal.editUser.athlete.initial}
                  validationSchema={utilModal.validateUser}
                  onSubmit={utilModal.modal.editUser.athlete.onSubmit}
                  editType={utilPermissions.permissionMapping.ATHLETE}
                  permission={utilPermissions.permissionMapping.api.USER}
                /> */}
              <AddEntry action={toggleAthleteModal} />
              <Button variant='secondary' onClick={clear}>
                Clear List
              </Button>
            </div>
        <MatTable title='Onboarding List' data={data} columns={colDefs}/>
        <Button className='mt-3' size='lg' variant='primary' onClick={e => {
          setLoading(true);
          proInstinctAPI.onboardInvite(dataToSend, handleShow).then(val => {
            setLoading(false);
          });
        }} disabled={data === null || data.length === 0 || isLoading ? true 
        : false}>
          {isLoading ? <Spinner
            as='span'
            animation='border'
            role='status'
            aria-hidden='true'
          /> : 'Send!'}
        </Button>
        <AlertModal
          show={show}
          handleClose={handleClose}
          titleText={'Woohoo! Inviting people!'}
          bodyText={'The emails have been sent to the above list.'}
          footerJsx={
            [<Button key={'1'} variant='secondary' onClick={e => {
              clear();
              handleClose();
            }}>
              Clear List
            </Button>,
            <Button key={'2'} variant='primary' onClick={handleClose}>
              Exit
            </Button>]
          }
        />
        <AlertModal
          show={showAlert}
          handleClose={() => {
            setShowAlert(false);
            setImportValErrors([]);
            setErrorMessage('');
          }}
          titleText={
            'Oh snap! You got some funny stuff going on in your spreadsheet.'
          }
          bodyText={errorMessage}
        />
      </>
    </div>
  );
};

InviteAthletes.propTypes = {
};

export default InviteAthletes;
