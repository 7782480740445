import React from 'react';
import pt from 'prop-types';

const LargeModalCancel = ({ setParentDisabled, toggleModal, cancel }) => {
  return (
    <button
      className='modalCancel largeCancel-btn border-0 rounded mr-3'
      onClick={() => {
        setParentDisabled(false);
        toggleModal();
      }}
      type='reset'
    >
      <bt-1 class='sf-display-bold text-white'>{cancel}</bt-1>
    </button>
  );
};

LargeModalCancel.propTypes = {
  setParentDisabled: pt.func.isRequired,
  toggleModal: pt.func.isRequired,
  cancel: pt.string
};

LargeModalCancel.defaultProps = {
  cancel: 'Cancel'
};

export default LargeModalCancel;
