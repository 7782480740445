import React, { useState } from 'react';
import { utilityVariables as utilLoginFlow } from 'utility/login/loginFlow';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import LoginFlowInstructions from 'components/LoginFlow/LoginFlowInstructions';
import LoginFlowCancel from 'components/LoginFlow/LoginFlowCancel';
import ForgotPasswordForm from 'components/LoginFlow/ForgotPasswordForm';

const ResetPasswordForm = () => {
  const [spin, setSpin] = useState(false);

  return (
    <>
      <div className='loginFlowBG' />
      <div className={utilLoginFlow.loginFlow.classNames}>
        <div className='loginFlowContainer'>
          <LoginFlowInstructions
            title={utilLoginFlow.loginFlow.resetPassword.header}
          />
          <ForgotPasswordForm
            initialValues={utilLoginFlow.loginFlow.resetPassword.initialValues}
            schema={utilLoginFlow.loginFlow.resetPassword.validation}
            spin={spin}
            setSpin={setSpin}
            loginFlowFields={utilLoginFlow.loginFlow.resetPassword.fields}
            submissionLabel={BTN_LOCALIZATIONS['en'].RESETPASSWORD}
            navigateTo={utilLoginFlow.resetPasswordForm.navigateTo}
          />
          <LoginFlowCancel
            text={utilLoginFlow.loginFlow.resetPassword.cancel}
            linkRef={utilLoginFlow.resetPasswordForm.cancelTo}
            linkLabel={utilLoginFlow.loginFlow.resetPassword.cancelLink}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
