import IframeModal from 'components/IframeModal';
import React, { useState } from 'react';
import { utilityVariables as utilVar } from 'utility/tos';

const TermsOfService = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <>
      <b-1 class='ml-2 text-white sf-text-regular'>
        {utilVar.tos.heading}{' '}
        <btn className='loginFlowCancelLink' onClick={e => {
          setShowTerms(true);
        }}>
          {utilVar.tos.terms}
        </btn>{' '}
        and{' '}
        <btn className='loginFlowCancelLink' onClick={e => {
          setShowPrivacy(true);
        }}>
          {utilVar.tos.privacyPolicy}
        </btn>
      </b-1>
      <IframeModal 
        show={showTerms}
        handleClose={e =>{
          setShowTerms(false);}}
        titleText='Terms of Service'
        bodyJsx={
          <iframe
            title='Proinstinct Terms of Service'
            allowtransparency='true' 
            allowFullScreen={true} 
            src={'https://www.proinstinctapp.com/terms/'}
            frameBorder='none'
            className='jotform-iframe'
          />
        }
      />
      <IframeModal 
        show={showPrivacy}
        handleClose={e =>{
          setShowPrivacy(false);}}
        titleText='Privacy Policy'
        bodyJsx={
          <iframe
            title='Proinstinct Privacy Policy'
            allowtransparency='true' 
            allowFullScreen={true} 
            src={'https://www.proinstinctapp.com/privacy/'}
            frameBorder='none'
            className='jotform-iframe'
          />
        }
      />
    </>
  );
};

export default TermsOfService;
