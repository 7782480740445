import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import LoginFlowField from 'components/LoginFlow/LoginFlowField';
import LoginFlowSpinner from 'components/LoginFlow/LoginFlowSpinner';
import LoginFlowSubmission from 'components/LoginFlow/LoginFlowSubmission';
import TermsOfService from 'components/TermsOfService';
import pt from 'prop-types';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilAPI } from 'utility/api';
import proInstinctAPI from 'proInstinctAPI';

const ResetPasswordFormUtil = ({
  initialValues,
  schema,
  spin,
  setSpin,
  loginFlowFields,
  submissionLabel,
  navigateTo
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [apiError, setApiError] = useState(false);
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        utilFunc.handleApiRequestWithLoader(
          values, 'username', proInstinctAPI.forgotPassword, 
          setApiError, setSpin, history.push, setErrorMessage, navigateTo
        );
      }}
    >
      {({ errors, setErrors }) => (
        <Form className='d-flex flex-column mb-2'>
          <div className='fieldContainer'>
            {loginFlowFields.map((field, i) => (
              <LoginFlowField
                key={`${field.label}${i}`}
                label={field.label}
                type={field.type}
                fieldName={field.fieldName}
                error={apiError ? 
                  errorMessage : 
                  errors[field.fieldName]
                }
                placeholder={field?.placeholder}
              />
            ))}
          </div>
          <LoginFlowSpinner show={spin} />
          {history.location.pathname === '/signUp' ? <TermsOfService /> : null}
          <LoginFlowSubmission
            spin={spin}
            setSpin={setSpin}
            setErrors={setErrors}
            submissionText={submissionLabel}
          />
        </Form>
      )}
    </Formik>
  );
};

ResetPasswordFormUtil.propTypes = {
  initialValues: pt.object.isRequired,
  schema: pt.object.isRequired,
  spin: pt.bool.isRequired,
  setSpin: pt.func.isRequired,
  loginFlowFields: pt.array.isRequired,
  submissionLabel: pt.string.isRequired,
  navigateTo: pt.string.isRequired
};

export default ResetPasswordFormUtil;
