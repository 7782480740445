import React from 'react';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/hamburger';
import pt from 'prop-types';
import './Hamburger.scss';

const Hamburger = ({
  hamburgerClick,
  setResponsiveClick,
  isHamburgerClicked,
  isResponsiveClicked,
  width
}) => {
  return (
    <div
      className={utilVar.hamburger.classNames}
      onClick={() =>
        utilFunc.hamburgerClickHandler(
          hamburgerClick,
          isHamburgerClicked,
          setResponsiveClick,
          isResponsiveClicked,
          width
        )
      }
    >
      <span className='bars mb-1' />
      <span className='bars mb-1' />
      <span className='bars' />
    </div>
  );
};

Hamburger.propTypes = {
  hamburgerClick: pt.func.isRequired,
  setResponsiveClick: pt.func.isRequired,
  isHamburgerClicked: pt.bool.isRequired,
  isResponsiveClicked: pt.bool.isRequired,
  width: pt.number
};

Hamburger.defaultProps = {
  width: window.innerWidth
};

export default Hamburger;
