import React, { useEffect, useState } from 'react';
import { utilityVariables as utilVar } from 'utility/login/loginFlow';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import ReactCodeInput from 'react-code-input';
import AccessCodeSubmission from '../AccessCodeSubmission';
import LoginFlowSpinner from 'components/LoginFlow/LoginFlowSpinner';
import { Modal } from 'react-bootstrap';
import JotformParentalConsent from '../JotformIframe';
import { Link, useParams } from 'react-router-dom';
import proInstinctAPI from 'proInstinctAPI';
import { useHistory } from 'react-router-dom';


const AccessCode = () => {
  let { id } = useParams();

  const [validCode, setValidCode] = useState(id ? true : false);
  const [verifiedCode] = useState(false);
  const [isValid] = useState(true);
  const [inputEvent, setInputEvent] = useState(id ? id : '');
  const [spin, setSpin] = useState(false);
  const [errors, setErrors] = useState('');
  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function handleInputChange(e) {
    setInputEvent(e);
    // validating length only 
    // other types of validation:
    // --- ignoring chars [-, /]
    // --- 
    if (e.length === 6) {
      setValidCode(true);
    } else {
      setValidCode(false);
    }
  }

  function isValidOutput(valCode, verCode, setVal) {
    setVal(!(valCode && !verCode));
  }

  useEffect(() => {
    localStorage.clear();
    if (id) {
      setSpin(true);
        isValidOutput(validCode, verifiedCode, setValidCode);
        let verified;

        proInstinctAPI.validateCode(id).then((async res => {
          setSpin(false);
          verified = res.goodRes;
          if (verified) {
            localStorage.setItem('onboardCode', id);
            localStorage.setItem('onboardGoodRes', res.goodRes);
            localStorage.setItem('onboardIsUnderage', res.isUnderage);
            localStorage.setItem('onboardTeamIds', res.teamId);
            await proInstinctAPI.markEmailAsOpen(id);

            // check if we've already gotten a sub
            await proInstinctAPI.verifySubmission(id).then(val => {
              if (!(val === undefined || val === null || val.length === 0)) {
                if (res.isUnderage) {
                  localStorage.setItem(
                    'onboardFirstName', val[val.length - 1].firstAthlete.firstName
                  );
                  localStorage.setItem(
                    'onboardLastName', val[val.length - 1].firstAthlete.lastName
                  );
                  localStorage.setItem(
                    'onboardParentEmail', val[val.length - 1].parent_email
                  );
                  history.push('/signUp');
                } else {
                  history.push('/signUpNoConsent');
                }
              }
            }).catch(e => {
              // response not found (code error or API error)
              console.error(e);
            });

            if (res.isUnderage) {
              handleShow();
            } else {
              history.push('/signUpNoConsent'); // navigateTo
            }
          } else {
            setErrors('Incorrect access code.');
            setTimeout(() => {
              setErrors('');
            }, 3000);
          }
        }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    inputStyle: {
      margin: '4px',
      width: '32px',
      borderRadius: '3px',
      fontSize: '18px',
      height: '40px'
    },
    inputStyleInvalid: {
      margin: '4px',
      width: '32px',
      borderRadius: '3px',
      fontSize: '18px',
      height: '40px',
      color: 'red',
      border: '2px solid red'
    }
  };

  return (
    <>
      <div className='loginFlowBG' />
      <div className={utilVar.loginFlow.classNames}>
        <div className='loginFlowContainer'>
          <LogoTitle />
          <ReactCodeInput
            type='text'
            fields={6}
            {...props}
            className='reactCodeInput'
            onChange={handleInputChange}
            isValid={isValid}
            value={id ? id : ''}
          />
          <LoginFlowSpinner show={spin} />
          <p style={{ color: 'red' }}>{errors}</p>
          <AccessCodeSubmission
            setSpin={setSpin}
            setErrors={setErrors}
            submissionText={'SUBMIT'}
            submission={inputEvent}
            isValid={validCode}
            setValidOutput={isValidOutput}
            setVal={setValidCode}
            verifiedCode={verifiedCode}
            validCode={validCode}
            handleShow={handleShow}
          />
          <b-1 class='sf-text-regular text-white ml-2'>
            If there are issues using your access code, please contact <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className='loginFlowCancelLink'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>.
          </b-1>
          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName='modal-95vw'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Consent Form</Modal.Title>
            </Modal.Header>
            <Modal.Body className='jotform-body'>
              <JotformParentalConsent
                uuid={inputEvent}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AccessCode;
