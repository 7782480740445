import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proInstinctAPI from '../../proInstinctAPI';

export const fetchMonthlyStats = createAsyncThunk(
  'dashboard/fetchMonthlyStats',
  async ({ start, end }) => {
    const {
      totalAthletes,
      totalHours,
      avgHours,
      months
    } = await proInstinctAPI.fetchMonthlyStats(start, end);
    return { totalAthletes, totalHours, avgHours, months };
  }
);

export const fetchTopModules = createAsyncThunk(
  'dashboard/fetchTopModules',
  async ({ start, end }) => {
    const { modules } = await proInstinctAPI.fetchTopModules(start, end);
    return { modules };
  }
);

export const fetchActiveAthletes = createAsyncThunk(
  'dashboard/fetchActiveAthletes',
  async ({ start, end }) => {
    const {
      athletes: activeAthletes
    } = await proInstinctAPI.fetchActiveAthletes(start, end);
    return { activeAthletes };
  }
);

const initialState = {
  totalAthletes: 0,
  totalHours: '0:00',
  avgHours: '0:00',
  monthlyStats: [],
  fetchingStats: true,
  didFetchFail: false,
  topModules: [],
  fetchingTopModules: true,
  didFetchModulesFail: false,
  activeAthletes: [],
  fetchingAthletes: true,
  didFetchAthletesFail: false
};

export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMonthlyStats.fulfilled]: (state, action) => {
      const { totalAthletes, totalHours, avgHours, months } = action.payload;
      state.totalAthletes = totalAthletes;
      state.totalHours = totalHours;
      state.avgHours = avgHours;
      state.monthlyStats = months;
      state.fetchingStats = false;
    },
    [fetchMonthlyStats.pending]: state => {
      state.fetchingStats = true;
    },
    [fetchMonthlyStats.rejected]: state => {
      state.fetchingStats = false;
      state.didFetchFail = true;
    },
    [fetchTopModules.fulfilled]: (state, action) => {
      const { modules } = action.payload;
      state.topModules = modules;
      state.fetchingTopModules = false;
    },
    [fetchTopModules.pending]: state => {
      state.fetchingTopModules = true;
    },
    [fetchTopModules.rejected]: state => {
      state.topModules = [];
      state.fetchingTopModules = false;
      state.didFetchModulesFail = true;
    },
    [fetchActiveAthletes.fulfilled]: (state, action) => {
      const { activeAthletes } = action.payload;
      state.activeAthletes = activeAthletes;
      state.fetchingAthletes = false;
    },
    [fetchActiveAthletes.pending]: state => {
      state.fetchingAthletes = true;
    },
    [fetchActiveAthletes.rejected]: state => {
      state.activeAthletes = [];
      state.fetchingAthletes = false;
      state.didFetchAthletesFail = true;
    }
  }
});

export const getTotalAthletes = state => state.dashboard.totalAthletes;
export const getTotalHours = state => state.dashboard.totalHours;
export const getAverageHours = state => state.dashboard.avgHours;
export const getFetchingStats = state => state.dashboard.fetchingStats;
export const getDidFetchFail = state => state.dashboard.didFetchFail;

export const getTopModules = state => state.dashboard.topModules;
export const getFetchingModules = state => state.dashboard.fetchingTopModules;
export const getFetchModulesFail = state => state.dashboard.didFetchModulesFail;

export const getFetchingAthletes = state => state.dashboard.fetchingAthletes;
export const getFetchAthletesFail = state =>
  state.dashboard.didFetchAthletesFail;

export default slice.reducer;
