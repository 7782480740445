import React, { useState, useEffect } from 'react';
import pt from 'prop-types';
import { Spinner } from 'react-bootstrap';
import FieldDisplay from 'components/Settings/FieldDisplay';
import FieldEdit from 'components/Settings/FieldEdit';
import ChangePassword from 'components/Settings/ChangePassword';
import Footer from 'components/Settings/Footer';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/settings';
import { utilityVariables as utilPermissions } from 'utility/permissions';
import { utilityVariables as utilModal } from 'utility/modal';
import { utilityVariables as utilMessages } from 'utility/messages';
import { toggleDeleteAthlete, toggleEditAthlete } from 'features/Modal';
import {
  fetchClubAdmins,
  getFetchingAdmins,
  getClubInfo,
  getFetchingClubInfo
} from 'features/Settings';
import { selectConvertedClubAdmins } from 'features/Settings/SettingsSelectors';
import EmailNotifications from './EmailNotifications';
import { useDispatch, useSelector } from 'react-redux';
import EditUser from '../Modals/EditUser';
import DeleteUser from 'components/Modals/DeleteUser';
import SettingsHeader from './SettingsHeader';
import FieldContainer from './FieldContainer';
import DeleteButton from './DeleteButton';
import EditSetting from './EditSetting';
import AdminInfoField from './AdminInfoField';
import AdminInfoContainer from './AdminInfoContainer';
import './Settings.scss';

const Settings = ({ width }) => {
  const dispatch = useDispatch();
  const storageId = Number(localStorage.getItem('userId'));
  const storagePermission = localStorage.getItem('permission');
  const [settingsEdit, setSettingsEdit] = useState(null);
  const clubInfo = useSelector(getClubInfo);
  const fetchingClubInfo = useSelector(getFetchingClubInfo);
  const fetchingAdmins = useSelector(getFetchingAdmins);
  const fetchingStatus = [fetchingAdmins, fetchingClubInfo];
  const clubAdmins = useSelector(selectConvertedClubAdmins);
  // why do we search through list of users for ourself?
  const currentAdmin = clubAdmins.find(admin => admin.id === storageId);

  const handleNotificationsChange = notificationsState => {
    utilVar.settings.notifications.submit(notificationsState);
  };

  useEffect(() => {
    if (!clubAdmins) {
      dispatch(
        fetchClubAdmins({
          toFetch: false,
          permission: storagePermission
        })
      );
    }
  }, [clubAdmins, dispatch, storagePermission]);

  return (
    <>
      <SettingsHeader />
      <div className='d-flex align-items-center flex-column'>
        {utilFunc.checkLoading(fetchingStatus) ? (
          <Spinner
            animation='border'
            variant='primary'
            className='position-absolute align-self-start'
          />
        ) : null}
        {/* what is this validation? */}
        {!clubInfo || !currentAdmin ? null : (
        // {(
          <div className='settings-container'>
            <AdminInfoContainer>
              {Object.keys(utilVar.settings.adminFields).map(field => (
                <AdminInfoField
                  key={field}
                  header={utilVar.settings.adminFields[field]}
                  value={currentAdmin[field]}
                />
              ))}
              <EditUser
                toggleEditModal={() => dispatch(toggleEditAthlete())}
                clubUsers={clubAdmins}
                initialValues={utilModal.modal.editUser.admin.initial}
                validationSchema={utilModal.modal.editUser.admin.schema}
                onSubmit={utilModal.modal.editUser.admin.onSubmit}
                editType={utilPermissions.permissionMapping.ADMIN}
                permission={localStorage.getItem('permission')}
              />
              <button
                className='loginFormSubmit sf-display-bold editAccount my-3'
                onClick={() => dispatch(toggleEditAthlete(storageId))}
                type='submit'
              >
                <b-2>{utilVar.settings.editAccount.title}</b-2>
              </button>
            </AdminInfoContainer>
            {clubInfo.email ? (
              <>
                <FieldContainer>
                  {settingsEdit === utilVar.settings.clubName.label ? (
                    <FieldEdit
                      label={utilVar.settings.clubName.label}
                      initialValues={{ clubName: clubInfo.name }}
                      schema={utilVar.settings.clubName.validation}
                      type={utilVar.settings.clubName.type}
                      fieldName={utilVar.settings.clubName.fieldName}
                      setSettingsEdit={setSettingsEdit}
                      onSubmit={utilVar.settings.clubName.submit}
                    />
                  ) : (
                    <FieldDisplay
                      label={utilVar.settings.clubName.label}
                      value={clubInfo.name}
                      actionButton={
                        <EditSetting
                          action={() =>
                            setSettingsEdit(utilVar.settings.clubName.label)
                          }
                          className={
                            utilVar.settings.fieldEdit.classNames
                              .editBtnContainer
                          }
                        />
                      }
                    />
                  )}
                </FieldContainer>
                <FieldContainer>
                  {settingsEdit === utilVar.settings.clubEmail.label ? (
                    <FieldEdit
                      label={utilVar.settings.clubEmail.label}
                      initialValues={{ clubEmail: clubInfo.email }}
                      schema={utilVar.settings.clubEmail.validation}
                      type={utilVar.settings.clubEmail.type}
                      fieldName={utilVar.settings.clubEmail.fieldName}
                      setSettingsEdit={setSettingsEdit}
                      onSubmit={utilVar.settings.clubEmail.submit}
                    />
                  ) : (
                    <FieldDisplay
                      label={utilVar.settings.clubEmail.label}
                      value={clubInfo.email}
                      actionButton={
                        <EditSetting
                          action={() =>
                            setSettingsEdit(utilVar.settings.clubEmail.label)
                          }
                          className={
                            utilVar.settings.fieldEdit.classNames
                              .editBtnContainer
                          }
                        />
                      }
                    />
                  )}
                </FieldContainer>
                <FieldContainer>
                  {settingsEdit === utilVar.settings.clubPassword.label ? (
                    <ChangePassword
                      email={clubInfo.email}
                      setSettingsEdit={setSettingsEdit}
                    />
                  ) : (
                    <FieldDisplay
                      label={utilVar.settings.clubPassword.label}
                      actionButton={
                        <EditSetting
                          action={() =>
                            setSettingsEdit(utilVar.settings.clubPassword.label)
                          }
                          className={
                            utilVar.settings.fieldEdit.classNames
                              .editBtnContainer
                          }
                        />
                      }
                    />
                  )}
                </FieldContainer>
                <FieldContainer>
                  <EmailNotifications
                    setState={handleNotificationsChange}
                    state={clubInfo.notifications}
                    width={width}
                  />
                </FieldContainer>
              </>
            ) : (
              // <div className='mb-3'>
              //   <dh-2 class='text-dark font-weight-bold'>
              //     {utilMessages.warnings.contactAdmin}
              //   </dh-2>
              // </div>
              null
            )}
            <DeleteUser
              toggleDeleteModal={() => dispatch(toggleDeleteAthlete())}
              permission={localStorage.getItem('permission')}
              body={utilModal.modal.deleteUser.adminMessage}
              title={utilModal.modal.deleteUser.adminTitle}
            />
            {/* Club should always have a Club Admin account
            It should never be deleted
            So button is hidden if logged in as club admin */}
            {storagePermission !==
            utilPermissions.permissionMapping.api.CLUB_ADMIN ? (
              <FieldContainer background='fieldContainer-dark'>
                <FieldDisplay
                  label={utilVar.settings.deleteAccount.title}
                  value={utilVar.settings.deleteAccount.body}
                  textColor='text-white'
                  actionButton={
                    <DeleteButton
                      onClick={() => dispatch(toggleDeleteAthlete(storageId))}
                    />
                  }
                />
              </FieldContainer>
            ) : null}
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

Settings.propTypes = {
  width: pt.number
};

Settings.defaultProps = {
  width: window.innerWidth
};

export default Settings;
