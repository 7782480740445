import React from 'react';
import { Spinner } from 'react-bootstrap';
import pt from 'prop-types';

const LoginSpinner = ({ show }) => {
  return (
    <div className='my-2'>
      {show ? <Spinner animation='border' variant='primary' /> : null}
    </div>
  );
};

LoginSpinner.propTypes = {
  show: pt.bool.isRequired
};

export default LoginSpinner;
