import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { utilityVariables as utilVar } from 'utility/dashboard/athletesTable';
import pt from 'prop-types';
import './AthletesTableRowContainer.scss';

const AthletesTableRowContainer = ({ children, ...props }) => {
  const tableRef = useRef(null);

  return (
    <CSSTransition
      classNames='fade'
      timeout={utilVar.athletesTable.transitionTimeout}
      unmountOnExit
      nodeRef={tableRef}
      {...props}
    >
      <tr className='sf-display-regular athleteTableRow' ref={tableRef}>
        {children}
      </tr>
    </CSSTransition>
  );
};

AthletesTableRowContainer.propTypes = {
  children: pt.node
};

AthletesTableRowContainer.defaultProps = {
  children: null
};

export default AthletesTableRowContainer;
