import React from 'react';
//prettier-ignore
import { 
  utilityVariables as utilVar 
} from 'utility/dashboard/athletesMonth/athletesMonthTitle';
import './AthletesMonthTitle.scss';

const AthletesMonthTitle = () => {
  return (
    <div className='d-flex flex-column athleteTitleSection pl-4'>
      <h3 className='sf-display-bold athleteMonthTitle mb-2'>
        {utilVar.athleteMonthTitle.title}
      </h3>
      <h4 className='sf-text-bold athleteMonthSubTitle'>
        {utilVar.athleteMonthTitle.subtitle}
      </h4>
    </div>
  );
};

export default AthletesMonthTitle;
