import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signOut } from 'features/Authentication';
import {
  toggleLogout,
  getDidLogoutCancel,
  getDidLogoutSuccess,
  getShowLogout,
  resetLogout
} from 'features/Modal';
import { getCurrentPage } from 'features/Navigation';
import LogoutConfirm from 'components/Modals/LogoutConfirm';
import { resetTeamDataState } from 'features/TeamIds';

const LogOut = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logoutCancel = useSelector(getDidLogoutCancel);
  const logoutSuccess = useSelector(getDidLogoutSuccess);
  const showLogout = useSelector(getShowLogout);
  const currentPage = useSelector(getCurrentPage);

  useEffect(() => {
    if (logoutCancel) {
      // if modal is cancelled by clicking away or No
      currentPage ? history.goBack() : history.push('/');
    } else if (logoutSuccess) {
      // if you press Yes
      dispatch(resetTeamDataState());
      dispatch(resetLogout());
      dispatch(signOut());
      history.push('/');
    } else if (!showLogout) {
      // show logout modal if not there
      dispatch(toggleLogout());
    }
  }, [currentPage, dispatch, history, logoutCancel, logoutSuccess, showLogout]);

  return <LogoutConfirm />;
};

export default LogOut;
