import React from 'react';
import pt from 'prop-types';
import { Link } from 'react-router-dom';
import './SideBarItem.scss';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/sidebar';
import { utilityVariables as utilIcons } from 'utility/icons';

const SideBarItem = ({ itemName, icon, selected, setSelected }) => {
  return (
    <li
      className={`${
        selected === true ? 'selected' : null
      } d-flex align-items-center sf-display-bold pl-3 mb-2`}
    >
      <div className={utilVar.sidebar.classNames.sideBarIconContainer}>
        <img
          src={icon}
          alt={utilIcons.icons[utilFunc.sideBarName(itemName)].alt}
          width={utilIcons.icons[utilFunc.sideBarName(itemName)].width}
          height={utilIcons.icons[utilFunc.sideBarName(itemName)].height}
        />
      </div>
      <Link
        to={`/${utilFunc.linkName(itemName)}`}
        onClick={() => setSelected(utilFunc.linkName(itemName))}
      >
        {itemName}
      </Link>
    </li>
  );
};

SideBarItem.propTypes = {
  itemName: pt.string,
  icon: pt.string,
  selected: pt.bool,
  setSelected: pt.func.isRequired
};

SideBarItem.defaultProps = {
  itemName: 'Temporary Item',
  icon: null,
  selected: false
};

export default SideBarItem;
