import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  athletesGraph: {
    classNames: {
      activeAthletes: utilFunc.joinClassNames([
        'mr-sm-3',
        'mb-sm-0',
        'mb-3',
        'AthletesGraphContainer',
        'barChartContainer',
        'py-3',
        'px-4',
        'bg-white'
      ])
    },
    percentageDiffStyle: { color: 'rgb(6, 155, 217)' },
    header: 'Currently Active Athletes:',
    containerHeight: 163,
    areaChartMargin: { top: 0, right: 18, bottom: 0, left: 18 },
    xAxisDataKey: 'month',
    axisTick: { fontSize: 10 },
    areaType: 'monotone',
    areaDataKey: 'activeAthletes',
    areaStroke: 'none',
    areaFill: 'url(#greenGradient)'
  }
};
