import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  topModules: {
    classNames: {
      container: utilFunc.joinClassNames([
        'd-flex',
        'flex-column',
        'justify-content-between',
        'py-3',
        'px-4',
        'top-exercises-container'
      ]),
      footer: utilFunc.joinClassNames([
        'd-flex',
        'justify-content-end',
        'sf-display-regular',
        'mt-3',
        'text-right',
        'text-white'
      ])
    },
    modules: 'Top Modules',
    players: 'Players',
    footer: 'Last 30 Days'
  }
};
