import React from 'react';
import { useHistory } from 'react-router-dom';
import { utilityVariables as util404 } from 'utility/404';

const ErrorPage = () => {
  const history = useHistory();

  const handleClick = () => history.goBack();

  return (
    <div className='h-100 d-flex justify-content-center align-items-center'>
      <div className='loginFlowBG' />
      <div className='d-flex flex-column align-items-center'>
        <h1 className='font-large'>{util404[404].title}</h1>
        <h2>{util404[404].body}</h2>
        <button
          className='loginFormSubmit sf-display-bold editAccount my-3'
          onClick={handleClick}
        >
          {util404[404].buttonText}
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
