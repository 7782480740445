import React from 'react';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { utilityVariables as utilVar } from 'utility/modal';
import pt from 'prop-types';

const MediumModalHeader = ({ icon, alt, title }) => {
  return (
    <ModalHeader className='border-bottom-0' closeButton>
      <ModalTitle>
        <span className='mr-2'>
          <img
            src={icon}
            alt={alt}
            height={utilVar.modal.modalIconDimensions.iconHeight}
            width={utilVar.modal.modalIconDimensions.iconWidth}
          />
        </span>
        <h3 className='sf-display-bold mb-0'>{title}</h3>
      </ModalTitle>
    </ModalHeader>
  );
};

MediumModalHeader.propTypes = {
  icon: pt.string.isRequired,
  alt: pt.string.isRequired,
  title: pt.string
};

MediumModalHeader.defaultProps = {
  title: ''
};

export default MediumModalHeader;
