import React from 'react';
import sortUp from 'assets/Sorting up.png';
import sortDown from 'assets/Sorting down.png';

export const utilityFunctions = {
  actionTableSelectColumn: (isSorted, isDesc) => {
    return isSorted ? (
      isDesc ? (
        <img
          className='ml-2'
          src={sortDown}
          alt='sort down'
          width={utilityVariables.icons.sortingArrows.width}
          height={utilityVariables.icons.sortingArrows.height}
        />
      ) : (
        <img
          className='ml-2'
          src={sortUp}
          alt='sort up'
          width={utilityVariables.icons.sortingArrows.width}
          height={utilityVariables.icons.sortingArrows.height}
        />
      )
    ) : (
      ''
    );
  }
};

export const utilityVariables = {
  icons: {
    athletes: {
      alt: 'athletes icon',
      width: 11,
      height: 12
    },
    dashboard: {
      alt: 'dashboard icon',
      width: 10,
      height: 10
    },
    settings: {
      alt: 'settings icon',
      width: 9,
      height: 9
    },
    support: {
      alt: 'support icon',
      width: 5,
      height: 9
    },
    logout: {
      alt: 'logout icon',
      width: 9,
      height: 8
    },
    closeHamburger: {
      width: 20,
      height: 20
    },
    sortingArrows: {
      width: 11,
      height: 6
    },
    clubLogo: {
      width: 36,
      height: 36
    }
  }
};
