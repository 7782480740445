import React from 'react';
import { utilityVariables as utilVar } from 'utility/settings';

const SettingsHeader = () => {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-column flex-sm-row'>
        <h2 className='sf-display-bold dashboard-title mr-auto'>
          {utilVar.settings.title}
        </h2>
      </div>
      <hr className='mt-2' />
    </div>
  );
};

export default SettingsHeader;
