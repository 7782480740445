import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  hamburger: {
    classNames: utilFunc.joinClassNames([
      'hamburger',
      'd-flex',
      'flex-column',
      'justify-content-center',
      'align-items-center',
      'mr-2'
    ])
  }
};
