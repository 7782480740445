import React from 'react';
import { useHistory } from 'react-router';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import { utilityVariables as utilOnboard } from 'utility/postOnboarding';

const ThankYou = () => {
  const history = useHistory();

  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <div className='loginFlowBG' />
      <div className='d-flex flex-column align-items-center mx-3'>
        <LogoTitle />
        <h1>Welcome to ProInstinct!</h1>
        <div style={{
          height: '0.75rem'
        }} />
        <h3>{utilOnboard.thankYou.approvalTextTop}</h3>
        <h3>{utilOnboard.thankYou.approvalTextBottom}</h3>
      </div>
    </div>
  );
};

export default ThankYou;
