import React from 'react';
import pt from 'prop-types';
//prettier-ignore
import { 
  utilityVariables as utilVar 
} from 'utility/dashboard/dateSelectionButton';
import chevronLeft from 'assets/Chevron Left.png';
import chevronRight from 'assets/Chevron Right.png';
import './DateSelectionButton.scss';

const DateSelectionButton = ({
  id,
  handleDateSelection,
  direction,
  disabled
}) => {
  const arrowDirection = direction =>
    direction === 'left' ? (
      <img
        src={chevronLeft}
        alt='chevron left'
        width={utilVar.dateSelectionButton.chevron.width}
        height={utilVar.dateSelectionButton.chevron.height}
      />
    ) : (
      <img
        src={chevronRight}
        alt='chevron right'
        width={utilVar.dateSelectionButton.chevron.width}
        height={utilVar.dateSelectionButton.chevron.height}
      />
    );

  return (
    <button
      id={id}
      className={utilVar.dateSelectionButton.classNames.dateSelection}
      onClick={handleDateSelection}
      disabled={disabled}
    >
      {arrowDirection(direction)}
    </button>
  );
};

DateSelectionButton.propTypes = {
  id: pt.string,
  handleDateSelection: pt.func.isRequired,
  direction: pt.string,
  disabled: pt.bool
};

DateSelectionButton.defaultProps = {
  id: 'dateAdd',
  direction: 'right',
  disabled: false
};

export default DateSelectionButton;
