import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  inputField: {
    classNames: utilFunc.joinClassNames([
      'modal-field',
      'rounded',
      'sf-text-bold'
    ])
  }
};
