import React from 'react';
import { utilityVariables as utilVar } from 'utility/settings';

const Footer = () => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <a href={`${process.env.REACT_APP_PRIVACY}`}>
        <s-1 class='sf-text-regular'>{utilVar.settings.footer.privacy}</s-1>
      </a>
      <a href={`${process.env.REACT_APP_TERMS}`}>
        <s-1 class='sf-text-regular'>{utilVar.settings.footer.terms}</s-1>
      </a>
    </div>
  );
};

export default Footer;
