import React, { useEffect, useState } from 'react';
import { utilityVariables as utilVar } from 'utility/login/loginFlow';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import LoginFlowForm from 'components/LoginFlow/LoginFlowForm';
import { useHistory } from 'react-router';

const SignUpNoConsent = () => {
  const [spin, setSpin] = useState(false);
  const [createUserJSON, setCreateUserJSON] = useState({
    'parentEmail': '',
    'firstName': '',
    'lastName': '',
    'dateOfBirth': '2000-08-10',
    'inviteCode': '',
    'username': '',
    'email': '',
    'teamId': 1,
    'password': ''
  });
  const history = useHistory();
  const code = localStorage.getItem('onboardCode');
  const isUnderage = localStorage.getItem('onboardIsUnderage');
  const teamId = localStorage.getItem('onboardTeamIds');

  useEffect(() => {
    if (!code) {
      alert('Please input a valid sign up code first.');
      localStorage.clear();
      history.push('/');
    } else {
      setCreateUserJSON({ 
        ...createUserJSON,
        teamId: parseInt(teamId),
        dateOfBirth: isUnderage ? '2010-08-10' : '2000-08-10',
        parentEmail: '',
        inviteCode: localStorage.getItem('onboardCode')
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  }, [createUserJSON]);

  return (
    <>
      <div className='loginFlowBG' />
        <div className={utilVar.loginFlow.classNames}>
          <div className='signUpFlowContainer'>
            <LogoTitle />
            <LoginFlowForm
              initialValues={utilVar.loginFlow.signUpNoConsent.initialValues}
              schema={utilVar.loginFlow.signUpNoConsent.validation}
              spin={spin}
              setSpin={setSpin}
              loginFlowFields={utilVar.loginFlow.signUpNoConsent.fields}
              submissionLabel={BTN_LOCALIZATIONS['en'].SIGNUP}
              navigateTo={utilVar.loginFlow.signUpNoConsent.navigateTo}
              createUserJSON={createUserJSON}
            />
        </div>
      </div>
    </>
  );
};

export default SignUpNoConsent;
