import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, Tabs, Tab, DropdownButton, Dropdown } from 'react-bootstrap';
// import AddUser from '../Modals/AddUser';
// import DeleteUser from '../Modals/DeleteUser';
// import EditUser from '../Modals/EditUser';
// import AddEntry from '../AddEntry';
// import {
//   toggleAddAthlete,
//   toggleDeleteAthlete,
//   toggleEditAthlete
// } from 'features/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectConvertedClubUsers } from 'features/Athletes/AthletesSelectors';
import { getClubInfo, getFetchingClubInfo } from 'features/Settings';

import {
  fetchClubTeams,
  getClubUsers,
  getClubTeams,
  getFetchingAthletes,
  getFetchClubTeams,
  getDidFetchFail,
  getDidFetchTeamsFail,
  fetchClubAthletes
} from 'features/Athletes';
import { signOut } from 'features/Authentication';
import { utilityFunctions as utilFunc } from 'utility';
// import { utilityVariables as utilVar } from 'utility/athletes';
// import { utilityVariables as utilModal } from 'utility/modal';
// import { utilityVariables as utilPermissions } from 'utility/permissions';
import { utilityVariables as utilMessages } from 'utility/messages';
import { getTokenAuthentic } from 'features/API';
import InviteAthletes from './InviteAthletes';
import { getTeamData, getTeams } from 'features/TeamIds';
import MatTable from 'utility/materialUI';
import proInstinctAPI from 'proInstinctAPI';

const AthletesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clubUsers = useSelector(getClubUsers);
  const teamData = useSelector(getTeamData);
  // const convertedClubUsers = useSelector(selectConvertedClubUsers);
  const teams = useSelector(getClubTeams);
  const [selectedTeam, setSelectedTeam] = useState(localStorage.getItem('clubId'));

  const fetchingAthletes = useSelector(getFetchingAthletes);
  const fetchingTeams = useSelector(getFetchClubTeams);
  const clubInfo = useSelector(getClubInfo);
  const fetchingClubInfo = useSelector(getFetchingClubInfo);
  const fetchingStatus = [fetchingAthletes, fetchingTeams, fetchingClubInfo];
  const didFetchFail = useSelector(getDidFetchFail);
  const didFetchTeamsFail = useSelector(getDidFetchTeamsFail);
  const authenticToken = useSelector(getTokenAuthentic);
  const permission = localStorage.getItem('permission');

  const [inviteAthleteList, setInviteAthleteList] = useState([]);

  useEffect(() => {
    if (!teamData) {
      dispatch(getTeams());
    }
  }, [dispatch, teamData]);

  useEffect(() => {
    if (!clubUsers) {
      dispatch(
        fetchClubAthletes({
          toFetch: false,
          permission: 'USER',
          clubID: selectedTeam
        })
      );
    }
  }, [dispatch, clubUsers]);

  // for dropdown selection use
  // useEffect(() => {
  //     dispatch(
  //       fetchClubAthletes({
  //         toFetch: false,
  //         permission: 'USER',
  //         clubID: selectedTeam
  //       })
  //     );
  // }, [selectedTeam]);

  useEffect(() => {
    if (!teams.length) {
      dispatch(fetchClubTeams());
    }
  }, [teams, dispatch]);

  useEffect(() => {
    proInstinctAPI.sentOnboardingEmailsList().then(res => {
      const list = res.map(val => {
        let newVal = {};
        if (val.onboardCode.isUnderage) {
          newVal = {
            'athleteName': val.onboardCode.athleteName ? val.onboardCode.athleteName : undefined,
            'parentEmail': val.sentTo ? val.sentTo : undefined,
            // account created
            'accountCreated': val.user ? 'Yes' : 'No',
            // approval status
            'approvalStatus': val.onboardCode.approvalStatus ? 'Approved' : 'Pending',
            // date sent
            'createdAt': val.createdAt ? val.createdAt.slice(0, 10) : null,
            // clicked link
            'openedAt': val.openedAt ? val.openedAt.slice(0, 10) : 'No'
          };
        } else {
          newVal = {
            'athleteName': val.onboardCode.athleteName ? val.onboardCode.athleteName : undefined,
            'parentEmail': 'N/A',
            'accountCreated': val.user ? 'Yes' : 'No',
            'approvalStatus': 'Not required',
            'createdAt': val.createdAt ? val.createdAt.slice(0, 10) : null,
            'openedAt': val.openedAt ? val.openedAt.slice(0, 10) : 'No'
          };
        }
        return newVal;
      });
      setInviteAthleteList(list);
    }); 
  }, []);

  useEffect(() => {
    if (!authenticToken) {
      dispatch(signOut());
      history.push('/');
    }
  }, [authenticToken, dispatch, history]);

  const userTableColumns = [
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'Age', field: 'age' },
    { title: 'Email', field: 'email' },
    { title: 'Parent Email', field: 'parentEmail' }
  ];

  const userTableColumns2 = [
    { title: 'First Name/Initial', field: 'athleteName' },
    { title: 'Parent Email', field: 'parentEmail' },
    { title: 'Date Sent', field: 'createdAt' },
    { title: 'Clicked Link', field: 'openedAt' },
    { title: 'Account Created', field: 'accountCreated' },
    { title: 'Approval Status', field: 'approvalStatus' }
  ];

  // const toggleAthleteModal = () => dispatch(toggleAddAthlete());
  // const toggleDeleteModal = () => dispatch(toggleDeleteAthlete());
  // const toggleEditModal = () => dispatch(toggleEditAthlete());

  const memoizedElement = useMemo(() => {
    return (<MatTable
      title='Current Athletes List'
      columns={userTableColumns}
      data={fetchingAthletes ? null : clubUsers.map(user => ({ ...user }))}
      options={{
        paging:true,
        pageSize:10,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions:[10, 15, 20]    // rows selection options
      }}
    />);
  }, [clubUsers]);

  const memoizedElement2 = useMemo(() => {
    return (<MatTable
      title='Invited Athletes List'
      columns={userTableColumns2}
      data={inviteAthleteList}
      options={{
        paging:true,
        pageSize:10,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions:[10, 15, 20]    // rows selection options
      }}
    />);
  }, [inviteAthleteList]);

  function onClick(v) {
    setSelectedTeam(parseInt(v));
  }

  function toTeamName(v) {
    if(v === 0) {
     return 'All Teams';
    }
    let item = teams.filter(function (t) {
      return t.value === v;
    });

    return item[0].label;
  }

  return (
    <div className='text-center'>
      {utilFunc.checkLoading(fetchingStatus) ? (
        <Spinner animation='border' variant='primary' />
      ) : didFetchTeamsFail ? (
        <h1>{utilMessages.errors.errorFetchAthletes}</h1>
      ) : (
        <div>
          {/* Dropdown for SITE_ADMIN to filter by user */}
          {/* {(permission === 'SITE_ADMIN') ? <DropdownButton align='start' id='dropdown-basic-button' title='Team' >
            {teams.map(team => <Dropdown.Item eventKey={`${team.value}`} key={team.value} onSelect={onClick}>{team.label}</Dropdown.Item>)}
            {[{ value: 0, label: 0 }, { value: 1, label: 1 }, { value: 2, label: 2 }].map(team => <Dropdown.Item eventKey={`${team.value}`} key={team.value} onSelect={onClick}>{team.label}</Dropdown.Item>)}
          </DropdownButton> : <div/>} */}
          <h1>{clubInfo.name}</h1>
          {/* <h1>Club {selectedTeam}</h1> */}
          <Tabs defaultActiveKey='athletePage' id='uncontrolled-tab-example'>
            <Tab eventKey='athletePage' title='Current Athletes'>
              <>
                {/* <AddUser toggleAthleteModal={toggleAthleteModal} />
                <DeleteUser
                  toggleDeleteModal={toggleDeleteModal}
                  permission={utilPermissions.permissionMapping.api.USER}
                />
                <EditUser
                  toggleEditModal={toggleEditModal}
                  clubUsers={convertedClubUsers}
                  initialValues={utilModal.modal.editUser.athlete.initial}
                  validationSchema={utilModal.validateUser}
                  onSubmit={utilModal.modal.editUser.athlete.onSubmit}
                  editType={utilPermissions.permissionMapping.ATHLETE}
                  permission={utilPermissions.permissionMapping.api.USER}
                />
                <AddEntry action={toggleAthleteModal} /> */}
                {didFetchFail ? (
                  <b-1 class='sf-display-regular'>
                    {utilMessages.warnings.noAthletesFound}
                  </b-1>
                ) : 
                  memoizedElement}
              </>
            </Tab>
            <Tab eventKey='sentInvitePage' title='Invited Athtetes'>
              <>
                {didFetchFail ? (
                  <b-1 class='sf-display-regular'>
                    {utilMessages.warnings.noAthletesFound}
                  </b-1>
                ) : 
                  memoizedElement2}
              </>
            </Tab>
            <Tab
              eventKey='inviteAthlete'
              title='Onboard Athletes'
              disabled={permission !== 'SITE_ADMIN'}
            >
              <InviteAthletes />
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default AthletesPage;
