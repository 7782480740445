import React, { useEffect, useState } from 'react';
import LoginFlowSpinner from 'components/LoginFlow/LoginFlowSpinner';
import { useHistory } from 'react-router-dom';
import proInstinctAPI from 'proInstinctAPI';
import LogoTitle from 'components/LoginFlow/LogoTitle';
import Checkmark_circle from 'assets/Checkmark_circle.png';
import { Row } from 'react-bootstrap';

const JotformRedirect = () => {
  const [spin, setSpin] = useState(true);
  const history = useHistory();

  const isUnderage = localStorage.getItem('onboardIsUnderage');
  const code = localStorage.getItem('onboardCode');

  useEffect(() => {
    proInstinctAPI.verifySubmission(code).then(val => {
      setTimeout(() => {
        if (val === undefined || val === null || val.length === 0) {
          // response recorded but didn't receive form
          // alert('We did not receive a valid consent form from you.');
          if (code) {
            history.push(`/accessCode/${code}`);
          } else {
            localStorage.clear();
            history.push('/approvalRedirect');
          }
        } else {
          if (isUnderage) {
            // eslint-disable-next-line max-len
            localStorage.setItem('onboardFirstName', val[val.length - 1].firstAthlete.firstName);
            // eslint-disable-next-line max-len
            localStorage.setItem('onboardLastName', val[val.length - 1].firstAthlete.lastName);
            // eslint-disable-next-line max-len
            localStorage.setItem('onboardParentEmail', val[val.length - 1].parent_email);
            
            history.push('/signUp');
          } else {
            history.push('/signUpNoConsent');
          }
        }
      }, 5000);
      setSpin(false);
    }).catch(e => {
      // response not found (code error or API error)
      console.error(e);
      alert('Internal Error.');
      localStorage.clear();
      history.push('/');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <div className={'h-100 d-flex align-items-center justify-content-center'}>
        <div className='loginFlowBG'/>
        <div className='d-flex flex-column align-items-center mx-3'>
          <LogoTitle />
          <Row style={{
            marginBottom: '2rem'
          }}>
            <h2><img
              src={Checkmark_circle}
              width={30}
              height={30}
              alt={'checkmarkShield'}
            /> Your consent form has been received!</h2> 
          </Row>
          <h3>Please wait while we redirect you to our sign up page.</h3>
          <LoginFlowSpinner spin={spin} />
        </div>
      </div>
    </>
  );
};

export default JotformRedirect;
