import React from 'react';
import { Spinner } from 'react-bootstrap';
import pt from 'prop-types';

const LoginFlowSpinner = ({ show }) => {
  return (
    <div
      className={`loginFlowSpinner text-center py-3 ${
        show ? 'visible' : 'invisible'
      }`}
    >
      <Spinner animation='border' variant='primary' />
    </div>
  );
};

LoginFlowSpinner.propTypes = {
  show: pt.bool.isRequired
};

export default LoginFlowSpinner;
