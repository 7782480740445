import React from 'react';
import pt from 'prop-types';
import AddIcon from 'assets/add@3x.png';
import { utilityVariables as utilVar } from 'utility/athletes/addEntry';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import './AddEntry.scss';

const AddEntry = ({ action }) => {
  return (
    <div className={utilVar.addEntry.classNames.addContainer}>
      <button
        className={utilVar.addEntry.classNames.addButton}
        onClick={action}
      >
        <bt-1 class='sf-display-bold text-white mr-2'>
          {BTN_LOCALIZATIONS['en'].ADD}
        </bt-1>
        <img
          src={AddIcon}
          alt={utilVar.addEntry.addImgAlt}
          height={utilVar.addEntry.dimensions}
          width={utilVar.addEntry.dimensions}
        />
      </button>
    </div>
  );
};

AddEntry.propTypes = {
  action: pt.func.isRequired
};

export default AddEntry;
