import React from 'react';
import pt from 'prop-types';
import './AdminInfoContainer.scss';

const AdminInfoContainer = ({ children }) => {
  return (
    <div className='d-flex justify-content-center'>
      <div className='adminInfo d-flex flex-column align-items-center'>
        {children}
      </div>
    </div>
  );
};

AdminInfoContainer.propTypes = {
  children: pt.node.isRequired
};

export default AdminInfoContainer;
