import React from 'react';
import pt from 'prop-types';
import { Modal } from 'react-bootstrap';

const IframeModal = ({
    show,
    handleClose,
    titleText,
    bodyJsx,
    footerJsx

}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName='modal-95vw'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='jotform-body'>
              {bodyJsx}
            </Modal.Body>
            {(footerJsx === undefined || footerJsx === null) ? ''
                : <Modal.Footer>{footerJsx}</Modal.Footer>}
          </Modal>
    );
};

IframeModal.propTypes = {
    show: pt.bool.isRequired,
    handleClose: pt.func.isRequired,
    titleText: pt.string.isRequired,
    bodyJsx: pt.any.isRequired,
    footerJsx: pt.any
};

export default IframeModal;
