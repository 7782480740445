export const utilityVariables = {
  barChart: {
    containerHeight: 300,
    maxBarSize: 11,
    margin: { top: 16, right: 0, bottom: 0, left: -30 },
    xAxisDataKey: 'month',
    axisTick: { fontSize: 10 },
    yAxisDomain: [0, dataMax => Math.ceil(dataMax / 100) * 100],
    yAxisPadding: { bottom: 8 },
    barDataKey: 'totalHours',
    barFill: '#826AF9',
    barRadius: [5, 5, 5, 5],
    barName: 'Total Hours'
  }
};
