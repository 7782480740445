import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/settings';
import { BTN_LOCALIZATIONS } from 'utility/buttons';
import { getUpdatingSettings } from 'features/Settings';
import pt from 'prop-types';
import './FieldEdit.scss';

const FieldEdit = ({
  label,
  initialValues,
  schema,
  type,
  fieldName,
  setSettingsEdit,
  onSubmit
}) => {
  const settingsUpdating = useSelector(getUpdatingSettings);

  return settingsUpdating ? (
    <Spinner animation='border' variant='primary' />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={
        type === 'email' ? schema(initialValues.clubEmail) : schema
      }
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setSettingsEdit(null);
        onSubmit(values);
      }}
    >
      {({ errors, setErrors }) => (
        <Form className='d-flex flex-column mb-4'>
          <dh-2 class='text-dark font-weight-bold mb-1 sf-display-regular'>
            {label}
          </dh-2>
          <div className={utilVar.settings.fieldEdit.classNames.formContainer}>
            <Field
              className='fieldStyle border'
              type={type}
              id={fieldName}
              name={fieldName}
            />
            <div className='d-md-none d-block'>
              {errors[fieldName] ? (
                <b-1 class='fieldError ml-1 mt-1'>{errors[fieldName]}</b-1>
              ) : null}
            </div>
            <div className={utilVar.settings.fieldEdit.classNames.btnContainer}>
              <button
                className={utilVar.settings.fieldEdit.classNames.confirm}
                type='submit'
                onClick={() => utilFunc.resetErrors(setErrors)}
              >
                <bt-2>{BTN_LOCALIZATIONS.en.SAVE}</bt-2>
              </button>
              <button
                className={utilVar.settings.fieldEdit.classNames.cancel}
                type='reset'
                onClick={() => setSettingsEdit(null)}
              >
                <bt-2>{BTN_LOCALIZATIONS.en.CANCEL}</bt-2>
              </button>
            </div>
          </div>
          <div className='d-none d-md-block'>
            {errors[fieldName] ? (
              <b-1 class='fieldError ml-1 mt-1'>{errors[fieldName]}</b-1>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

FieldEdit.propTypes = {
  label: pt.string,
  initialValues: pt.object.isRequired,
  schema: pt.object.isRequired,
  type: pt.string,
  fieldName: pt.string.isRequired,
  setSettingsEdit: pt.func.isRequired,
  onSubmit: pt.func.isRequired
};

FieldEdit.defaultProps = {
  label: '',
  type: 'text'
};

export default FieldEdit;
