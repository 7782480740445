import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  login: {
    logoWidth: 150,
    logoHeight: 150,
    initialValues: {
      loginEmail: '',
      loginPassword: ''
    },
    footer: {
      forgotPassword: 'Forgot Password?',
      forgotPasswordRedirect: '/forgotPassword',
      // prettier-ignore
      signUpText: 'Have an invite code? ',
      signUpLinkText: 'Sign up',
      signUpRedirect: '/accessCode'
    },
    classNames: {
      loginFieldContainer: utilFunc.joinClassNames([
        'd-flex',
        'h-50',
        'w-100',
        'justify-content-center',
        'align-items-center'
      ]),
      loginFieldFormContainer: utilFunc.joinClassNames([
        'inputFormLogin',
        'd-flex',
        'flex-column',
        'align-items-end',
        'mt-4',
        'mb-2'
      ]),
      loginIconContainer: utilFunc.joinClassNames([
        'w-25',
        'h-100',
        'd-flex',
        'justify-content-center',
        'align-items-center'
      ]),
      loginIcon: utilFunc.joinClassNames([
        'iconContainer',
        'd-flex',
        'justify-content-center',
        'align-items-center'
      ]),
      loginLinks: utilFunc.joinClassNames([
        'mt-5',
        'd-flex',
        'flex-column',
        'loginLinkContainer',
        'loginFlow-sf-xs',
        'sf-text-regular'
      ]),
      loginForm: utilFunc.joinClassNames([
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center'
      ]),
      formikContainer: utilFunc.joinClassNames([
        'w-100',
        'h-100',
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center'
      ])
    }
  }
};
