import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  dashboardMonthPicker: {
    classNames: {
      monthPickerButton: utilFunc.joinClassNames([
        'DateRangePickerInput_calendarIcon',
        'DateRangePickerInput_calendarIcon_1',
        'h-100',
        'd-flex',
        'align-items-center'
      ])
    },
    monthPickerDropdown: {
      alt: 'arrow down',
      height: 6,
      width: 11
    },
    monthDisplay: 'MMM YYYY'
  }
};
