import ValidInvitation from 'components/ConfirmInvitation';
import EmailConfirmation from 'components/ForgotPassword/EmailConfirmation';
import InviteAthlete from 'components/InviteAthlete';
import PostOnboarding from 'components/PostOnboarding';
import ResetConfirmation from 'components/ResetPassword/ResetConfirmation';
import ResetPasswordForm from 'components/ResetPassword/ResetPasswordForm';
import SignUp from 'components/SignUp';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import VerifyEmail from './components/ForgotPassword/VerifyEmail';
import Login from './components/Login';
import Navigation from './components/Navigation';
import PrivateRoute from './components/PrivateRoute';
import ErrorPage from 'components/404';
import './App.scss';
import AccessCode from 'components/SignUp/AccessCode';
import Over16 from 'components/SignUp/Over16';
import SignUpNoConsent from 'components/SignUp/Over16';
import JotformRedirect from 'components/SignUp/Redirect';
import ThankYou from 'components/ThankYou';
import ApprovalRedirect from 'components/ApprovalRedirect';

const App = () => {
  return (
    <div className='App'>
      <Switch>
        {/* removing /login route completely and replacing it with root route to
        simplify things */}
        <Route
          exact
          path='/'
          render={() =>
            localStorage.getItem('jwt') ? (
              <Redirect to='/dashboard' />
            ) : (
              <Login />
            )
          }
        />
        <Route path='/forgotPassword'>
          <VerifyEmail />
        </Route>
        <Route path='/forgotPasswordConfirmation'>
          <EmailConfirmation />
        </Route>
        <Route path='/resetPassword'>
          <ResetPasswordForm />
        </Route>
        <Route path='/resetPasswordConfirmation'>
          <ResetConfirmation />
        </Route>
        <Route path='/invite/:uuid'>
          <InviteAthlete />
        </Route>
        <Route path='/confirmInvite'>
          <ValidInvitation />
        </Route>
        <Route path='/signUp'>
          <SignUp />
        </Route>
        <Route path='/jotformRedirect'>
          <JotformRedirect />
        </Route>
        <Route path='/approvalRedirect'>
          <ApprovalRedirect />
        </Route>
        <Route path='/signUpNoConsent'>
          <SignUpNoConsent />
        </Route>
        <Route path='/nextSteps'>
          <PostOnboarding />
        </Route>
        <Route path='/thankYou'>
          <ThankYou />
        </Route>
        <Route path='/accessCode'>
          <AccessCode />
          <Switch>
            <Route path='/accesscode/:id'>
              <AccessCode />
            </Route>
          </Switch>
        </Route>
        <Route path='/over16'>
          <Over16 />
        </Route>
        {/* the idea is if we see a route we don't know 
        and we're not logged in, go
        to the error page else go check the private route */}
        <Route
          render={() =>
            localStorage.getItem('jwt') ? (
              <PrivateRoute>
                <Navigation />
              </PrivateRoute>
            ) : (
              <ErrorPage />
            )
          }
        />
      </Switch>
    </div>
  );
};

export default App;
