import React from 'react';
import pt from 'prop-types';
import { useHistory } from 'react-router';
import proInstinctAPI from 'proInstinctAPI';

const AccessCodeSubmission = ({
  setSpin,
  setErrors,
  submissionText,
  submission,
  isValid,
  setValidOutput,
  setVal,
  verifiedCode,
  validCode,
  handleShow
}) => {

  const history = useHistory();

  return (
    <button
      disabled={!isValid}
      className='accessCodeSubmit mt-3 sf-display-bold'
      type='submit'
      onClick={() => {
        setSpin(true);
        setValidOutput(validCode, verifiedCode, setVal);
        let verified;

        proInstinctAPI.validateCode(submission).then((async res => {
          setSpin(false);
          verified = res.goodRes;
          if (verified) {
            localStorage.setItem('onboardCode', submission);
            localStorage.setItem('onboardGoodRes', res.goodRes);
            localStorage.setItem('onboardIsUnderage', res.isUnderage);
            localStorage.setItem('onboardTeamIds', res.teamId);
            await proInstinctAPI.markEmailAsOpen(submission);

            // check if we've already gotten a sub
            await proInstinctAPI.verifySubmission(submission).then(val => {
              if (!(val === undefined || val === null || val.length === 0)) {
                if (res.isUnderage) {
                  localStorage.setItem(
                    'onboardFirstName', val[val.length - 1].firstAthlete.firstName
                  );
                  localStorage.setItem(
                    'onboardLastName', val[val.length - 1].firstAthlete.lastName
                  );
                  localStorage.setItem(
                    'onboardParentEmail', val[val.length - 1].parent_email
                  );

                  history.push('/signUp');
                } else {
                  history.push('/signUpNoConsent');
                }
              }
            }).catch(e => {
              // response not found (code error or API error)
              console.error(e);
            });

            if (res.isUnderage) {
              handleShow();
            } else {
              history.push('/signUpNoConsent'); // navigateTo
            }
          } else {
            setErrors('Incorrect access code.');
            setTimeout(() => {
              setErrors('');
            }, 3000);
          }
        }));
        // utilFunc.resetErrors(setErrors);
      }}
    >
      <bt-2>{submissionText}</bt-2>
    </button>
  );
};

AccessCodeSubmission.propTypes = {
  setSpin: pt.func,
  setErrors: pt.func,
  submissionText: pt.string.isRequired,
  submission: pt.string.isRequired,
  isValid: pt.bool.isRequired,
  setValidOutput: pt.func.isRequired,
  setVal: pt.func.isRequired,
  verifiedCode: pt.bool.isRequired,
  validCode: pt.bool.isRequired,
  handleShow: pt.func.isRequired
};

export default AccessCodeSubmission;
