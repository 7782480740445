import { combineReducers } from 'redux';
import dateChangeReducer from 'features/DateRange';
import promptReducer from 'features/Prompt';
import authenticationReducer from 'features/Authentication';
import modalReducer from 'features/Modal';
import athleteReducer from 'features/Athletes';
import dashboardReducer from 'features/Dashboard';
import navigationReducer from 'features/Navigation';
import inviteReducer from 'features/Invite';
import settingsReducer from 'features/Settings';
import apiReducer from 'features/API';
import teamIdsReducer from 'features/TeamIds';

const appReducer = combineReducers({
  dateChange: dateChangeReducer,
  prompt: promptReducer,
  // Server caching
  authentication: authenticationReducer,
  modal: modalReducer,
  // Caching state from a server
  athletes: athleteReducer,
  dashboard: dashboardReducer,
  // UI state
  navigation: navigationReducer,
  invite: inviteReducer,
  // Complex data management on the client
  settings: settingsReducer,
  api: apiReducer,
  teamIds: teamIdsReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'authentication/signOut') {
    state = {
      api: state.api
    };
  }

  return appReducer(state, action);
};
