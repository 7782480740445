import { utilityFunctions as utilFunc } from 'utility';

export const utilityVariables = {
  sidebar: {
    classNames: {
      clubHeadingContainer: utilFunc.joinClassNames([
        'clubHeading',
        'd-flex',
        'justify-content-center',
        'align-items-end',
        'w-100',
        'container',
        'mb-4',
        'px-0'
      ]),
      clubLogo: utilFunc.joinClassNames([
        'col-3',
        'pr-0',
        'd-flex',
        'justify-content-center',
        'align-items-center'
      ]),
      clubName: utilFunc.joinClassNames([
        'col-9',
        'd-flex',
        'pr-0',
        'pl-2',
        'flex-column',
        'justify-content-center',
        'text-white'
      ]),
      sideBarIconContainer: utilFunc.joinClassNames([
        'sideBarImgContainer',
        'd-flex',
        'justify-content-center',
        'align-items-center',
        'mr-1',
        'h-100'
      ])
    },
    clubInfoGreeting: 'Hello',
    clubInfoAthlete: 'Athlete',
    clubInfoAthletes: 'Athletes',
    clubInfoActives: 'ACTIVES',
    clubLogoAlt: 'club logo',
    poweredBy: 'Powered by Preception',
    version: 'v0.0.01',
    creditsLogo: {
      alt: 'credits logo',
      height: 16,
      width: 15
    }
  }
};
