import React from 'react';
import ModalFooter from 'react-bootstrap/ModalFooter';
import MediumModalButton from '../MediumModalButton';
import { utilityVariables as utilVar } from 'utility/modal';
import pt from 'prop-types';

const MediumModalFooter = ({ onCancel, onSubmit, cancelText, confirmText }) => {
  return (
    <ModalFooter>
      <div className={utilVar.modal.mediumModalFooter.classNames.container}>
        <MediumModalButton
          className={utilVar.modal.mediumModalFooter.classNames.cancel}
          handleClick={onCancel}
          type={'reset'}
          buttonText={cancelText}
        />
        <MediumModalButton
          className={utilVar.modal.mediumModalFooter.classNames.confirm}
          handleClick={onSubmit}
          type={'submit'}
          buttonText={confirmText}
        />
      </div>
    </ModalFooter>
  );
};

MediumModalFooter.propTypes = {
  onCancel: pt.func.isRequired,
  onSubmit: pt.func.isRequired,
  cancelText: pt.string,
  confirmText: pt.string
};

MediumModalFooter.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm'
};

export default MediumModalFooter;
