export const utilityVariables = {
  support: {
    title: 'Looking for Help?',
    // prettier-ignore
    // eslint-disable-next-line max-len
    body: `If you have any questions or need help, reach out to\n${process.env.REACT_APP_SUPPORT_EMAIL}`,
    clipboard: '(Copy to clipboard)',
    email: process.env.REACT_APP_SUPPORT_EMAIL,
    clipboardSuccess: 'Successfully Copied',
    clipboardTimeout: 1500,
    logo: {
      width: 234,
      height: 82,
      alt: 'supportImg'
    }
  }
};
