import React from 'react';
import { utilityVariables as utilVar } from 'utility';
import { utilityVariables as utilLogin } from 'utility/login';
import { utilityVariables as utilLoginFlow } from 'utility/login/loginFlow';
import logo from 'assets/whiteProInstinct.png';

const LogoTitle = () => {
  return (
    <div className='d-flex flex-column align-items-center'>
      <img
        src={logo}
        alt={utilLoginFlow.loginFlow.logoTitle.logoAlt}
        width={utilLogin.login.logoWidth}
        height={utilLogin.login.logoHeight}
      />
      <h1 className='mb-4 sf-display-bold text-white'>{utilVar.appTitle}</h1>
    </div>
  );
};

export default LogoTitle;
