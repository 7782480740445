import React from 'react';
import PasswordField from 'components/Fields/PasswordField';
import pt from 'prop-types';
import './LoginFlowField.scss';
import DateField from 'components/Fields/DateField';
import PhoneField from 'components/Fields/PhoneField';
import SelectField from 'components/Fields/SelectField';
import InputField from 'components/Fields/InputField';
import { utilityVariables as utilVar } from 'utility/fields/phoneField';
import { utilityVariables as utilModal } from 'utility/modal';

const LoginFlowField = ({
  label,
  type,
  fieldName,
  fieldProps,
  error,
  placeholder,
  disabled
}) => {
  const flowField = type => {
    switch (type) {
      case 'password':
        return <PasswordField
            fieldName={fieldName}
        />;
      case 'date':
        return (
          <DateField
            id={`${fieldName}_login_flow`}
            name={fieldName}
            setFieldValue={fieldProps.setFieldValue}
            setParentDisabled={fieldProps.setParentDisabled}
            dob={fieldProps.dob}
            placeholder={utilModal.modal.fields.dob.placeholder}
          />
        );
      case 'phone':
        return (
          <PhoneField
            setValue={fieldProps.setFieldValue}
            className={`fieldStyle ${utilVar.phoneField.classNames.phone}`}
            dropdownBtnClass={`fieldFlagStyle`}
            placeholder={utilModal.modal.fields.mobile.placeholder}
            mobile={fieldProps.mobile}
          />
        );
      case 'select':
        return (
          <SelectField
            setValue={fieldProps.setFieldValue}
            className={fieldProps.className}
            options={fieldProps.teams}
            placeholder={utilModal.modal.fields.team.placeholder}
          />
        );
      default:
        return (
          <InputField
            type={type}
            name={fieldName}
            className='fieldStyle sf-display-regular'
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
          />
        );
    }
  };

  return (
    <div className='field d-flex flex-column ml-2 sf-display-regular'>
      <b-1 class='text-black ml-1 mb-1 fieldText'>{label}</b-1>
      {flowField(type)}
      {<b-1 class='fieldError ml-1 mt-1'>{error ? error : ''}</b-1>}
    </div>
  );
};

LoginFlowField.propTypes = {
  label: pt.string.isRequired,
  type: pt.string.isRequired,
  fieldName: pt.string.isRequired,
  error: pt.string,
  fieldProps: pt.object,
  placeholder: pt.string,
  disabled: pt.bool
};

LoginFlowField.defaultProps = {
  error: '',
  fieldProps: {},
  placeholder: '',
  disabled: false
};

export default LoginFlowField;
