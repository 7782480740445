// have a slice here that checks validity of API calls based on auth (403)
// if (403) then set tokenAuthentic to false
// which we can useSelector on pages that make API calls

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tokenAuthentic: true
};

export const slice = createSlice({
  name: 'API',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setTokenNotAuthentic(state) {
      state.tokenAuthentic = false;
    }
  }
});

export const {
  setTokenNotAuthentic,
  resetState: resetTokenAuthentic
} = slice.actions;

export const getTokenAuthentic = state => state.api.tokenAuthentic;

export default slice.reducer;
