import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showAddAthlete: false,
  showDeleteAthlete: false,
  showEditAthlete: false,
  showLogout: false,
  didLogoutCancel: false,
  didLogoutSucceed: false,
  athleteIndex: null
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleAddAthlete(state) {
      state.showAddAthlete = !state.showAddAthlete;
    },
    toggleDeleteAthlete(state, action) {
      const index = action.payload;
      if (state.showDeleteAthlete) {
        state.athleteIndex = null;
      } else {
        state.athleteIndex = index;
      }

      state.showDeleteAthlete = !state.showDeleteAthlete;
    },
    toggleEditAthlete(state, action) {
      const index = action.payload;
      if (state.showEditAthlete) {
        state.athleteIndex = null;
      } else {
        state.athleteIndex = index;
      }

      state.showEditAthlete = !state.showEditAthlete;
    },
    toggleLogout(state) {
      state.showLogout = true;
    },
    toggleLogoutCancel(state) {
      state.showLogout = false;
      state.didLogoutCancel = true;
    },
    toggleLogoutSuccess(state) {
      state.showLogout = false;
      state.didLogoutSucceed = true;
    },
    resetLogout(state) {
      state.showLogout = false;
      state.didLogoutCancel = false;
      state.didLogoutSucceed = false;
    }
  }
});

export const {
  toggleAddAthlete,
  toggleEditAthlete,
  toggleDeleteAthlete,
  toggleLogout,
  toggleLogoutCancel,
  toggleLogoutSuccess,
  resetLogout
} = modalSlice.actions;

export const getShowAddAthlete = state => state.modal.showAddAthlete;
export const getShowDeleteAthlete = state => state.modal.showDeleteAthlete;
export const getShowEditAthlete = state => state.modal.showEditAthlete;
export const getShowLogout = state => state.modal.showLogout;
export const getDidLogoutCancel = state => state.modal.didLogoutCancel;
export const getDidLogoutSuccess = state => state.modal.didLogoutSucceed;
export const getAthleteIndex = state => state.modal.athleteIndex;

export default modalSlice.reducer;
