import React, { useEffect } from 'react';
import pt from 'prop-types';
import moment from 'moment';
import { detect } from 'detect-browser';
import {
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip
} from 'recharts';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/dashboard/athletesGraph';
import { selectEndDate } from 'features/DateRange/DateRangeSelectors';
import './AthletesGraph.scss';
import { useSelector } from 'react-redux';

const browser = detect();
const { athletePercentageDiff, percentageDifferenceText } = utilFunc;

const ResponsiveAthleteChart = ({ monthlyStats }) => {
  const month = moment().format('MMMM');
  const year = moment().format('YYYY');
  const [data, setData] = [];

  useEffect(() => {
    // formatting monthlyStats so that future months have null values
    // so graph does not link to a 0 value
    let futureMonths = false;
    
    if (monthlyStats[0].year == year) {
      monthlyStats.map(dataPoint => {
        if (dataPoint.month === month) {
          futureMonths = true;
        } else {
          if (futureMonths) {
            dataPoint.activeAthletes = null;
          }
        }
      });
    }
  }, []); 
  
  return (
    <ResponsiveContainer height={utilVar.athletesGraph.containerHeight}>
      <AreaChart
        data={monthlyStats}
        margin={utilVar.athletesGraph.areaChartMargin}
      >
        <defs>
          <linearGradient id='greenGradient' x1='1' y1='1' x2='0' y2='0'>
            <stop offset='0%' stopColor='rgb(6, 155, 217)' stopOpacity={1} />
            <stop offset='100%' stopColor='rgb(1, 242, 176)' stopOpacity={1} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey='month'
          tickLine={false}
          axisLine={false}
          tickFormatter={utilFunc.formatXAxis}
          tick={utilVar.athletesGraph.axisTick}
        />
        <YAxis tickLine={false} hide />
        <Tooltip content={<CustomTooltip data={monthlyStats} />} />
        <Area
          type={utilVar.athletesGraph.areaType}
          dataKey={utilVar.athletesGraph.areaDataKey}
          stroke={utilVar.athletesGraph.areaStroke}
          fill={utilVar.athletesGraph.areaFill}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const StaticAthleteChart = ({ monthlyStats }) => {
  return (
    <AreaChart
      data={monthlyStats}
      margin={utilVar.athletesGraph.areaChartMargin}
      height={200}
      width={600}
    >
      <defs>
        <linearGradient id='greenGradient' x1='1' y1='1' x2='0' y2='0'>
          <stop offset='0%' stopColor='rgb(6, 155, 217)' stopOpacity={1} />
          <stop offset='100%' stopColor='rgb(1, 242, 176)' stopOpacity={1} />
        </linearGradient>
      </defs>
      <XAxis
        dataKey='month'
        tickLine={false}
        axisLine={false}
        tickFormatter={utilFunc.formatXAxis}
        tick={utilVar.athletesGraph.axisTick}
      />
      <YAxis tickLine={false} hide />
      <Tooltip content={<CustomTooltip data={monthlyStats} />} />
      <Area
        type={utilVar.athletesGraph.areaType}
        dataKey={utilVar.athletesGraph.areaDataKey}
        stroke={utilVar.athletesGraph.areaStroke}
        fill={utilVar.athletesGraph.areaFill}
      />
    </AreaChart>
  );
};

const CustomTooltip = ({ active, payload, label, data }) => {
  /***
   * Assumption: Data resets annually
   * If assumption is incorrect then need to update prevMonth
   * when * label === 'Jan' to be previous year Dec
   ***/
  if (active) {
    let percentageDiffAthletes = null;
    let prevMonth;
    let currentMonth = moment(label, 'MMMM').format('MMM');
    let currentMonthAthletes;
    if (payload[0] === undefined) {
      currentMonthAthletes = null;
    } else {
      currentMonthAthletes = payload[0].value;
    }

    // if the month is not January
    if (moment(label, 'MMM').format('M') > 1) {
      //get the previous month in 3 letter format
      prevMonth = moment(label, 'MMM').subtract(1, 'months').format('MMM');
      //find the previous month's athletes
      const prevMonthAthletes = data.find(
        item => moment(item.month, 'MMMM').format('MMM') === prevMonth
      ).activeAthletes;

      //helper function to calculate percentage difference
      percentageDiffAthletes = athletePercentageDiff(
        currentMonthAthletes,
        prevMonthAthletes
      );
    }

    return (
      (currentMonthAthletes == null) ? <div/> : <div className='custom-tooltip'>
        <p>{`${label}: ${currentMonthAthletes}`}</p>
        {percentageDiffAthletes !== null && (
          <p style={utilVar.athletesGraph.percentageDiffStyle} className='desc'>
            {percentageDifferenceText(
              prevMonth,
              currentMonth,
              percentageDiffAthletes
            )}
          </p>
        )}
      </div>
    );
  }

  return null;
};

const AthletesGraph = ({ monthlyStats }) => {
  const selectedDate = useSelector(selectEndDate);
  const currentMonth = moment.unix(selectedDate).format('MMMM');
  const currentNumOfAthletesItem = monthlyStats.filter(
    item => item.month === currentMonth
  )[0];

  return (
    <div className={utilVar.athletesGraph.classNames.activeAthletes}>
      {utilVar.athletesGraph.header && (
        <b-1 class='sf-display-bold'>{utilVar.athletesGraph.header}</b-1>
      )}
      <br />
      {currentNumOfAthletesItem && (
        <t-2 class='sf-display-regular'>
          {currentNumOfAthletesItem.activeAthletes}
        </t-2>
      )}
      {browser.name === 'ie' ? (
        <StaticAthleteChart monthlyStats={monthlyStats} />
      ) : (
        <ResponsiveAthleteChart monthlyStats={monthlyStats} />
      )}
    </div>
  );
};

AthletesGraph.propTypes = {
  monthlyStats: pt.array.isRequired
};

CustomTooltip.propTypes = {
  active: pt.bool,
  payload: pt.array,
  label: pt.string,
  data: pt.array.isRequired
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  label: ''
};

ResponsiveAthleteChart.propTypes = {
  monthlyStats: pt.array.isRequired
};

StaticAthleteChart.propTypes = {
  monthlyStats: pt.array.isRequired
};

export default AthletesGraph;
