import React, { useState, useEffect } from 'react';
import pt from 'prop-types';
import { Formik, Form } from 'formik';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getShowEditAthlete, getAthleteIndex } from 'features/Modal';
import { promptConstants } from 'features/Prompt/PromptConstants';
import { triggerPrompt } from 'features/Prompt/PromptUtility';
import {
  fetchClubAthletes,
  getClubTeams,
  resetAthleteResult,
  getUpdatingAthlete,
  getDidUpdateFail,
  getDidUpdatePass
} from 'features/Athletes';
import { fetchClubInfo, fetchClubAdmins } from 'features/Settings';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilMessages } from 'utility/messages';
import { utilityVariables as utilModal } from 'utility/modal';
import { utilityVariables as utilPermissions } from 'utility/permissions';
// import { utilityVariables as utilInput } from 'utility/fields/inputField';
// import { utilityVariables as utilPhone } from 'utility/fields/phoneField';
import { BTN_LOCALIZATIONS } from 'utility/buttons';

import Modal from 'react-bootstrap/Modal';
import addModalIcon from 'assets/addModal@3x.png';
// import InputField from 'components/Fields/InputField';
// import DateField from 'components/Fields/DateField';
// import PhoneField from 'components/Fields/PhoneField';
// import SelectField from 'components/Fields/SelectField';
import LargeModalHeader from '../LargeModalHeader';
import LargeModalBody from '../LargeModalBody';
import LargeModalFooter from '../LargeModalFooter';
import '../Modal.scss';

const EditUser = ({
  toggleEditModal,
  clubUsers,
  initialValues,
  validationSchema,
  onSubmit,
  editType,
  permission
}) => {
  const dispatch = useDispatch();
  const [parentDisabled, setParentDisabled] = useState(false);

  const showEditAthlete = useSelector(getShowEditAthlete);
  const editAthleteIndex = useSelector(getAthleteIndex);
  // const clubUsers = useSelector(selectConvertedClubUsers);
  const teams = useSelector(getClubTeams);

  const updatingAthlete = useSelector(getUpdatingAthlete);
  const updatingAthleteFailed = useSelector(getDidUpdateFail);
  const updatingAthleteSuccess = useSelector(getDidUpdatePass);

  //spread clubUsers object incase it is undefined
  const editUserFilled = {
    ...clubUsers.find(user => user.id === editAthleteIndex)
  };

  useEffect(() => {
    utilFunc.validateAge(editUserFilled.dob)
      ? setParentDisabled(true)
      : setParentDisabled(false);
  }, [editUserFilled.dob]);

  useEffect(() => {
    if (updatingAthleteFailed) {
      dispatch(resetAthleteResult());
      triggerPrompt(
        utilMessages.errors.athleteUpdateFailed,
        promptConstants.danger,
        dispatch
      );
      toggleEditModal();
    } else if (updatingAthleteSuccess) {
      dispatch(resetAthleteResult());
      triggerPrompt(
        utilMessages.success.athleteUpdated,
        promptConstants.success,
        dispatch
      );

      if (permission === utilPermissions.permissionMapping.api.USER) {
        dispatch(fetchClubAthletes({ toFetch: true, permission }));
      } else {
        dispatch(fetchClubAdmins({ toFetch: true, permission }));
        dispatch(fetchClubInfo(true));
      }
      toggleEditModal();
    }
  }, [
    updatingAthleteSuccess,
    updatingAthleteFailed,
    dispatch,
    toggleEditModal,
    permission
  ]);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...editUserFilled
      }}
      validationSchema={validationSchema(
        editUserFilled.athleteEmail || editUserFilled.adminEmail
      )}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => onSubmit(values, dispatch, parentDisabled)}
    >
      {({ errors, setErrors, setFieldValue, values }) => {
        const editUserFields = utilModal.modal.editUser[editType].fields(
          setFieldValue,
          values,
          setParentDisabled,
          parentDisabled,
          editUserFilled,
          teams
        );

        return (
          <Modal show={showEditAthlete} onHide={toggleEditModal} size='xl'>
            <Form>
              <LargeModalHeader
                icon={addModalIcon}
                alt={utilModal.modal.editUser.modalHeaderAlt}
                title={utilModal.modal.editUser.title}
              />
              <LargeModalBody fields={editUserFields} errors={errors} />
              {updatingAthlete ? (
                <div className='d-flex justify-content-center mb-3'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : null}
              <LargeModalFooter
                setParentDisabled={setParentDisabled}
                toggleModal={toggleEditModal}
                cancelText={BTN_LOCALIZATIONS['en'].CANCEL}
                setErrors={setErrors}
                confirmText={BTN_LOCALIZATIONS['en'].SAVE}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

EditUser.propTypes = {
  toggleEditModal: pt.func.isRequired,
  clubUsers: pt.array.isRequired,
  initialValues: pt.object.isRequired,
  validationSchema: pt.func.isRequired,
  onSubmit: pt.func.isRequired,
  editType: pt.string,
  permission: pt.string.isRequired
};

EditUser.defaultProps = {
  editType: 'athlete'
};

export default EditUser;
