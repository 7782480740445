import React from 'react';
import { Field } from 'formik';
import pt from 'prop-types';
import './InputField.scss';

const InputField = ({ type, disabled, name, placeholder, className }) => {
  return (
    <Field
      className={`${className} ${
        disabled ? 'inputFieldDisabled no-hover' : 'bg-white'
      }`}
      type={type}
      id={name}
      name={name}
      autoComplete={name}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

InputField.propTypes = {
  className: pt.string,
  type: pt.string,
  disabled: pt.bool,
  name: pt.string,
  placeholder: pt.string
};

InputField.defaultProps = {
  className: '',
  type: null,
  disabled: false,
  name: null,
  placeholder: 'placeholder'
};

export default InputField;
