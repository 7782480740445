import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proInstinctAPI from 'proInstinctAPI';

export const fetchClubAthletes = createAsyncThunk(
  'athletes/fetchClubAthletes',
  async ({ toFetch, permission, clubID }) => {
    const { club } = await proInstinctAPI.fetchClub(toFetch, permission, clubID);
    return club;
  }
);

export const fetchClubTeams = createAsyncThunk(
  'athletes/fetchClubTeams',
  async () => {
    const { teams } = await proInstinctAPI.fetchClubTeams();
    return teams;
  }
);

export const addClubUser = createAsyncThunk(
  'athletes/addClubUser',
  async (userPayload, { rejectWithValue }) => {
    const addedAthlete = await proInstinctAPI.addClubUser(
      userPayload,
      rejectWithValue
    );
    return addedAthlete;
  }
);

export const sendUserInvite = createAsyncThunk(
  'athletes/inviteClubUser',
  async (inviteData, handleShow=()=>{}) => {
    await proInstinctAPI.onboardInvite(inviteData);
  }
);

export const updateClubUser = createAsyncThunk(
  'athletes/updateClubUser',
  async (userPayload, { rejectWithValue }) =>
    await proInstinctAPI.updateClubUser(userPayload, rejectWithValue)
);

export const deleteClubUser = createAsyncThunk(
  'athletes/deleteClubUser',
  async (userId, { rejectWithValue }) =>
    await proInstinctAPI.deleteClubUser(userId, rejectWithValue)
);

const initialState = {
  club: null,
  teams: [],
  fetchingAthletes: true,
  didFetchFail: false,
  fetchingTeams: true,
  didFetchTeamsFail: false,
  updatingAthlete: false,
  didUpdateFail: false,
  didUpdatePass: false,
  deletingAthlete: false,
  didDeleteFail: false,
  didDeletePass: false,
  addedAthlete: {},
  addingAthlete: false,
  didAddFail: false,
  didAddPass: false
};

export const slice = createSlice({
  name: 'athletes',
  initialState,
  reducers: {
    resetAthleteResult(state) {
      state.didFetchFail = false;
      state.didUpdateFail = false;
      state.didUpdatePass = false;
      state.didDeleteFail = false;
      state.didDeletePass = false;
      state.didAddFail = false;
      state.didAddPass = false;
    }
  },
  extraReducers: {
    [fetchClubAthletes.fulfilled]: (state, action) => {
      state.club = action.payload;
      state.fetchingAthletes = false;
    },
    [fetchClubAthletes.pending]: state => {
      state.fetchingAthletes = true;
    },
    [fetchClubAthletes.rejected]: state => {
      state.club = {};
      state.fetchingAthletes = false;
      state.didFetchFail = true;
    },
    [fetchClubTeams.fulfilled]: (state, action) => {
      state.teams = action.payload;
      state.fetchingTeams = false;
    },
    [fetchClubTeams.pending]: state => {
      state.fetchingTeams = true;
    },
    [fetchClubTeams.rejected]: state => {
      state.teams = [];
      state.fetchingTeams = false;
      state.didFetchTeamsFail = true;
    },
    [addClubUser.fulfilled]: (state, action) => {
      const { addedAthlete } = action.payload;
      state.addedAthlete = addedAthlete;
      state.addingAthlete = false;
      state.didAddPass = true;
    },
    [addClubUser.pending]: state => {
      state.addingAthlete = true;
    },
    [addClubUser.rejected]: state => {
      state.addingAthlete = false;
      state.didAddFail = true;
    },
    [updateClubUser.fulfilled]: state => {
      state.updatingAthlete = false;
      state.didUpdatePass = true;
    },
    [updateClubUser.pending]: state => {
      state.updatingAthlete = true;
    },
    [updateClubUser.rejected]: state => {
      state.updatingAthlete = false;
      state.didUpdateFail = true;
    },
    [deleteClubUser.fulfilled]: state => {
      state.deletingAthlete = false;
      state.didDeletePass = true;
    },
    [deleteClubUser.pending]: state => {
      state.deletingAthlete = true;
    },
    [deleteClubUser.rejected]: state => {
      state.deletingAthlete = false;
      state.didDeleteFail = true;
    }
  }
});

export const { resetAthleteResult } = slice.actions;

export const getClubUsers = state => state.athletes.club;
export const getClubTeams = state => state.athletes.teams;

export const getFetchingAthletes = state => state.athletes.fetchingAthletes;
export const getDidFetchFail = state => state.athletes.didFetchFail;
export const getFetchClubTeams = state => state.athletes.fetchClubTeams;
export const getDidFetchTeamsFail = state => state.athletes.didFetchTeamsFail;

export const getUpdatingAthlete = state => state.athletes.updatingAthlete;
export const getDidUpdateFail = state => state.athletes.didUpdateFail;
export const getDidUpdatePass = state => state.athletes.didUpdatePass;

export const getDeletingAthlete = state => state.athletes.deletingAthlete;
export const getDidDeleteFail = state => state.athletes.didDeleteFail;
export const getDidDeletePass = state => state.athletes.didDeletePass;

export const getAddingAthlete = state => state.athletes.addingAthlete;
export const getAddedAthlete = state => state.athletes.addedAthlete;
export const getDidAddFail = state => state.athletes.didAddFail;
export const getDidAddPass = state => state.athletes.didAddPass;

export default slice.reducer;
