import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import LoginFlowSpinner from 'components/LoginFlow/LoginFlowSpinner';
import LoginFlowSubmission from 'components/LoginFlow/LoginFlowSubmission';
import TermsOfService from 'components/TermsOfService';
import pt from 'prop-types';
import proInstinctAPI from 'proInstinctAPI';
import { Container } from 'react-bootstrap';
import LoginFlowFieldColumn from '../LoginFlowFieldColumn';

const LoginFlowForm = ({
  initialValues,
  schema,
  spin,
  setSpin,
  loginFlowFields,
  submissionLabel,
  navigateTo,
  createUserJSON
}) => {
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setSpin(true);
        const toSend = {
          ...createUserJSON,
          'email': values.clubEmail,
          'password': values.clubPass,
          'username': values.username,
          'dateOfBirth': values.dob,
          'firstName': values.firstName,
          'lastName': values.lastName
        };
        // post createUser to backend
        proInstinctAPI.createUser(toSend).then(val => {
          setSpin(false);
          history.push(`${navigateTo}/${toSend.firstName}`);
        }).catch(e => {
          setSpin(false);
          console.log('sign up error: ', e.message.slice(27, 35));
          if (e.message.slice(27, 35) === 'code 500') {
            alert('This email has already been used. Please use another email.');
          } else if (e.message.slice(27, 35) === 'code 409') {
            alert('This username has already been used. Please use another username');
          }
          else {
            alert('An error has occured... Please try again.');
          }
        });
      }}
    >
      {({ errors, setErrors, setFieldValue, values }) => (
        <Form className='d-flex flex-column mb-2'>
          <div className='fieldContainer'>
            <Container>
              <LoginFlowFieldColumn 
                loginFlowFields={loginFlowFields}
                errors={errors}
                setErrors={setErrors}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Container>
            <LoginFlowSpinner spin={spin} />
            {(history.location.pathname === '/signUpNoConsent' ||
              history.location.pathname === '/signUp')
              ? <TermsOfService /> : null
            }
            <LoginFlowSubmission
              spin={spin}
              setSpin={setSpin}
              setErrors={setErrors}
              submissionText={submissionLabel}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

LoginFlowForm.propTypes = {
  initialValues: pt.object.isRequired,
  schema: pt.object.isRequired,
  spin: pt.bool.isRequired,
  createUserJSON: pt.object.isRequired,
  setSpin: pt.func.isRequired,
  loginFlowFields: pt.array.isRequired,
  submissionLabel: pt.string.isRequired,
  navigateTo: pt.string.isRequired
};

export default LoginFlowForm;
