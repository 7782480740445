export const utilityVariables = {
  postOnboarding: {
    androidTextTop:
      'Congratulations on successfully registering!',
    androidTextBottom:
      'Get started with your training by clicking the Play Store logo below.',
    iOSTextTop:
      'Congratulations on successfully registering!',
    iOSTextBottom:
      'Get started with your training by clicking the AppStore logo below.',
    browserTextTop:
      'Congratulations on successfully registering!',
    browserTextBottom:
      'Get started by downloading the ProInstinct app on your mobile device.',
    logoWidth: 100,
    androidLogoAlt: 'playStore',
    iosLogoAlt: 'appStore'
  },
  thankYou: {
    approvalTextTop: // eslint-disable-next-line max-len
      'Congratulations on your first step in registering!',
    approvalTextBottom: // eslint-disable-next-line max-len
      'You will receive a confirmation in your email within 24 hours upon approval.'
  }
};
