import React from 'react';
import pt from 'prop-types';
import AthletesMonthSection from '../AthletesMonthSection';
import AthletesMonthTitle from '../AthletesMonthTitle';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/dashboard/athletesMonth';
import './AthletesMonth.scss';

const AthletesMonth = ({ athletesCurrentMonth }) => {
  return (
    <div className={utilVar.athletesMonth.classNames.athletesMonth}>
      <AthletesMonthTitle />
      {utilFunc.setHorizontalVisibility(window) ? (
        <hr />
      ) : (
        <div className='vertical' />
      )}
      <AthletesMonthSection sectionData={athletesCurrentMonth} />
    </div>
  );
};

AthletesMonth.propTypes = {
  athletesCurrentMonth: pt.array.isRequired
};

export default AthletesMonth;
