import { getTokenAuthentic } from 'features/API';
import {
  getDidAuthPass,
  getDidRefreshPass,
  getIsRefreshing,
  refreshToken,
  resetAuthResult,
  resetRefreshResult,
  signOut
} from 'features/Authentication';

import DashboardHeader from './DashboardHeader';
import DashboardMonthPicker from './DashboardMonthPicker';
// import DashboardDatePicker from './DashboardDatePicker';
import AthletesMonth from './AthletesMonth';
import AthletesTable from './AthletesTable';
import TopModules from './TopModules';
import TrainingHours from './TrainingHours';
import AthletesGraph from './AthletesGraph';

import './Dashboard.scss';
import {
  selectStartDate,
  selectEndDate,
  selectMonthAsDate
} from 'features/DateRange/DateRangeSelectors';
import {
  getDateChanged,
  resetDateChanged,
  dateSelection
} from 'features/DateRange';
import {
  fetchActiveAthletes,
  fetchMonthlyStats,
  getAverageHours,
  getFetchingAthletes,
  getFetchingStats,
  getTotalAthletes,
  getTotalHours
} from 'features/Dashboard';
import {
  selectAllActiveAthletes,
  selectConvertedMonths
} from 'features/Dashboard/DashboardSelectors';
import {
  getConfirmInviteFailed,
  getConfirmInvitePassed,
  getInviteFailed,
  getInviteMessage,
  resetInviteFilled
} from 'features/Invite';
import { promptConstants } from 'features/Prompt/PromptConstants';
import { triggerPrompt } from 'features/Prompt/PromptUtility';
import moment from 'moment';
import pt from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { useHistory } from 'react-router-dom';
import { utilityFunctions as utilFunc } from 'utility';
import { utilityVariables as utilVar } from 'utility/dashboard';
import { utilityVariables as utilMessages } from 'utility/messages';
// import DashboardDatePicker from './DashboardDatePicker';

const Dashboard = ({ targetRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [focusedInput, setFocusedInput] = useState(null);
  const [expandAthletes, setExpandAthletes] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const monthSelected = useSelector(selectMonthAsDate);

  const onDatesChange = ({ startDate, endDate, date }) => {
    dispatch(dateSelection(startDate, endDate, date));
  };

  const handleExpandAthletes = () => {
    setExpandAthletes(!expandAthletes);
  };

  const fetchingStats = useSelector(getFetchingStats);
  const fetchingAthletes = useSelector(getFetchingAthletes);
  const fetchingStatus = [fetchingStats, fetchingAthletes];

  const didAuthPass = useSelector(getDidAuthPass);
  const didRefreshPass = useSelector(getDidRefreshPass);
  const isRefreshing = useSelector(getIsRefreshing);

  const startUnix = useSelector(selectStartDate);
  const endUnix = useSelector(selectEndDate);
  const dateChanged = useSelector(getDateChanged);
  const totalAthletes = useSelector(getTotalAthletes);
  const totalHours = useSelector(getTotalHours);
  const averageHours = useSelector(getAverageHours);
  const monthlyStats = useSelector(selectConvertedMonths);

  const displayInviteFailed = useSelector(getInviteFailed);
  const inviteMessage = useSelector(getInviteMessage);
  const confirmInviteFailed = useSelector(getConfirmInviteFailed);
  const confirmInvitePassed = useSelector(getConfirmInvitePassed);

  const authenticToken = useSelector(getTokenAuthentic);

  // const topModules = useSelector(selectTopModules);
  const allAthletes = useSelector(selectAllActiveAthletes);

  const athletesCurrentMonth = [
    {
      fieldValue: totalAthletes,
      fieldName: utilVar.dashboard.currentMonth.totalAthletes
    },
    {
      fieldValue: totalHours,
      fieldName: utilVar.dashboard.currentMonth.totalHours
    },
    {
      fieldValue: averageHours,
      fieldName: utilVar.dashboard.currentMonth.averageHours
    }
  ];

  useEffect(() => {
    if (!displayCalendar && dateChanged) {
      if (
        !isRefreshing &&
        utilFunc.decodedJWT()?.exp < utilFunc.currentTimestamp()
      ) {
        dispatch(
          refreshToken({
            email: localStorage.getItem('email'),
            refresh: localStorage.getItem('refresh')
          })
        );
      } else if (!didRefreshPass) {
        // this block should only run when token has not expired/not refreshed
        dispatch(fetchMonthlyStats({ start: startUnix, end: endUnix }));
        // dispatch(fetchTopModules({ start: startUnix, end: endUnix }));
        dispatch(fetchActiveAthletes({ start: startUnix, end: endUnix }));
      }
      dispatch(resetDateChanged());
    }
  }, [
    dateChanged,
    dispatch,
    endUnix,
    displayCalendar,
    isRefreshing,
    startUnix,
    didRefreshPass
  ]);

  // we need this because the token could refresh on date change
  useEffect(() => {
    if (didRefreshPass) {
      dispatch(resetRefreshResult());
      dispatch(fetchMonthlyStats({ start: startUnix, end: endUnix }));
      // dispatch(fetchTopModules({ start: startUnix, end: endUnix }));
      dispatch(fetchActiveAthletes({ start: startUnix, end: endUnix }));
    }
  }, [didRefreshPass, dispatch, endUnix, startUnix]);

  useEffect(() => {
    async function postAuth() {
      if (didAuthPass) {
        await new Promise(resolve =>
          setTimeout(resolve, promptConstants.delay)
        );
        dispatch(resetAuthResult());
        triggerPrompt(
          utilMessages.success.loginSuccess,
          promptConstants.success,
          dispatch
        );
      }
    }

    postAuth();
  }, [didAuthPass, dispatch]);

  useEffect(() => {
    if (displayInviteFailed) {
      dispatch(resetInviteFilled());
      triggerPrompt(
        inviteMessage || utilMessages.errors.inviteFailed,
        promptConstants.danger,
        dispatch
      );
    }
  }, [dispatch, displayInviteFailed, inviteMessage]);

  useEffect(() => {
    if (confirmInviteFailed) {
      dispatch(resetInviteFilled());
      triggerPrompt(inviteMessage, promptConstants.danger, dispatch);
    } else if (confirmInvitePassed) {
      dispatch(resetInviteFilled());
      triggerPrompt(inviteMessage, promptConstants.success, dispatch);
    }
  }, [confirmInviteFailed, confirmInvitePassed, dispatch, inviteMessage]);

  useEffect(() => {
    if (!authenticToken) {
      dispatch(signOut());
      history.push('/');
    }
  }, [authenticToken, dispatch, history]);

  return (
    <>
      {displayCalendar && (
        <Calendar
          view='year'
          value={monthSelected}
          className='position-absolute'
          onClickMonth={value => {
            onDatesChange({
              startDate: moment(value),
              endDate: moment(value),
              date: moment(value).valueOf()
            });
            setDisplayCalendar(!displayCalendar);
          }}
          maxDate={new Date()}
        />
      )}
      <div
        ref={targetRef}
        onClick={() => {
          if (displayCalendar) setDisplayCalendar(false);
        }}
      >
        <DashboardHeader />
        <div className='d-flex flex-column flex-sm-row'>
          {/* <DashboardDatePicker
          width={width}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
        /> */}
          <DashboardMonthPicker
            display={displayCalendar}
            setDisplay={setDisplayCalendar}
            value={monthSelected}
          />
        </div>
        {utilFunc.checkLoading(fetchingStatus) ? (
          <div className='text-center'>
            <Spinner animation='border' variant='primary' />
          </div>
        ) : (
          <>
            <AthletesMonth athletesCurrentMonth={athletesCurrentMonth} />
            <TrainingHours monthlyStats={monthlyStats} />
            <div className='d-flex flex-sm-row flex-column mt-3'>
              <AthletesGraph monthlyStats={monthlyStats} />
              <TopModules />
            </div>
            <AthletesTable
              activeAthletes={allAthletes}
              expandAthletes={expandAthletes}
              setExpandAthletes={handleExpandAthletes}
            />
          </>
        )}
      </div>
    </>
  );
};

Dashboard.propTypes = {
  targetRef: pt.object.isRequired
};

export default withResizeDetector(Dashboard);
