import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

//you could also keep the dates in sessionStorage
const startDate = new moment().subtract(7, 'd').unix();
const endDate = new moment().unix();

const dateChangeSlice = createSlice({
  name: 'dateChange',
  initialState: {
    startDate,
    endDate,
    monthSelection: moment().valueOf(),
    dateChanged: true
  },
  reducers: {
    resetDateChanged(state) {
      state.dateChanged = false;
    },
    // eslint-disable-next-line max-len
    // dateBack and dateForward are used to jump between weeks which will be in a FUTURE update
    dateBack(state) {
      state.startDate = moment.unix(state.startDate).subtract(7, 'd').unix();
      state.endDate = moment.unix(state.endDate).subtract(7, 'd').unix();
      state.dateChanged = true;
    },
    dateForward(state) {
      state.startDate = moment.unix(state.startDate).add(7, 'd').unix();
      state.endDate = moment.unix(state.endDate).add(7, 'd').unix();
      state.dateChanged = true;
    },
    monthBack(state) {
      state.startDate = moment
        .unix(state.startDate)
        .subtract(1, 'month')
        .unix();
      state.endDate = moment.unix(state.endDate).subtract(1, 'month').unix();
      state.monthSelection = moment(state.monthSelection)
        .subtract(1, 'months')
        .valueOf();
      state.dateChanged = true;
    },
    monthForward(state) {
      state.startDate = moment.unix(state.startDate).add(1, 'month').unix();
      state.endDate = moment.unix(state.endDate).add(1, 'month').unix();
      state.monthSelection = moment(state.monthSelection)
        .add(1, 'months')
        .valueOf();
      state.dateChanged = true;
    },
    dateSelection: {
      reducer(state, action) {
        const { startDateConverted, endDateConverted, date } = action.payload;
        state.startDate = startDateConverted || state.startDate;
        state.endDate = endDateConverted || state.endDate;
        state.monthSelection = date;
        state.dateChanged = true;
      },
      prepare(startDate, endDate, date) {
        const startDateConverted = startDate?.unix();
        const endDateConverted = endDate?.unix();
        return {
          payload: {
            startDateConverted,
            endDateConverted,
            date
          }
        };
      }
    }
  }
});

export const {
  dateBack,
  dateForward,
  monthBack,
  monthForward,
  dateSelection,
  resetDateChanged
} = dateChangeSlice.actions;
export const getDateChanged = state => state.dateChange.dateChanged;
export const getMonthSelection = state => state.dateChange.monthSelection;
export default dateChangeSlice.reducer;
