import React from 'react';
import pt from 'prop-types';

const LoginFlowHeader = ({ title }) => {
  return <h1 className='text-white sf-display-bold mb-4'>{title}</h1>;
};

LoginFlowHeader.propTypes = {
  title: pt.string.isRequired
};

export default LoginFlowHeader;
