import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { monthBack, monthForward } from 'features/DateRange';
import DateSelectionButton from '../DateSelectionButton';
import chevronDown from 'assets/Chevron Down.png';
import { getFetchingStats } from 'features/Dashboard';
import { utilityFunctions as utilFunc } from 'utility';
//prettier-ignore
import { 
  utilityVariables as utilVar 
} from 'utility/dashboard/dashboardMonthPicker';
import pt from 'prop-types';
import '../DashboardDatePicker/DashboardDatePicker.scss';
import './DashboardMonthPicker.scss';

const DashboardMonthPicker = ({ display, setDisplay, value }) => {
  const dispatch = useDispatch();
  const fetchingStats = useSelector(getFetchingStats);

  const handleDateSelection = e => {
    // Don't allow a new date to be selected if stats are being fetched
    if (fetchingStats) return;

    e.currentTarget.id === 'dateSubtract'
      ? dispatch(monthBack())
      : dispatch(monthForward());
  };

  return (
    <div className='d-flex'>
      <DateSelectionButton
        id='dateSubtract'
        handleDateSelection={e => {
          handleDateSelection(e);
        }}
        disabled={fetchingStats}
        direction='left'
      />
      <div className='DateRangePickerInput d-flex align-items-center'>
        <span className='px-3'>
          {utilFunc.formatJSDate(
            value,
            utilVar.dashboardMonthPicker.monthDisplay
          )}
        </span>
        <button
          className={utilVar.dashboardMonthPicker.classNames.monthPickerButton}
          onClick={() => setDisplay(!display)}
          disabled={fetchingStats}
        >
          <img
            src={chevronDown}
            alt={utilVar.dashboardMonthPicker.monthPickerDropdown.alt}
            height={utilVar.dashboardMonthPicker.monthPickerDropdown.height}
            width={utilVar.dashboardMonthPicker.monthPickerDropdown.width}
          />
        </button>
      </div>
      <DateSelectionButton
        id='dateAdd'
        handleDateSelection={e => {
          handleDateSelection(e);
        }}
        disabled={
          fetchingStats ||
          utilFunc.formatJSDate(
            value,
            utilVar.dashboardMonthPicker.monthDisplay
          ) ===
            utilFunc.formatCurrentDate(
              utilVar.dashboardMonthPicker.monthDisplay
            )
        }
        direction='right'
      />
    </div>
  );
};

DashboardMonthPicker.propTypes = {
  display: pt.bool,
  setDisplay: pt.func,
  value: pt.object
};

export default DashboardMonthPicker;
