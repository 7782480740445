import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetInviteSubmitted,
  showFailedInvite,
  getUUID,
  getInviteData,
  completeOnboarding,
  getConfirmInviteFetching
} from 'features/Invite';
import { utilityVariables as utilVar } from 'utility/invite/confirmInvite';
import LoginFlowField from 'components/LoginFlow/LoginFlowField';
import LoginFlowInstructions from 'components/LoginFlow/LoginFlowInstructions';
import LoginFlowSubmission from 'components/LoginFlow/LoginFlowSubmission';
import { BTN_LOCALIZATIONS } from 'utility/buttons';

const ValidInvitation = () => {
  const dispatch = useDispatch();
  const invitationUUID = useSelector(getUUID);

  if (!invitationUUID) {
    dispatch(showFailedInvite());
    return <Redirect to='/' />;
  }

  return <ConfirmInvitation />;
};

const ConfirmInvitation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const inviteData = useSelector(getInviteData);
  const invitationUUID = useSelector(getUUID);
  const confirmFetching = useSelector(getConfirmInviteFetching);
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    dispatch(resetInviteSubmitted());
  }, [dispatch]);

  useEffect(() => {
    if (!confirmFetching) {
      history.push('/nextSteps');
    }
  }, [history, confirmFetching]);

  return (
    <>
      <div className='loginFlowBG' />
      <div className='w-100 d-flex flex-column align-items-center pt-5'>
        <div className='loginFlowContainer'>
          <LoginFlowInstructions
            title={utilVar.confirmInvite.title}
            body={utilVar.confirmInvite.instructions}
          />
        </div>
        <Formik
          initialValues={utilVar.confirmInvite.initialValues}
          validationSchema={utilVar.confirmInvite.validateInvite}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            dispatch(
              completeOnboarding({
                ...inviteData,
                ...{
                  // TODO: we can rework the backend naming in the future
                  email: inviteData.athleteEmail,
                  phoneNumber: inviteData.mobile,
                  uuid: invitationUUID,
                  newPassword: values.password
                }
              })
            );
          }}
        >
          {({ errors, setErrors }) => {
            const addUserFields = [
              {
                label: 'Password',
                name: 'password',
                field: 'password'
              },
              {
                label: 'Confirm Password',
                name: 'confirmPassword',
                field: 'password'
              }
            ];

            return (
              <Form>
                {addUserFields.map((userField, idx) => (
                  <div key={`${userField.label}${idx}`} className='mb-2'>
                    <LoginFlowField
                      label={userField.label}
                      type={userField.field}
                      fieldName={userField.name}
                      fieldProps={userField.props}
                      error={errors[userField.name]}
                    />
                  </div>
                ))}
                <LoginFlowSubmission
                  spin={spin}
                  setSpin={setSpin}
                  setErrors={setErrors}
                  submissionText={BTN_LOCALIZATIONS.en.CONTINUE}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ValidInvitation;
