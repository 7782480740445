import React, { useState } from 'react';
import moment from 'moment';
import { utilityVariables as utilVar } from 'utility/fields/dateField';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import pt from 'prop-types';
import './DateField.scss';

const DateField = ({
  id,
  name,
  disabled,
  setFieldValue,
  dob,
  placeholder
}) => {
  const [focusedInput, setFocusedInput] = useState(false);

  return (
    <SingleDatePicker
      date={dob ? moment(dob) : null}
      onDateChange={val => {
        // utilFunc.handleDateChange(e, name, setFieldValue, setParentDisabled);
        setFieldValue(name, val.format('YYYY-MM-DD'));
      }}
      readOnly
      focused={focusedInput}
      onFocusChange={() => setFocusedInput(!focusedInput)}
      id={id || name}
      placeholder={placeholder}
      displayFormat='YYYY-MM-DD'
      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
      keepOpenOnDateSelect={false}
      numberOfMonths={1}
      disabled={disabled}
      //inspired by this:
      //https://tinyurl.com/35u6pqx7
      renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
        <div className='d-flex justify-content-center'>
          <div>
            <select
              className='border-0'
              value={month.month()}
              onChange={e => onMonthSelect(month, e.currentTarget.value)}
            >
              {moment.months().map((label, value) => (
                <option key={`${label}${value}`} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              className='border-0'
              value={month.year()}
              onChange={e => onYearSelect(month, e.currentTarget.value)}
            >
              {[
                ...Array(
                  moment().year() - utilVar.dateField.yearStart + 1
                ).keys()
              ].map(i => {
                const momentYear = moment().year() - i;
                return (
                  <option key={`offsetYear${i}`} value={momentYear}>
                    {momentYear}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      )}
    />
  );
};

DateField.propTypes = {
  id: pt.string,
  name: pt.string,
  disabled: pt.bool,
  setFieldValue: pt.func.isRequired,
  setParentDisabled: pt.func,
  placeholder: pt.string,
  dob: pt.string
};

DateField.defaultProps = {
  id: null,
  name: null,
  disabled: false,
  placeholder: '',
  dob: ''
};

export default DateField;
